/* eslint-disable  */
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import React, { Dispatch, useState, useEffect } from "react";
import IconButton from '@material-ui/core/IconButton';
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import Menu, { MenuProps } from '@material-ui/core/Menu';
import { withStyles } from "@material-ui/core/styles";
import FullscreenLoader from '../../common/components/FullscreenLoader';
import CloseIcon from '@material-ui/icons/Close';
import DialogTitle from '@material-ui/core/DialogTitle';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { getNewBrand } from '../../common/components/util';
import moment from "moment-timezone";
import GetAppIcon from "@material-ui/icons/GetApp";
import XLSX from "xlsx";
import Alert from '@material-ui/lab/Alert';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';

const useStyles = makeStyles((theme) => ({
	tableHeadSmallBrand: {
		color: '#133F8A',
		fontWeight: 900,
		fontSize: '0.75rem',
		fontFamily: 'Nunito',
		padding: 20,
		lineHeight: 0,
		width: 100,
	},
	tableHeadPart: {
		color: '#133F8A',
		fontWeight: 900,
		fontSize: '0.75rem',
		fontFamily: 'Nunito',
		padding: 20,
		lineHeight: 0,
		width: 220,
	},
	tableHeading: {
		color: '#133F8A',
		fontWeight: 900,
		fontSize: '0.75rem',
		fontFamily: 'Nunito',
		padding: 10,
		lineHeight: 0,
		width: 200,
	},
	tableHead: {
		color: '#133F8A',
		fontWeight: 900,
		fontSize: '0.75rem',
		fontFamily: 'Nunito',
		padding: 20,
		lineHeight: 0,
		width: 140,
	},
	tableHeadMessage: {
		color: '#133F8A',
		fontWeight: 900,
		fontSize: '0.75rem',
		fontFamily: 'Nunito',
		padding: 20,
		lineHeight: 0,
		width: 240,
	},
	tableCellColorGreen: {
		color: '#4CBB17',
		fontWeight: 600,
		fontSize: '0.75rem',
		fontFamily: 'Nunito',
		textTransform: 'capitalize',
		padding: 20,
		lineHeight: 0,
		width: 100,
	},
	tableCellColorRed: {
		color: 'red',
		fontWeight: 600,
		fontSize: '0.75rem',
		fontFamily: 'Nunito',
		textTransform: 'capitalize',
		padding: 20,
		lineHeight: 0,
		width: 100,
	},
	tableAction: {
		color: '#133F8A',
		fontWeight: 900,
		fontSize: '0.75rem',
		fontFamily: 'Nunito',
		paddingLeft: 30,
		lineHeight: 0,
		width: 140,
	},
	tableHeadLog: {
		color: '#133F8A',
		fontWeight: 900,
		fontSize: '0.75rem',
		fontFamily: 'Nunito',
		paddingTop: 20,
		paddingRight: 20,
		paddingLeft: 16,
		paddingDown: 20,
		lineHeight: 0,
		width: 140,
	},
	tableHeadExpiry: {
		color: '#133F8A',
		fontWeight: 900,
		fontSize: '0.75rem',
		fontFamily: 'Nunito',
		paddingTop: 10,
		paddingRight: 10,
		paddingLeft: 20,
		paddingDown: 5,
		lineHeight: 0,
		width: 140,
	},
	body: {
		color: '#666666',
		fontWeight: 600,
		fontSize: '0.75rem',
		fontFamily: 'Nunito',
		padding: 20,
		lineHeight: 0,
		width: 180,
	},
	bodycus: {
		color: '#666666',
		fontWeight: 600,
		fontSize: '0.7rem',
		fontFamily: 'Nunito',
		lineHeight: 0,
		width: 310,
	},
	bodyExpiry: {
		color: '#666666',
		fontWeight: 600,
		fontSize: '0.75rem',
		fontFamily: 'Nunito',
		lineHeight: 0,
		// padding:40,
		// paddingRight: 40,
		width: 240,
	},
	actions: {
		color: '#1A9FE0',
		fontSize: 13,
		fontFamily: 'Nunito',
		fontWeight: 700,
		width: 200
	},
	subTable2: {
		color: '#444444',
		fontWeight: 350,
		width: 97,
		fontSize: '0.75rem',
		fontFamily: 'Nunito',
		padding: 10,
	},
	subTable6: {
		color: '#444444',
		fontWeight: 350,
		width: 140,
		fontSize: '0.75rem',
		fontFamily: 'Nunito',
		padding: 10,
	},
	actionButton: {
		color: '#1A9FE0',

	},
	DialogActions: {
		position: 'sticky',
		bottom: '1px',
		background: '#fff',

	},
	rowCustomSummary: {
		display: 'flex',
		flexWrap: 'wrap'
	}
}));


const OrderLogDialog = (props: any) => {
	const classes = useStyles();
	const dispatch: Dispatch<any> = useDispatch();
	const [open, setOpen] = React.useState(false);
	const [loading, setLoading] = React.useState<any>(false);
	const [loggedOnOrder, setLoggedOnOrder] = React.useState<any>('desc');
	const [loggedOnOrderBy, setLoggedOnOrderBy] = React.useState<any>('loggedOn');
	const [isSelectedAll, setIsSelectedAll] = React.useState(true);
	const [selectedItems, setSelectedItems] = React.useState<any>([]);
	const [selected, setSelected] = React.useState<any>([]);
	const [orderLogsData, setOrderLogsData] = useState([]);
	const { getOrderLogsDetails }: any = useSelector(
		(state: any) => state.createUpdateEnquiry
	);

	const createSortHandler = (property: any, type: any) => () => {
		if (type === 'date') {
			const isAsc = loggedOnOrderBy === property && loggedOnOrder === 'asc';
			setLoggedOnOrder(isAsc ? 'desc' : 'asc');
			setLoggedOnOrderBy(property);
		} else if (type === 'text') {
			const isAsc = loggedOnOrderBy === property && loggedOnOrder === 'asc';
			setLoggedOnOrder(isAsc ? 'desc' : 'asc');
			setLoggedOnOrderBy(property);
		}
	};

	const exportData = () => {
		if (getOrderLogsDetails && getOrderLogsDetails.data && Array.isArray(getOrderLogsDetails && getOrderLogsDetails.data) && getOrderLogsDetails && getOrderLogsDetails.data.length > 0) {
			let currentDate = moment(new Date).format("YYYY-MM-DD");
			let filename = `Order_log_(${getOrderLogsDetails && getOrderLogsDetails.data && getOrderLogsDetails.data[0].B2bEnquiryId})_(${currentDate}).xlsx`;
			let data: any = [];

			getOrderLogsDetails && getOrderLogsDetails.data && getOrderLogsDetails.data.map((enq: any, index: any) => {
				return (
					<>
						{enq.logs.map((ele: any, index: any) => {
							return (
								data.push({
									'Logged On': moment(ele && ele.loggedOn).tz('Asia/Dubai').format('YYYY-MM-DD HH:mm:ss'),
									'Log Type': ele && ele.logType,
									'Part No.': ele && ele.materialNumber,
									'Log Category': ele && ele.logCategory,
									'Brand': enq && enq.make ? getNewBrand(enq.make) : "-",
									'SAP Enquiry ID': enq && enq.enqueryNumber ? enq.enqueryNumber : "-",
									'Message': ele && ele.messageText,
								})
							)
						})}
					</>
				)
			})
			var ws = XLSX.utils.json_to_sheet(data);
			var wb = XLSX.utils.book_new();
			XLSX.utils.book_append_sheet(wb, ws, "sheet1");
			XLSX.writeFile(wb, filename);
		}
	};


	useEffect(() => {
		let data: any = [...sortLogs(orderLogsData)];
		setOrderLogsData(data);
	}, [loggedOnOrder])

	const sortLogs = (logs: any) => {
		logs.sort((logA: any, logB: any) => {
			if (loggedOnOrderBy === 'logCategory') {
				return loggedOnOrder === 'asc'
					? logA.logCategory.localeCompare(logB.logCategory)
					: logB.logCategory.localeCompare(logA.logCategory);
			} else if (loggedOnOrderBy === 'loggedOn') {
				const dateA = new Date(logA.loggedOn).getTime() as any;
				const dateB = new Date(logB.loggedOn).getTime() as any;
				return loggedOnOrder === 'asc' ? dateA - dateB : dateB - dateA;
			}

			//     return loggedOnOrder === 'asc'
			//         ? (new Date(logA.loggedOn) as any) - (new Date(logB.loggedOn) as any)
			//         : (new Date(logB.loggedOn) as any) - (new Date(logA.loggedOn) as any);
			// }
			// Add more sorting conditions as needed
			return 0;
		});
		return logs;
	};

	const handleSelectAllClick = (isValue?: any) => {
		if (isValue) {
			const allSelected = true;
			setIsSelectedAll(true);
			const updatedSelectedItems = allSelected
				? getOrderLogsDetails && getOrderLogsDetails.data && getOrderLogsDetails.data.flatMap((enq: any) => {
					return enq.logs.map((log: any) => ({
						...log,
						enquiryNumber: enq.enqueryNumber,
						//loggedOn: moment(enq && enq.loggedOn).tz('Asia/Dubai').format('YYYY-MM-DD HH:mm:ss'),
						make: enq.make ? enq.make : ''

					}));
				})
				: [];
			setSelectedItems(updatedSelectedItems);
		} else {
			const allSelected = !isSelectedAll;
			setIsSelectedAll(allSelected);
			const updatedSelectedItems = allSelected
				? getOrderLogsDetails && getOrderLogsDetails.data && getOrderLogsDetails.data.flatMap((enq: any) => {
					return enq.logs.map((log: any) => ({
						...log,
						enquiryNumber: enq.enqueryNumber,
						//loggedOn: moment(enq && enq.loggedOn).tz('Asia/Dubai').format('YYYY-MM-DD HH:mm:ss'),
						make: enq.make ? enq.make : ''
					}));
				})
				: [];
			setSelectedItems(updatedSelectedItems);
		}
	};

	React.useEffect(() => {
		if (getOrderLogsDetails && getOrderLogsDetails.data && getOrderLogsDetails.data.length > 0) {
			handleSelectAllClick(true);
			let orderLogs: any = getOrderLogsDetails.data;
			let dataArr: any = [];
			orderLogs.map((item: any) => {
				item.logs.map((ele: any) => {
					dataArr.push({ ...ele, enqueryNumber: item.enqueryNumber, make: item.make ? item.make : '' });
				})
			})
			setOrderLogsData(dataArr);
			let data: any = [...sortLogs(dataArr)];
			setOrderLogsData(data);

		}
	}, [getOrderLogsDetails]);

	const handleCheckboxClick = (e: any, log: any, enq: any) => {
		const updatedSelectedItems = [...selectedItems];
		const index = updatedSelectedItems.findIndex(
			(selectedLog) => selectedLog.materialNumber === log.materialNumber
		);
		if (index !== -1) {
			updatedSelectedItems.splice(index, 1);
		} else if (e.target.checked) {
			updatedSelectedItems.push({
				...log,
				enquiryNumber: enq.enqueryNumber,
				make: enq.make ? enq.make : ''
			});
		}
		// if (index !== -1) {
		// 	updatedSelectedItems.splice(index, 1);
		// } else {
		// 	updatedSelectedItems.push(log);
		// }
		setSelectedItems(updatedSelectedItems);
		setIsSelectedAll(updatedSelectedItems.length === getOrderLogsDetails && getOrderLogsDetails.data && getOrderLogsDetails.data.length * getOrderLogsDetails && getOrderLogsDetails.data && getOrderLogsDetails.data[0].logs.length);
	};

	return (
		<div>
			{loading && (<FullscreenLoader />)}
			<Dialog
				open={props.openOrderLogPopup}
				aria-abelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				fullWidth={true}
				maxWidth={'lg'}
			>
				<DialogTitle className="pb-0 mt-2 mb-2" id="form-dialog-title">
					<div className="page-title">Enquiry & Order Logs
						<IconButton aria-label="close" className="float-right mb-1" onClick={() => { props.openCloseOrderLogPopup(); props.setGetLatestOrderCount(true) }}>
							<CloseIcon />
						</IconButton>
					</div>
				</DialogTitle>
				<div className='row col-12'>
					<p className="tandcHeading-orderlog px-4"> The enquiry & order log messages will help you in identifying and resolving technical issues when processing enquiry or creating order.</p>
				</div>
				{getOrderLogsDetails && getOrderLogsDetails.data && Array.isArray(getOrderLogsDetails && getOrderLogsDetails.data) && getOrderLogsDetails && getOrderLogsDetails.data.length > 0 ?
					<div className="col-12 border-top w-100 p-0 mb-3 mt-3">
						<div className={`order-logs-no ${classes.rowCustomSummary} justify-content-start`}>
							<div className='pl-2'>Enquiry Number : {getOrderLogsDetails && getOrderLogsDetails.data && getOrderLogsDetails.data[0].B2bEnquiryId ? getOrderLogsDetails.data[0].B2bEnquiryId : "-"}</div>
							<div className='pl-4'>Reference Number : {getOrderLogsDetails && getOrderLogsDetails.data && getOrderLogsDetails.data[0].masterReferenceNumber ? getOrderLogsDetails.data[0].masterReferenceNumber : "-"}</div>
						</div>
					</div>
					: ""}
				{getOrderLogsDetails && getOrderLogsDetails.data && Array.isArray(getOrderLogsDetails && getOrderLogsDetails.data) && getOrderLogsDetails && getOrderLogsDetails.data.length > 0 ?
					<>
						<div className="col-12 col-lg-12 col-md-12 col-sm-12 mb-5 mt- 4 pb-5">
							<div className="cardCommon mh-300p">
								<TableContainer>
									<Table aria-label="table" >
										<TableHead className="w-100">
											<TableRow>
												{sessionStorage.getItem('userType') !== 'FSE' ?
													<TableCell padding="checkbox" className="w-60p pr-0">
														<Checkbox
															indeterminate={selectedItems && selectedItems.length > 0 && selectedItems.length < getOrderLogsDetails && getOrderLogsDetails.data && getOrderLogsDetails.data.length * getOrderLogsDetails && getOrderLogsDetails.data && getOrderLogsDetails.data.length && getOrderLogsDetails.data.length > 0 && getOrderLogsDetails.data[0].logs && getOrderLogsDetails.data[0].logs.length && getOrderLogsDetails.data[0].logs.length}
															checked={isSelectedAll}
															onChange={() => handleSelectAllClick()}
														/>
														{/* <Checkbox
															indeterminate={selected.length > 0 && selected.length < getOrderLogsDetails && getOrderLogsDetails.data && getOrderLogsDetails.data[0].count}
															checked={getOrderLogsDetails && getOrderLogsDetails.data && getOrderLogsDetails.data[0].count && selected.length === getOrderLogsDetails && getOrderLogsDetails.data && getOrderLogsDetails.data[0].count}
															onChange={(e: any) => handleSelectAllClick(e)}
															inputProps={{ 'aria-label': 'select all favourites' }}
														/> */}
													</TableCell>
													: ""}
												<TableCell className={classes.tableHeadLog}>Logged On
													<TableSortLabel
														active={loggedOnOrderBy === 'loggedOn'}
														direction={loggedOnOrderBy === 'loggedOn' ? loggedOnOrder : 'asc'}
														onClick={createSortHandler('loggedOn', 'date')} >
													</TableSortLabel>
												</TableCell>
												<TableCell className={classes.tableHeadSmallBrand}>Log Type</TableCell>
												<TableCell className={classes.tableHeadPart}>Part No.</TableCell>
												<TableCell className={classes.tableHead}>Log Category
													<TableSortLabel
														active={loggedOnOrderBy === 'logCategory'}
														direction={loggedOnOrderBy === 'logCategory' ? loggedOnOrder : 'asc'}
														onClick={createSortHandler('logCategory', 'text')}     >
													</TableSortLabel>
												</TableCell>
												<TableCell className={`${classes.tableHeadExpiry}`}>Brand</TableCell>
												<TableCell className={`${classes.tableHead}`}>SAP Enquiry Number</TableCell>
												<TableCell className={classes.tableHeadMessage}>Message</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											<React.Fragment>
												{orderLogsData.length > 0 && orderLogsData
													.map((data: any, logIndex: any) => (
														<TableRow hover
															role="checkbox"
															tabIndex={-1}
															key={logIndex}>
															{sessionStorage.getItem('userType') !== 'FSE' ?
																<TableCell padding="checkbox">
																	<Checkbox
																		checked={selectedItems && selectedItems.length > 0 && selectedItems.some(
																			(selectedLog: any) => selectedLog.materialNumber === data.materialNumber
																		)}
																		onChange={(e: any) => handleCheckboxClick(e, data, data)}
																	/>
																</TableCell> : ""}
															<TableCell className="">
																<h6 className="orderLogsLabelTable">{data && data.loggedOn ? moment(data.loggedOn).tz('Asia/Dubai').format('YYYY-MM-DD') : "-"}</h6>
																<div className="greyLabelTable">
																	{data && data.loggedOn ? moment(data.loggedOn).tz('Asia/Dubai').format('HH:mm:ss') : "-"}
																</div>
															</TableCell>
															<TableCell className={data && data.logType === "success" ? classes.tableCellColorGreen : data && data.logType === "Error" ? classes.tableCellColorRed : classes.body}>{data && data.logType ? data.logType : "-"}</TableCell>
															<TableCell className={classes.body}>{data && data.materialNumber ? data.materialNumber : "-"}</TableCell>
															<TableCell className={classes.body}>{data && data.logCategory && data.logCategory === 'item' ? 'Item' : data.logCategory ? data.logCategory : "-"}</TableCell>
															<TableCell className={classes.body}>{data && data.make ? getNewBrand(data.make) : "-"}</TableCell>
															<TableCell className={classes.body}>{data && data.enqueryNumber ? data.enqueryNumber : "-"}</TableCell>
															<TableCell className={classes.body}>
																<Typography variant='body2'>
																	{data && data.messageText ? data && data.messageText : "-"}
																</Typography>
															</TableCell>
														</TableRow>
													))
												}
											</React.Fragment>
										</TableBody>
									</Table>
								</TableContainer>
							</div>
						</div>
						<DialogActions className={`justify-content-between mt-n-65 ${classes.DialogActions}`}>
							<IconButton
								color="primary"
								className="mr-3 ml-2 " size="small" onClick={() => exportData()}>
								<GetAppIcon fontSize="inherit" />
								<span className="font-size-13 load-from-fav-order-log ml-2">
									Export to Excel
								</span>
							</IconButton>
							{sessionStorage.getItem('userType') !== 'FSE' ?
								<Button
									className={`submit-button font-size-11 mr-3 mb-3 ${(selectedItems && selectedItems.length > 0)
										? "actionButtomButtomsBlue"
										: "actionButtomButtomsBlue actionButtomButtomsBlueDisabled"
										}`}
									disabled={selectedItems && selectedItems.length > 0 ? false : true}
									color="primary"
									variant="contained"
									// onClick={() => { props.openCloseOrderLogPopup(); resetData(); props.setGetLatestOrderCount(true) }}
									onClick={() => { props.sendOrderLogsEmail(sortLogs(selectedItems)) }}
								>
									Share Logs with Al-Futtaim
								</Button> : ""}
						</DialogActions>
					</>
					:
					<div className="col-12 mt-4 mb-4">
						<Alert severity="info">No Information Available !</Alert>
					</div>
				}
			</Dialog >
		</div >
	)
}
export default OrderLogDialog