import React, { Fragment, useEffect } from "react";
import "./DeliveryList.css";
import IconButton from '@material-ui/core/IconButton';
import AnnouncementIcon from "../../common/components/AnnouncementIcon";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import VisibilityIcon from '@material-ui/icons/Visibility';
import NotificationIcon from "../../common/components/NotificationIcon";
import { get } from "lodash";
import { Tooltip } from "@material-ui/core";
import FullscreenLoader from "../../common/components/FullscreenLoader";
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import moment from "moment-timezone";
import { Redirect } from "react-router-dom";
import TablePagination from '@material-ui/core/TablePagination';
import { setPageNumber } from "../../store/actions/orderHistory.actions";
import { selectedViewDelivery } from "../../store/actions/delivery.actions";
import { deliveryService } from "../../services/delivery.service";
import { Prompt } from "react-router";
import ViewDelivery from "./ViewDelivery";
import { useState } from "react";
import Snackbar from '../../common/components/CreatedEnquirySnackbar';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: '100%',
    },
    input: {
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
    bodyContent: {
        color: '#666666',
        fontWeight: 600,
        fontSize: '0.75rem',
        fontFamily: 'Nunito'
    },
    tableHead: {
        color: '#133F8A',
        fontWeight: 900,
        fontSize: '0.813rem',
        fontFamily: 'Nunito',
        minWidth: 100
    },
    actionButton: {
        color: '#1A9FE0',
    },
}));

const columns = [
    /* {
        id: 'orderEnquiryNo',
        label: 'Order Enquiry No.',
        align: 'left',
    }, */
    {
        id: 'delivery',
        label: 'Delivery No.',
        align: 'left',
    },
    {
        id: 'salesDocument',
        label: 'Order No.',
        align: 'left'
    },
    {
        id: 'description',
        label: 'Description',
        align: 'left'
    },
    {
        id: 'billingStatus',
        label: 'Billing Status',
        align: 'left'
    },
    {
        id: 'goodsMovementStatus',
        label: 'Goods Movement Status',
        align: 'left'
    },
    {
        id: 'shippingPlanningStatus',
        label: 'Shipping Planning Status',
        align: 'left'
    },
    {
        id: 'shippingReceivingPoint',
        label: 'Shipping Receiving Point',
        align: 'left'
    },
    {
        id: 'pickingStatus',
        label: 'Picking Status',
        align: 'left'
    },
];

const orgIdBrandsMapping: any = {
    "Toyota": "2001",
    "Honda": "2002",
    "Famco": "2003",
    "TE": "2380",
    "OES": "2195",
}

const DeliveryList: React.FC = () => {
    const history = useHistory()
    const classes = useStyles();
    const dispatch = useDispatch();
    const rowsPerPage = 10;
    const [page, setPage] = React.useState(0);
    const [prompt, setPromt] = useState(false);
    const [deliveryData, setDeliveryData] = React.useState<any>();
    const [len, setLen] = React.useState<any>();
    const [delivery, setDeliveryList] = React.useState<any>([]);
    const [download, setDownloadInvoice] = React.useState<any>([]);
    const [open, setOpen] = React.useState<any>(false);
    const [make, setMake] = React.useState<any>("")
    const [errorMessage, setErrorMessage] = React.useState<any>("")
    const { deliveryList, viewDeliverySelected }: any = useSelector((state: any) => state.delivery);
    const { viewDelivery }: any = useSelector((state: any) => state.delivery);
    const [orderCreatedOnDate, setOrderCreatedOnDate] = React.useState<any>();
    const Username: any = sessionStorage.getItem("userRoleCreatedBy");

    useEffect(() => {
        let count = 0
        viewDelivery && viewDelivery.data && viewDelivery.data.parentOrder && viewDelivery.data.parentOrder && viewDelivery.data.parentOrder.subEnquiries.map((ele: any) => {
            if (ele.status !== "Not delivered") {
                count += 1
            }
        })
        setLen(count)
    }, [ViewDelivery])


    useEffect(() => {
        const deliveryData = get(deliveryList, 'data.data.deliveryList', {})[0];
        setMake(viewDeliverySelected && viewDeliverySelected.data && viewDeliverySelected.data.make)
        if (deliveryList && deliveryList.data && deliveryList.data.data && deliveryList.data.data.deliveryList) {
            setDeliveryList(deliveryList && deliveryList.data && deliveryList.data.data && deliveryList.data.data.deliveryList)
        }
    }, [deliveryList, viewDeliverySelected])

    const getDeliveryNumber = (no: any) => {
        if (deliveryData) {
            if (deliveryData.enquiryNumber === no) {
                return deliveryData.delivery
            }
        }
    }

    const getInvoiceNo = (no: any) => {
        if (deliveryData) {
            if (deliveryData.enquiryNumber === no) {
                return deliveryData.billingDocument
            }
        }
    }
    const handleopenClose = () => {
        setOpen(false)
    }


    const exportData = async (no: any, data: any) => {
        const orgIdBrandsMapping: any = {
            "Toyota": "2001",
            "Honda": "2002",
            "Famco": "2003",
            "TE": "2380",
            "OES": "2195",
        }
        const res = await deliveryService.downloadInvoice(no, orgIdBrandsMapping[make]);

        setDownloadInvoice(res);
        if (res.success === false) {
            setOpen(true)
            setErrorMessage(res.error)
        } else if (res && res.message && res.message.status === "S") {
            window.open(res && res.data && res.data.url)
        }
    }

    const handlePageChange = () => {

    }

    const handleredirect = (i: any) => {
        <Redirect to="/deliverydetails" />
        history.push({
            pathname: "/deliverydetails",
        }
        )
    }

    return (
        <Fragment>
            <Snackbar
                open={open}
                type="error"
                handleopenClose={handleopenClose}
                message={errorMessage}
            />
            {deliveryList && deliveryList.loading ? <FullscreenLoader /> :
                <div>
                    <div className="d-flex align-items-center">
                        <h2 className="page-title">
                            Delivery List
                            <Breadcrumbs>
                                <Typography color="textSecondary" className="breadcrumb-text-order-history">Order History</Typography>
                                <Typography color="textPrimary" className="breadcrumb-text-delivery-details">Delivery List</Typography>
                            </Breadcrumbs>
                        </h2>
                        <div className="ml-auto">
                            <AnnouncementIcon />
                            <NotificationIcon />
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="d-flex align-items-center">
                                <h5 className="page-sub-title">List of Deliveries</h5>
                                <IconButton className={`ml-auto ${classes.actionButton}`} onClick={() => { history.push(`/orderhistory`); sessionStorage.setItem('userRoleCreatedBy', ""); }}>
                                    <ChevronLeftIcon fontSize="small" />
                                    <span className="backLinkLabel">Back to List</span>
                                </IconButton>
                            </div>
                        </div>
                    </div>
                    {delivery.length >= 1 &&
                        <>
                            <div className="row">
                                <div className="col-12 col-lg-12 col-md-12 col-sm-12">
                                    <div className="cardCommon orderItems">
                                        <TableContainer className={`classes.container`}>
                                            <Table aria-label="table">
                                                <TableHead className="w-100">
                                                    <TableRow>
                                                        <TableCell className={classes.tableHead}>Order Enquiry No.</TableCell>
                                                        <TableCell className={classes.tableHead}>Reference No.</TableCell>
                                                        <TableCell className={classes.tableHead}>Delivery No.</TableCell>
                                                        <TableCell className={classes.tableHead}>Order No.</TableCell>
                                                        <TableCell className={classes.tableHead}>Invoice No.</TableCell>
                                                        <TableCell className={classes.tableHead}>Username</TableCell>
                                                        <TableCell className={classes.tableHead}>Created On</TableCell>
                                                        <TableCell className={classes.tableHead}>Status</TableCell>
                                                        <TableCell className={classes.tableHead}>Action</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {
                                                        <>
                                                            {delivery && delivery.map((data: any, i: any) => {
                                                                const ele = viewDelivery && viewDelivery.data
                                                                return (
                                                                    <>
                                                                        <tr className={""}>
                                                                            <td className="tdchildCreateEnquiry" onClick={() => {
                                                                                sessionStorage.setItem('enquiryModeDetails', JSON.stringify({
                                                                                    mode: 'view',
                                                                                    masterId: viewDelivery.data && viewDelivery.data.parentOrder && viewDelivery.data.parentOrder && viewDelivery.data.parentOrder.masterId,
                                                                                    orderType: "order",
                                                                                    quotation: false
                                                                                }));
                                                                                dispatch(setPageNumber(page));
                                                                                history.push('/viewenquiry')
                                                                            }}>
                                                                                <ins className="enquiryViewDelivery">{viewDelivery.data && viewDelivery.data.parentOrder && viewDelivery.data.parentOrder && viewDelivery.data.parentOrder.masterId}</ins>
                                                                            </td>
                                                                            <td className="tdchildCreateEnquiry">
                                                                                <p>{ele.enquiryReferenceNumber}</p>
                                                                            </td>
                                                                            <td className="tdchildCreateEnquiry">
                                                                                <p>{data.delivery}</p>
                                                                            </td>
                                                                            <td className="tdchildCreateEnquiry">
                                                                                <p>{data.salesDocument}</p>
                                                                            </td>
                                                                            <td className="tdchildCreateEnquiry">
                                                                                <p>{data && data.billingDocument ? data.billingDocument : "-"}</p>
                                                                            </td>
                                                                            <td className="tdchildCreateEnquiry">
                                                                                {/* <p>{ele.userName}</p> */}
                                                                                <div className="">{ele.userName ? ele.userName : "-"}</div>
                                                                                {sessionStorage.getItem("userRoleCreatedBy") ? <div className="">({sessionStorage.getItem("userRoleCreatedBy")})</div> : ""}
                                                                                {/* <div className="dealer-table-subcontent">{order.userRole ? (order.userRole) : "-"}</div> */}
                                                                            </td>
                                                                            <td className="tdchildCreateEnquiry">
                                                                                <p>{moment(new Date(viewDelivery.data && viewDelivery.data.parentOrder && viewDelivery.data.parentOrder.createdOn)).utc().format('DD MMM YYYY')}</p>
                                                                            </td>
                                                                            <td className="tdchildCreateEnquiry ">{ele.status}</td>
                                                                            <td className="tdchildCreateEnquiry font-10 font-weight-700 text-444">
                                                                                <Tooltip title="View Delivery Details" placement="top">
                                                                                    <IconButton size="small" className={classes.actionButton}
                                                                                        onClick={() => {
                                                                                            handleredirect(i)
                                                                                            sessionStorage.setItem("selectedDelivery", i)
                                                                                            sessionStorage.setItem('selectedOrderMake', ele.make);
                                                                                            dispatch(selectedViewDelivery({ enquiryNumber: ele.enquiryNumber, make: ele.make, deliveryNo: data.delivery }));
                                                                                        }}
                                                                                    >
                                                                                        <VisibilityIcon fontSize="inherit" />
                                                                                    </IconButton>
                                                                                </Tooltip>
                                                                                {data.billingDocument === "" ?
                                                                                    <Tooltip title="Invoice no. is not generated" placement="top">
                                                                                        <IconButton className="exportExcel" size="small" >
                                                                                            <ArrowDownwardIcon
                                                                                                style={{ border: "2px solid #1A9FE0", borderRadius: "8px" }}
                                                                                                fontSize="inherit"
                                                                                                onClick={() => {
                                                                                                    // let checkDate = viewDelivery.data && viewDelivery.data.parentOrder && viewDelivery.data.parentOrder.createdOn
                                                                                                    // setOrderCreatedOnDate(checkDate);

                                                                                                    // let restrictFromDate = new Date("2022-11-22T00:00:00Z");
                                                                                                    // checkDate = new Date(checkDate);
                                                                                                    // console.log("checkDate > restrictFromDate", checkDate, ">", restrictFromDate)

                                                                                                    // if (checkDate > restrictFromDate) {
                                                                                                    //     exportData(data.billingDocument, ele)
                                                                                                    // } else {
                                                                                                    //     exportData(data.salesDocument, ele)
                                                                                                    // }
                                                                                                    setOpen(true)
                                                                                                    setErrorMessage("Invoice number is not generated")
                                                                                                }}
                                                                                            />
                                                                                        </IconButton>
                                                                                    </Tooltip>
                                                                                    :
                                                                                    <IconButton className="exportExcel" size="small" >
                                                                                        <ArrowDownwardIcon style={{ border: "2px solid #1A9FE0", borderRadius: "8px" }}
                                                                                            fontSize="inherit" onClick={() => {
                                                                                                let checkDate = viewDelivery.data && viewDelivery.data.parentOrder && viewDelivery.data.parentOrder.createdOn
                                                                                                setOrderCreatedOnDate(checkDate);

                                                                                                let restrictFromDate = new Date("2022-11-22T00:00:00Z");
                                                                                                checkDate = new Date(checkDate);
                                                                                                if (checkDate > restrictFromDate) {
                                                                                                    exportData(data.billingDocument, ele)
                                                                                                } else {
                                                                                                    exportData(data.salesDocument, ele)
                                                                                                }
                                                                                            }}
                                                                                        />
                                                                                    </IconButton>
                                                                                }
                                                                            </td>
                                                                        </tr>

                                                                    </>
                                                                )
                                                            })}
                                                        </>
                                                    }
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                        <div className="col-12 d-flex justify-content-end">
                                            <TablePagination
                                                rowsPerPageOptions={[10]}
                                                component="div"
                                                count={delivery && delivery.length}
                                                rowsPerPage={rowsPerPage}
                                                page={page}
                                                onPageChange={handlePageChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                    <Prompt when={prompt} message={"By Refreshing data will be lost are u sure"}></Prompt>
                </div>

            }


        </Fragment>
    );
};

export default DeliveryList;