/* eslint-disable */
import React, { Dispatch, useEffect } from "react";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { Button, IconButton, DialogTitle } from '@material-ui/core';
import { Link, useHistory } from "react-router-dom";
import { makeStyles, createTheme } from '@material-ui/core/styles';
import { useDispatch, useSelector } from "react-redux";
import Snackbar from '../../common/components/CreatedEnquirySnackbar';
import CloseIcon from '@material-ui/icons/Close';
import { FormControl, FormControlLabel, Radio, RadioGroup, TextField } from "@material-ui/core";
import { getDetailsBySapId, getGenrateOTP, setCustomerType, setCustomerTier, resetValuesOnCancel } from "../../store/actions/onboard.actions";
import { get } from "lodash";
import { useMsal } from "@azure/msal-react";
import { signOut, useUserDispatch } from "../../context/UserContext";
import { generateB2bUserId } from "../../store/actions/dealer.action";
import WarningIcon from "@material-ui/icons/Warning";
import { onboardingService } from "../../services/onboard.service";

const useStyles = makeStyles((theme) => ({
  iconButton: {
    padding: 10,
  },
  goToLogin: {
    backgroundColor: '#EAEAEA',
    width: 125,
    fontWeight: 800,
    fontFamily: 'Nunito',
    fontSize: '0.688rem',
    color: '#666666',
    borderRadius: '2.5rem',
    height: 35,
    textAlign: 'center',
    marginRight: 24
  },
  correctionText: {
    color: '#616161',
    fontSize: '0.875rem',
    fontFamily: 'Nunito',
    fontWeight: 700,
    textTransform: 'inherit'
  },
  closeButton: {
    backgroundColor: '#EAEAEA',
    width: 120,
    fontWeight: 800,
    textTransform: 'capitalize',
    fontFamily: 'Nunito',
    fontSize: '0.688rem',
    color: '#666666',
    borderRadius: '2.5rem',
    height: 35,
    textAlign: 'center',
    marginRight: 24
  },
  icon: {
    float: "left",
    // margin: "0 10px px 0",
    height: "100%",
    fill: "white",
  },
  close: {
    color: '#000000',
    fontWeight: 900
  }
}));

const theme = createTheme({
  palette: {
    secondary: {
      main: '#1A9FE0'
    },
  }
});

export function AddNewDealer(props: any) {
  const dispatch: Dispatch<any> = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [customerNumber, setCustomerNumber] = React.useState<any>();
  const [successMessage, setSuccessMessage] = React.useState('');
  const history = useHistory();
  const [errorMessage, setErrorMessage] = React.useState<any>();
  const [customerNumberError, setCustomerNumberError] = React.useState(false);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [openErrorSnackbar, setOpenErrorSnackbar] = React.useState(false);
  const [customerVerifyOTP, setCustomerVerifyOTP] = React.useState<any>();
  const [customerVerifyOTPError, setCustomerVerifyOTPError] = React.useState<boolean>(false);
  const [selected, setSelected] = React.useState<any>('new');
  const { profileDetails }: any = useSelector((state: any) => state.profileDetails);
  const { verifyOtp, generateOTP, customerType, customerTypeData }: any = useSelector((state: any) => state.onboard);
  const { openCustomerDialog, verifySuccess } = props;
  const [verify, setVerify] = React.useState<any>(false);
  const [error, setError] = React.useState<any>();
  const [notSelected, setNotSelected] = React.useState<any>(false);
  const userType: any = (profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.basicInfo && profileDetails.data.data.basicInfo.userType) || "";
  const [selectedTier, setSelectedTier] = React.useState<any>(userType === "FSE" ? "Int. Business" : "Dealer");
  const [selectedSubType, setSelectedSubType] = React.useState<any>();
  const [selectedGroupCode, setSelectedGroupCode] = React.useState<any>('');
  const [openConfirmation, setOpenConfirmation] = React.useState<any>(false);
  const b2bUserId = get(profileDetails, "data.data.basicInfo.b2bUserId", "");
  const isExistingUser = get(profileDetails, "data.data.basicInfo.isExistingUser", "");
  const userDispatch = useUserDispatch();


  const handleopenCloseSnackbar = (open?: any) => {
    setOpenSnackbar(open ? true : false);
  };

  const handleopenCloseErrorSnackbar = (open?: any) => {
    setOpenErrorSnackbar(open ? true : false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event: any) => {
    setSelectedSubType(null);
    setSelected(event.target.value);
    setCustomerNumber(null);
    setNotSelected(false);
    setError(null);
    setCustomerNumberError(false);
    setSelectedGroupCode("")
    dispatch(setCustomerType(event.target.value));
  };

  const handleChangeTier = (e: any) => {
    // setNotSelected(false);
    setCustomerNumber(null);
    setError(null);
    setSelectedSubType(null);
    sessionStorage.setItem('customerOnboardingType', e.target.value);
    // setSelected(e.target.value);
    let value: any = e.target.value
    if (value === "Dealer" || value === "Workshop") {
      let data = customerTypeData && customerTypeData.data && customerTypeData.data.length > 0 && customerTypeData.data[0].customerTypes && customerTypeData.data[0].customerTypes.length > 0 && customerTypeData.data[0].customerTypes.filter((ele: any) => ele.value === e.target.value)
      setSelectedGroupCode(data && data[0] && data[0].subType && data[0].subType[0] && data[0].subType[0].groupCode)
    } else {
      setSelectedGroupCode("")
    }
    setSelectedTier(e.target.value);
  }

  const handleChangeSubTask = (e: any, groupCode: any) => {
    setCustomerNumber(null);
    setError(null);
    setSelectedGroupCode(groupCode)
    setSelectedSubType(e.target.value);
  }

  const handleCustomerChange = (e: any) => {
    setCustomerNumber(e.target.value);
    if (e.target.required) {
      if (e.target.value === "" || e.target.value.trim() === "") {
        setCustomerNumberError(true);
      } else if (validateCustomerNumber(e.target.value) === false) {
        setCustomerNumberError(true);
      }
      else {
        setCustomerNumberError(false);
      }
    }
  }

  function validateCustomerNumber(number: any) {
    const re = /^[0-9]+$/;
    return re.test(number);
  }

  function validateVarificationCode(number: any) {
    const re = /^\d{6}$/;
    return re.test(number);
  }

  const validateSubtype = () => {
    if (selectedTier !== "Dealer" && selectedTier !== "Workshop") {
      if (selectedGroupCode === "" || selectedGroupCode === null) {
        setErrorMessage("Please select at least one sub type");
        handleopenCloseErrorSnackbar(true);
      } else {
        handleopenCloseErrorSnackbar(false);
        setOpenConfirmation(true);
      }
    } else {
      setOpenConfirmation(true);
    }
  };

  const handleCustomerVerifyOTPChange = (e: any) => {
    setCustomerVerifyOTP(e.target.value && e.target.value.length ? e.target.value : "");
    if (e.target.value === "" || e.target.value.trim() === "") {
      if (!validateVarificationCode(customerVerifyOTP)) {
        setCustomerVerifyOTPError(true);
      }
      else {
        setCustomerVerifyOTPError(false);
      }
    }
  }

  useEffect(() => {
    if (generateOTP && generateOTP.data) {
      setError(null);
      setVerify(true);
    } else if (generateOTP && generateOTP.err) {
      setError(generateOTP.err.error);
    }
  }, [generateOTP])

  const handleSubmit = async () => {
    dispatch(setCustomerTier(selectedTier));
    if (selected === 'new') {
      if (selectedTier === "Dealer" || selectedTier === "Workshop") {
        let value = customerTypeData && customerTypeData.data && customerTypeData.data.length > 0 && customerTypeData.data[0].customerTypes && customerTypeData.data[0].customerTypes.length > 0 && customerTypeData.data[0].customerTypes.filter((ele: any) => ele.value === selectedTier)
        setSelectedGroupCode(value && value[0] && value[0].subType && value[0].subType[0] && value[0].subType[0].groupCode)
        validateSubtype();
      } else {
        validateSubtype();
      }
    } else {
      if (customerNumber) {
        dispatch(setCustomerType('existing'));
        sessionStorage.setItem('customerType', 'existing');
        let number = customerNumber.length <= 9 && customerNumber[0] !== "0" ? "0" + customerNumber : customerNumber
        setCustomerNumber(number);
        history.push('/addnewdealer');
      } else {
        setCustomerNumberError(true);
      }
    }
  }

  // const handleSubmit = async () => {
  //   if (selected === 'new') {
  //     // setOpenConfirmation(true);
  //     if (selectedTier === "Dealer" || selectedTier === "Workshop") {
  //       let value = customerTypeData && customerTypeData.data && customerTypeData.data.length > 0 && customerTypeData.data[0].customerTypes && customerTypeData.data[0].customerTypes.length > 0 && customerTypeData.data[0].customerTypes.filter((ele: any) => ele.value === selectedTier)
  //       setSelectedGroupCode(value && value[0] && value[0].subType && value[0].subType[0] && value[0].subType[0].groupCode)
  //       validateSubtype();
  //     } else {
  //       validateSubtype();
  //     }
  //   } else {
  //     if (customerNumber) {
  //       let appendedString: any = customerNumber + '';
  //       if (appendedString.length < 10) {
  //         let newString = '';
  //         let customerLength = 10 - appendedString.length;
  //         for (let i = 0; i < customerLength; i++) {
  //           newString += '0'
  //         }
  //         appendedString = newString + customerNumber;
  //       }
  //       setCustomerNumber(appendedString);
  //       dispatch(setCustomerType('existing'));
  //       sessionStorage.setItem('customerType', 'existing');
  //       dispatch(getGenrateOTP(appendedString, b2bUserId))
  //       setCustomerNumberError(false);
  //     } else {
  //       setCustomerNumberError(true);
  //     }
  //   }
  // }

  const submitCustomer = async () => {
    dispatch(setCustomerType('new'));
    setOpen(false);
    verifySuccess();
    let data = {
      "isNewCustomer": true,
      "createdBy": sessionStorage.getItem('webApplicationLoginId'),
    }
    let res: any = await dispatch(generateB2bUserId(data));
    if (res && res.message && res.message.status === "S") {
      let data: any = {
        "b2bUserId": res && res.data && res.data.createdByB2bUserId,
        "customerType": selectedTier,
        "isNewCustomer": true,
        "groupCode": selectedGroupCode,
      }
      await onboardingService.updateSelectedCustomerType(data);
    }
    if (res && res.data) {
      history.push('/addnewdealer');
    }
    sessionStorage.setItem('sapCustomerId', '');
    sessionStorage.setItem('customerType', 'new');
    sessionStorage.setItem('groupCode', selectedGroupCode);
  }

  const resendVerificationLink = () => {
    dispatch(getGenrateOTP(customerNumber, b2bUserId));
    setCustomerVerifyOTP("");
  }

  async function handleDetials() {
    if (selected === 'new') {
      if (selectedTier === "Dealer" || selectedTier === "Workshop") {
        let value = customerTypeData && customerTypeData.data && customerTypeData.data.length > 0 && customerTypeData.data[0].customerTypes && customerTypeData.data[0].customerTypes.length > 0 && customerTypeData.data[0].customerTypes.filter((ele: any) => ele.value === selectedTier)
        setSelectedGroupCode(value && value[0] && value[0].subType && value[0].subType[0] && value[0].subType[0].groupCode)
        validateSubtype();
      } else {
        validateSubtype();
      }
    } else {
      if (customerNumber) {
        let number = customerNumber.length <= 9 && customerNumber[0] !== "0" ? "0" + customerNumber : customerNumber
        setCustomerNumber(number)
        let data: any = await dispatch(getDetailsBySapId(number));
        let groupCode = data && data.data && data.data && data.data.customerInformation && data.data.customerInformation.customerGroupCode
        let customerFirstName = data && data.data && data.data && data.data.customerInformation && data.data.customerInformation.customerFirstName
        let customerType = data && data.data && data.data && data.data.customerInformation && data.data.customerInformation.customerType
        setSelectedTier(customerType)
        if (data && data.data && data.data && data.data.customerInformation && data.data.customerInformation.organizationIds) {
          let res = data.data.customerInformation.organizationIds.filter((ele: any) => {
            return ele.organizationId === "2195"
          })
          if (customerType && customerType.toLowerCase() === 'workshop') {
            if (res.length >= 1) {
              sessionStorage.setItem('customerOnboardingType', customerType);
              sessionStorage.setItem('customerFirstName', customerFirstName);
              if (groupCode) {
                setSelected(customerType ? customerType.toLowerCase() : selected)
                let payloadData = {
                  "isNewCustomer": true,
                  "createdBy": sessionStorage.getItem('webApplicationLoginId'),
                }
                let res: any = await dispatch(generateB2bUserId(payloadData));
                if (res && res.message && res.message.status === "S") {
                  let responseData: any = {
                    "b2bUserId": res && res.data && res.data.createdByB2bUserId,
                    "customerType": customerType,
                    "isNewCustomer": false,
                    "groupCode": groupCode,
                  }
                  let updateCustomer: any = await onboardingService.updateSelectedCustomerType(responseData);

                  if (updateCustomer && updateCustomer.message && updateCustomer.message && updateCustomer.message.status === "S") {
                    setOpenConfirmation(true);
                  } else {
                    setError(updateCustomer && updateCustomer.message && updateCustomer.message && updateCustomer.message)
                  }
                }
                else {
                  setError(data && data.message)
                }
              } else {
                setError(data && data.error)
              }
            } else {
              setError("Tradepoint brand is required for existing workshop users")
              // setOpenErrorSnackbar(true)
            }
          } else if ((customerType && customerType.toLowerCase() === 'dealer') || (customerType && customerType.toLowerCase() === 'government') || (customerType && customerType.toLowerCase() === "fleet") || (customerType === "Int. Business")) {
            // if (((customerType && customerType.toLowerCase() === 'dealer') || (customerType && customerType.toLowerCase() === 'government') || (customerType && customerType.toLowerCase() === "fleet")) && res.length >= 1) {
            //   setError("OES brand (2195) is detected. Kindly connect with your KAM.")
            // }
            //  else {
            sessionStorage.setItem('customerOnboardingType', customerType);
            sessionStorage.setItem('customerFirstName', customerFirstName);
            if (groupCode) {
              setSelected(customerType ? customerType.toLowerCase() : selected)
              let payloadData = {
                "isNewCustomer": true,
                "createdBy": sessionStorage.getItem('webApplicationLoginId'),
              }
              let res: any = await dispatch(generateB2bUserId(payloadData));
              if (res && res.message && res.message.status === "S") {
                let responseData: any = {
                  "b2bUserId": res && res.data && res.data.createdByB2bUserId,
                  "customerType": customerType,
                  "isNewCustomer": false,
                  "groupCode": groupCode,
                }
                let updateCustomer: any = await onboardingService.updateSelectedCustomerType(responseData);

                if (updateCustomer && updateCustomer.message && updateCustomer.message && updateCustomer.message.status === "S") {
                  setOpenConfirmation(true);
                } else {
                  setError(updateCustomer && updateCustomer.message && updateCustomer.message && updateCustomer.message)
                }
              }
              else {
                setError(data && data.message)
              }
            } else {
              setError(data && data.error)
            }
            // }
          }
        } else {
          setError(data && data.message)
        }
      } else {
        setError("Customer Number is empty")
        setCustomerNumberError(true);
      }
    }
  }

  useEffect(() => {
    if (profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.basicInfo && profileDetails.data.data.basicInfo.isExistingUser === false && !customerType) {
      setOpen(true);
    }
  }, [profileDetails, customerType])

  useEffect(() => {
    if (openCustomerDialog) {
      setVerify(false);
      handleopenCloseSnackbar(false);
      setOpen(true);
    }
  }, [openCustomerDialog])

  const classes = useStyles();
  const { instance } = useMsal();

  return (
    <Dialog
      open={open ? true : false}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          setOpen(false)
        }
      }}
      aria-labelledby="form-dialog-title"
      aria-describedby="form-dialog-description"
      fullWidth={true}
      disableEscapeKeyDown
      maxWidth="xs"
      className="typeDialogParent typeDialogParent1"
    >
      <Snackbar
        open={openSnackbar}
        handleopenClose={() => {
          handleopenCloseSnackbar(false)
        }} message={successMessage} />
      <Snackbar
        open={openErrorSnackbar}
        type="error"
        handleopenClose={() => {
          handleopenCloseErrorSnackbar(false)
        }} message={errorMessage} />
      <DialogTitle>
        {openConfirmation && (
          <div className="justifyContentHorizontalSpacebetween align-items-center mt-3">
            <h4 className="page-title px-3 ml-2">Confirmation</h4>
            {isExistingUser && (
              <IconButton onClick={() => { setOpen(false); props.setOpenCustomerDialog(false); setSelectedSubType(null); setSelectedGroupCode(null); setSelectedTier(userType === "FSE" ? "Int. Business" : "Dealer"); }}>
                <CloseIcon fontSize="inherit" className={classes.close} />
              </IconButton>
            )}
          </div>
        )}
      </DialogTitle>
      <DialogTitle>
        {!verify && !openConfirmation && (
          <div className="justifyContentHorizontalSpacebetween align-items-center">
            <h4 className="page-title ml-3">Please make the selection</h4>
            {isExistingUser && (
              <IconButton onClick={() => { setOpen(false); props.setOpenCustomerDialog(false); }}>
                <CloseIcon fontSize="inherit" className={classes.close} />
              </IconButton>
            )}
          </div>
        )}
        {verify && (
          <div className="justifyContentHorizontalSpacebetween align-items-center">
            <h4 className="page-title">Verify Your Email</h4>
            {isExistingUser && (
              <IconButton onClick={() => { setOpen(false); props.setOpenCustomerDialog(false); setCustomerVerifyOTP(""); }}>
                <CloseIcon fontSize="inherit" className={classes.close} />
              </IconButton>
            )}
          </div>
        )}
      </DialogTitle>
      <DialogContent>
        {!verify && !openConfirmation && (
          <div>
            <FormControl component="fieldset" name="customer-type" className="mt-4 ml-4">
              <h6 className="page-title" style={{ fontSize: "15px" }}>Select Customer Type</h6>
              <RadioGroup className="mt-2" row onChange={handleChange} value={selected}>
                <FormControlLabel
                  value="new"
                  control={<Radio color="primary" />}
                  label="New Customer"
                />
                <FormControlLabel
                  value="existing"
                  disabled={userType !== "FSE"}
                  control={<Radio color="primary" />}
                  label="Existing Al Futtaim Customer"
                  className="ml-5"
                />
              </RadioGroup>
            </FormControl>
          </div>
        )}
        {!verify && !openConfirmation && (
          <div>
            {selected && selected !== '' && selected === 'new' && (
              <FormControl component="fieldset" name="customer-tier" className="mt-2">
                <h1 className="page-title ml-4" style={{ fontSize: "15px" }}>You want to be ?</h1>
                <RadioGroup className="mt-2 ml-4" row onChange={handleChangeTier} value={selectedTier} >
                  {customerTypeData && customerTypeData.data && customerTypeData.data.length > 0 && customerTypeData.data[0].customerTypes && customerTypeData.data[0].customerTypes.length > 0 && customerTypeData.data[0].customerTypes.map((type: any) => (
                    <>
                      <>
                        {userType === "FSE" ?
                          <FormControlLabel
                            value={type.value}
                            disabled={type.value === "Dealer"}
                            control={<Radio color="primary" />}
                            label={type.Name}
                            className="px-0 col-12 mb-0"
                          ></FormControlLabel>
                          : <FormControlLabel
                            value={type.value}
                            disabled={type.value === "Int. Business" || type.value === "Workshop" || type.value === "Government" || type.value === "Fleet"}
                            control={<Radio color="primary" />}
                            label={type.Name}
                            className="px-0 col-12 mb-0"
                          ></FormControlLabel>}
                        <span className="ml-2 row"><p className="ml-4 row font-customer-type-subheader">{type.description}</p></span>
                        {type && type.subType && type.subType.map((ele: any, i: any) => {
                          return <>
                            {selectedTier === type.value && type.value !== "Dealer" && type.value !== "Workshop" ?
                              <FormControl component="fieldset" name="customer-tier-type" className="col-12">
                                <RadioGroup className="mt-2" row onChange={((e: any) => { handleChangeSubTask(e, ele.groupCode) })} value={selectedSubType} >
                                  <FormControlLabel
                                    value={ele.name}
                                    control={<Radio color="primary" />}
                                    label={ele.name}
                                    className="ml-3"
                                  ></FormControlLabel>
                                </RadioGroup>
                              </FormControl>
                              : ""}
                          </>
                        })}
                      </>
                      <>
                      </>
                    </>
                  ))}
                </RadioGroup>
              </FormControl>
            )}
            {selected === 'existing' && (
              <TextField id="customer Number"
                value={customerNumber}
                error={customerNumberError}
                onKeyDown={(e: any) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                onChange={(e: any) => handleCustomerChange(e)}
                size="small" className="col-sm-12 my-2 commonRoundedInputs hiddenNo"
                type="number"
                label="Al Futtaim Customer Number" required variant="outlined" margin="dense" />
            )}
            {error && selected === 'existing' && (
              <p className="d-flex text-red text-center justify-content-center mt-3">{error}</p>
            )}
          </div>
        )}
        {openConfirmation && (
          <div className="mb-2 mt-0">
            <div className="col-12">
              <div className={`col-2 ${classes.icon}`}>
                <WarningIcon className="delete-warning-icon-customer" fontSize="small" />
              </div>
              {selected === 'int. business' ?
                <div className={`row col-9 ${classes.correctionText}`}>
                  <div className="mb-0 row mb-2">Are you sure to continue with Existing &nbsp;<span className="text-capitalize">&nbsp;</span>-<p className="row mb-0 px-3 confirmation-type-subheader text-capitalize"> {selectedTier} Customer. ?</p></div>
                </div> : <div className={`row col-9 ${classes.correctionText}`}>
                  <div className="mb-0 row mb-2">Are you sure to continue with &nbsp;<span className="text-capitalize">{selected}&nbsp;</span> Customer -<p className="row mb-0 px-3 confirmation-type-subheader text-capitalize"> {selectedTier} ?</p></div>
                </div>}
            </div>
            <div className="col-12">
              <div className="d-flex flex-row">
                <span className="document-sub-content m-2 mt-2 px-3"></span>
                <div className="d-flex flex-row">
                  {/* <h6 className="customerDialog pt-1 pb-2 mt-2"> - </h6> */}
                </div>
                <div className="d-flex flex ml-2 mb-2">
                  {/* <h6 className="document-sub-content pt-1 px-2 mt-2">Note - </h6><h6 className="document-sub-content-sub pt-1 mt-2">You will be able to change this later too.</h6> */}
                </div>
              </div>
            </div>
          </div>
        )}
      </DialogContent>
      <DialogActions className="mb-4 justify-content-end">
        {!verify && !openConfirmation && (
          <Button variant="contained" color="primary" className="rounded-pill font-size-11 mr-2"
            onClick={() => {
              handleDetials();
              sessionStorage.setItem('customerOnboardingType', selectedTier);
              sessionStorage.setItem('groupCode', selectedGroupCode);
            }}>
            Submit
          </Button>
        )}
        {openConfirmation && (
          <>
            <Button variant="contained" color="primary" className="rounded-pill font-size-11 mr-3 mt-0"
              onClick={() => {
                if (selected === "new") {
                  submitCustomer()
                } else {
                  handleSubmit();
                  setErrorMessage("");
                }
              }}>
              Yes, Continue
            </Button>
            <Button variant="contained" className={classes.closeButton}
              onClick={() => {
                setOpenConfirmation(false); setSelectedSubType(null); setSelectedGroupCode(null);
                dispatch(resetValuesOnCancel());
                setSelectedTier(userType === "FSE" ? "Int. Business" : "Dealer"); setSelected('new'); setCustomerNumberError(false); setError(null);
              }}
            >Cancel
            </Button>
          </>
        )}
        {!openConfirmation && (
          <Button variant="contained" className={classes.goToLogin}
            onClick={() => { setOpen(false); props.setOpenCustomerDialog(false); setSelectedTier(userType === "FSE" ? "Int. Business" : "Dealer"); setSelected("new"); setCustomerNumberError(false); setError(null); }}
          >Cancel</Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
