/* eslint-disable */
import React, { Dispatch, useEffect } from 'react';
import XLSX from 'xlsx';
import { useDispatch, useSelector } from "react-redux";
import { makeStyles, createTheme } from '@material-ui/core/styles';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Table from '@material-ui/core/Table';
import Dialog from '@material-ui/core/Dialog';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import DeleteIcon from '@material-ui/icons/Delete';
import { getFavoritesList, getFavoriteDetails, deleteFavorites } from "../../store/actions/favorites.actions";
import DeleteDialog from '../../common/components/deleteDialog';
import Button from '@material-ui/core/Button';
import _, { get } from "lodash";
import GetAppIcon from '@material-ui/icons/GetApp';
import OpenInNewOutlinedIcon from '@material-ui/icons/OpenInNewOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import { ThemeProvider } from '@material-ui/styles';
import SearchBar from './searchBar';
import Snackbar from '../../common/components/CreatedEnquirySnackbar';
import CreateFavorite from './createFavorite';
import ViewFavorite from './viewFavorite';
import AddIcon from '@material-ui/icons/Add';
import { getNewBrand, getNewlyAddedBrand } from '../../common/components/util';
import './favorites.css';
import FullscreenLoader from './../../common/components/FullscreenLoader';
import moment from "moment-timezone";
import AnnouncementIcon from '../../common/components/AnnouncementIcon';
import NotificationIcon from '../../common/components/NotificationIcon';
import { useCallback } from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  iconButton: {
    padding: 10,
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  visuallyHidden: {
    border: 0,
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,

  },
  title: {
    flex: '1 1 100%',
    fontSize: 30,
  },
  button: {
    borderRadius: "1.25rem",
    minWidth: 170,
    margin: "0 0 0 .5%",
    backgroundColor: "#1A9FE0",
    color: "white",
    height: "2.25rem",
    fontSize: "0.7rem",

  },
  inCellButton: {
    color: '#1A9FE0',
    textTransform: 'none',
    fontSize: 12,
    padding: "0 0.9rem",
  },
  header: {
    color: "#133F8A",
    fontWeight: 900,
    fontSize: '0.875rem',
    fontFamily: 'Nunito',
    textAlign: 'left',
    minWidth: 90,
    maxWidth: 120
  },
  bodyContent: {
    color: '#666666',
    fontWeight: 600,
    fontSize: '0.75rem',
    fontFamily: 'Nunito',
  },
  bodyContentActionHeader: {
    color: "#133F8A",
    fontWeight: 900,
    fontSize: '0.875rem',
    fontFamily: 'Nunito',
    textAlign: 'left',
    maxWidth: 60
  },
  bodyContentAction: {
    maxWidth: 60
  },
}));
const theme = createTheme({
  palette: {
    secondary: {
      main: '#1A9FE0'
    },
  }
});

const headCells = [
  { id: 'name', numeric: false, disablePadding: true, label: 'Favorite Name' },
  { id: 'status', numeric: true, disablePadding: false, label: 'Status' },
  { id: 'modDate', numeric: true, disablePadding: false, label: 'Modified Date' },
  { id: 'brands', numeric: true, disablePadding: false, label: 'Brands' },
  { id: 'createdBy', numeric: true, disablePadding: false, label: 'Created by' },
  { id: 'actions', numeric: true, disablePadding: false, label: 'Action' },
];

function EnhancedTableHead(props: any) {
  const { numSelected, onSelectAllClick, rowCount, } = props;
  const classes = useStyles();

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all favourites' }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            className={headCell.id === 'actions' ? classes.bodyContentActionHeader : classes.header}
            key={headCell.id}
            align={headCell.numeric ? headCell.id === 'actions' || headCell.id === 'brands' ? 'center' : 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function Favorites() {
  const classes = useStyles();
  const [selected, setSelected] = React.useState<any>([]);
  const [selectedFavorites, setSelectedFavorites] = React.useState<any>([]);
  const [page, setPage] = React.useState(0);
  const rowsPerPage = 10;
  const dispatch: Dispatch<any> = useDispatch();
  const [successMessage, setSuccessMessage] = React.useState<any>(null);
  const { favoritesList, createFavorite, deleteFavorite, updateFavorite }: any = useSelector((state: any) => state.favorites);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [openErrorSnackbar, setOpenErrorSnackbar] = React.useState(false);
  const [openViewFavorite, setOpenViewFavorite] = React.useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [isAdmin, setIsAdmin] = React.useState(false);
  const [createdByME, setcreatedByME] = React.useState<any>(false);
  const [searchText, setsearchText] = React.useState<any>('');
  const { profileDetails }: any = useSelector((state: any) => state.profileDetails);
  const customerTierType = get(profileDetails, 'data.data.customerInfo.customerTierType', '');
  const customerTypeAccess = get(profileDetails, 'data.data.basicInfo.customerTypeAccess', '');
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleopenCloseSnackbar = (open?: any) => {
    setOpenSnackbar(open ? true : false);
  };

  const handleopenCloseErrorSnackbar = (open?: any) => {
    setOpenErrorSnackbar(open ? true : false);
  };

  useEffect(() => {
    if (selected) {
      let favorites: any = [];
      favoritesList && favoritesList.data && favoritesList.data.favouriteList && favoritesList.data.favouriteList.length > 0 && favoritesList.data.favouriteList.filter((favorite: any) => {
        selected.map((id: any) => {
          if (favorite.id === id) favorites.push(favorite);
          return undefined;
        })
        return undefined;
      });
      setSelectedFavorites(favorites);
    }
  }, [selected, favoritesList])

  const loadFavoritesList = useCallback((pageNumber?: any) => {
    setPage(pageNumber ? pageNumber : 0);
    dispatch(getFavoritesList(createdByME, searchText, pageNumber));
  }, [createdByME, dispatch])

  useEffect(() => {
    if (profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.basicInfo && profileDetails.data.data.basicInfo.userType === 'Admin') {
      setIsAdmin(true);
      loadFavoritesList();
    } else if (profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.basicInfo && profileDetails.data.data.basicInfo.userType === 'User') {
      setIsAdmin(false);
      loadFavoritesList();
    }
  }, [loadFavoritesList, profileDetails])

  useEffect(() => {
    if (createFavorite && createFavorite.data) {
      handleClose();
      setSuccessMessage('Favorite created successfully.');
      handleopenCloseSnackbar(true);
      loadFavoritesList();
      setSelected([]);
      setSelectedFavorites([]);
    }
  }, [createFavorite, loadFavoritesList]);

  useEffect(() => {
    if (updateFavorite && updateFavorite.data) {
      handleClose();
      setSuccessMessage('Favorite updated successfully.');
      handleopenCloseSnackbar(true);
      handleopenCloseViewFavorite(false);
      loadFavoritesList();
      setSelected([]);
      setSelectedFavorites([]);
    }
  }, [updateFavorite, loadFavoritesList]);

  useEffect(() => {
    if (deleteFavorite && deleteFavorite.data) {
      setSuccessMessage('Favorites deleted successfully.');
      handleopenCloseSnackbar(true);
      handleopenCloseViewFavorite(false);
      loadFavoritesList();
      setSelected([]);
      setSelectedFavorites([]);
    }
  }, [deleteFavorite, loadFavoritesList]);

  const onClickdeleteFavorite = (favorite: any) => {
    handleopenClose(true);
    dispatch(getFavoriteDetails(favorite));
    let value: any = [];
    value.push(favorite.id);
    setSelected(value);
  }

  // useEffect(() => {
  //   if (searchText === '') loadFavoritesList();
  // }, [searchText, loadFavoritesList]);

  const handleopenClose = (open?: any) => {
    setOpenDeleteDialog(open ? true : false);
    setSuccessMessage(null);
  };

  const exportData = () => {
    let favorites: any = [...selectedFavorites]
    if (favorites && Array.isArray(favorites) && favorites.length > 0) {
      let filename = 'favorites.xlsx';
      var wb = XLSX.utils.book_new();
      favorites.map((item: any, index: any) => {
        let data: any = [];
        item.products.map((product: any) => {
          data.push({
            'Part No': product.productId,
            'Description': product.productIdDescription,
            Brand: (customerTierType && customerTierType.toLowerCase() === 'workshop' || customerTypeAccess.includes("workshop")) ? getNewBrand(product.make) : getNewlyAddedBrand(product.make),
            Quantity: parseInt(product.quantity),
          });
          return undefined;
        })

        var ws = XLSX.utils.json_to_sheet(data);
        XLSX.utils.book_append_sheet(wb, ws, item.name && item.name.trim() ? item.name : `favorite${index}`);
        return undefined;
      });
      XLSX.writeFile(wb, filename);
    }
  }

  const handleSelectAllClick = (event: any) => {
    if (event.target.checked) {
      const newSelecteds: any = favoritesList && favoritesList.data && favoritesList.data.favouriteList && favoritesList.data.favouriteList.map((fav: any) => fav.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const resetFiltersForCustomer = () => {
    setcreatedByME(false);
    setsearchText("");
  }

  const handleClick = (event: any, name: any) => {
    const selectedIndex: any = selected.indexOf(name);
    let newSelected: any = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event: any, newPage: any) => {
    loadFavoritesList(newPage);
    setPage(newPage);
  };

  const handleopenCloseViewFavorite = (open?: any) => {
    setOpenViewFavorite(open ? true : false);
  };

  const deleteMultiFavorite = () => {
    const selectedIds = [...selected];
    let myFavIds: any = [];
    if (isAdmin) {
      dispatch(deleteFavorites([...selected]));
      handleopenClose(false);
    } else {
      favoritesList && favoritesList.data && favoritesList.data.favouriteList && favoritesList.data.favouriteList.map((favorite: any) => {
        if (favorite.userId === sessionStorage.getItem('webApplicationLoginId')) {
          myFavIds.push(favorite.id);
        }
        return undefined;
      });

      let validArray = (myFavIds: any, selectedIds: any) => selectedIds.every((id: any) => myFavIds.includes(id));
      if (validArray(myFavIds, selectedIds)) {
        dispatch(deleteFavorites([...selected]));
        handleopenClose(false);
      } else {
        setSuccessMessage(`You cannot delete other user's Favorites`);
        setOpenErrorSnackbar(true);
      }
    }
  }

  const getUniqueBrands = (brands: any) => {
    let uniqueBrands = brands.filter((c: any, index: any) => {
      return brands.indexOf(c) === index;
    });
    return uniqueBrands;
  }

  return (
    <div className={classes.root}>
      <Snackbar
        open={openSnackbar}
        message={successMessage}
        handleopenClose={() => {
          handleopenCloseSnackbar(false)
        }} />
      <Snackbar
        open={openErrorSnackbar}
        type="error"
        message={successMessage}
        handleopenClose={() => {
          handleopenCloseErrorSnackbar(false)
        }} />
      {favoritesList && favoritesList.loading && <FullscreenLoader />}
      <div className="d-flex align-items-center">
        <h2 className="page-title">
          Favorites</h2>
        <div className="ml-auto">
          <AnnouncementIcon />
          <NotificationIcon />
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-12">
          <div className="d-flex align-items-center mb-2">
            <h5 className="page-sub-title">Favorites List</h5>

            <div className="row-space-start ml-auto">
              <div>
                <ThemeProvider theme={theme}>
                  <Checkbox
                    checked={createdByME}
                    onChange={() => {
                      // dispatch(getFavoritesList(!createdByME, searchText));
                      setcreatedByME(!createdByME)
                    }} className="checkbox" />
                </ThemeProvider>
                <span className="text-info mr-3">Created by me</span>
              </div>
              <SearchBar callAPI={() => {
                dispatch(getFavoritesList(createdByME, searchText));
              }} value={searchText} onChange={(e: any) => setsearchText(e.target.value)} label="Search by favorite name" className="mr-3" />
              {selected.length > 0 ? (
                <>
                  <Button variant="contained" onClick={() => handleopenClose(true)} color="secondary" className="deleteButton ml-2" startIcon={<DeleteIcon />}>
                    Delete
                  </Button>
                  <Button variant="contained"
                    onClick={() => exportData()}
                    color="secondary" className="downloadButton ml-2 pr-3" startIcon={<GetAppIcon />}>
                    Download
                  </Button>
                </>
              ) : (
                <></>
              )}
              <div className='px-2'></div>
              <Button variant="contained" className="cancel-button font-size-11 px-2 mr-2"
                onClick={() => {
                  resetFiltersForCustomer();
                }
                }>Reset Filters</Button>
              <Button
                variant="contained"
                color="secondary"
                className="createFavourite ml-2"
                startIcon={<AddIcon />}
                onClick={() => { handleClickOpen(); dispatch(getFavoriteDetails(null)); }}
              >
                Create Favorite
              </Button>

            </div>
          </div>
          <div className="cardCommon mh-300p">
            <TableContainer>
              <Table
                className={classes.title}
                aria-labelledby="tableTitle"
                aria-label="enhanced table"
              >
                <EnhancedTableHead
                  numSelected={selected.length}
                  onSelectAllClick={handleSelectAllClick}
                  rowCount={favoritesList && favoritesList.data && favoritesList.data.favouriteList && favoritesList.data.favouriteList.length}
                />
                <TableBody>
                  {favoritesList && favoritesList.data && Array.isArray(favoritesList.data.favouriteList) && favoritesList.data.favouriteList && favoritesList.data.favouriteList.length > 0 && (
                    <>
                      {favoritesList.data.favouriteList.map((favorite: any, index: any) => (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={index}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={selected && selected.includes(favorite.id) ? true : false}
                              inputProps={{ 'aria-labelledby': `enhanced-table-checkbox-${index}` }}
                              onClick={(event) => handleClick(event, favorite.id)}
                            />
                          </TableCell>
                          <TableCell component="th" id={`enhanced-table-checkbox-${index}`} scope="row" padding="none" className={classes.bodyContent}>
                            {favorite.name}
                          </TableCell>
                          <TableCell align="left" className={classes.bodyContent}>{favorite.isGlobal ? 'isGlobal' : 'Self'}</TableCell>
                          <TableCell align="left" className={classes.bodyContent}>{moment(new Date(favorite.updatedAt)).utc().format("DD MMM YY")}</TableCell>
                          <TableCell align="left" className={classes.bodyContent}>{getUniqueBrands(favorite.make).map((brand: any, index: any) => (
                            <React.Fragment key={index}>{(customerTierType && customerTierType.toLowerCase() === 'workshop' || customerTypeAccess.includes("workshop")) ? getNewBrand(brand) : getNewlyAddedBrand(brand)}{getUniqueBrands(favorite.make).length === index + 1 ? '' : ','}</React.Fragment>
                          ))}</TableCell>
                          <TableCell align="left" className={classes.bodyContent}>{favorite.userId}</TableCell>
                          <TableCell align="left">
                            <div className="row">
                              <ButtonGroup variant="text" color="primary" aria-label="text primary button group">
                                {isAdmin && (
                                  <Button
                                    className="text-info"
                                    startIcon={<EditOutlinedIcon />}
                                    onClick={() => { handleClickOpen(); dispatch(getFavoriteDetails(favorite)); }}
                                  >
                                    Edit
                                  </Button>
                                )}
                                {!isAdmin && favorite.userId === sessionStorage.getItem('webApplicationLoginId') ?
                                  <Button
                                    className="text-info"
                                    startIcon={<EditOutlinedIcon />}
                                    onClick={() => { handleClickOpen(); dispatch(getFavoriteDetails(favorite)); }}
                                  >
                                    Edit
                                  </Button>
                                  : ''}
                                <Button onClick={() => { handleopenCloseViewFavorite(true); dispatch(getFavoriteDetails(favorite)); }}
                                  className="text-info" startIcon={<OpenInNewOutlinedIcon />} >
                                  View
                                </Button>
                                {isAdmin && (
                                  <Button
                                    onClick={() => onClickdeleteFavorite(favorite)}
                                    className="text-info"
                                    startIcon={<DeleteOutlineOutlinedIcon />}
                                  >
                                    Delete
                                  </Button>
                                )}
                                {!isAdmin && favorite.userId === sessionStorage.getItem('webApplicationLoginId') ?
                                  <Button
                                    onClick={() => onClickdeleteFavorite(favorite)}
                                    className="text-info"
                                    startIcon={<DeleteOutlineOutlinedIcon />}
                                  >
                                    Delete
                                  </Button>
                                  : ''}
                              </ButtonGroup>
                            </div>
                          </TableCell>
                        </TableRow>
                      ))
                      }
                    </>
                  )}
                  <TableRow>
                    <TableCell colSpan={6}><h6> Total:{favoritesList && favoritesList.data && favoritesList.data.totalCount}</h6></TableCell>
                    <TableCell colSpan={6}>
                      <TablePagination
                        rowsPerPageOptions={[10]}
                        component="div"
                        count={favoritesList && favoritesList.data && favoritesList.data.totalCount ? favoritesList && favoritesList.data && favoritesList.data.totalCount : 0}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        className="createFavoriteDialog"
        maxWidth="md"
      >
        <CreateFavorite setOpenDialog={handleClose} />
      </Dialog>
      <ViewFavorite
        open={openViewFavorite}
        handleopenClose={(data: any) => {
          handleopenCloseViewFavorite(false)
        }}
      />
      <DeleteDialog
        open={openDeleteDialog}
        text="Are you sure to delete this favorite ?"
        handleopenClose={(data: any) => {
          if (data === 'delete') deleteMultiFavorite();
          if (!data) handleopenClose(false);
        }} />
    </div>
  );
}
