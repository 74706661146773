/* eslint-disable */
import React, { Dispatch, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import CloseIcon from '@material-ui/icons/Close';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import moment from "moment-timezone";
import {
  Checkbox,
} from '@material-ui/core';
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import FullscreenLoader from '../../common/components/FullscreenLoader';
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import SearchIcon from '@material-ui/icons/Search';
import { getAgreementDocs, sendCategoryCertificateMail, sendCustomerAgreementMail } from '../../store/actions/dealer.action';
import Snackbar from "./../../common/components/CreatedEnquirySnackbar";
import { isEmpty, isString } from 'lodash';

export interface AgreementDialogProps {
  open: boolean;
  selectedTabValue: number;
  onClose: (value: boolean) => void;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`dealerdetail-tabpanel-${index}`}
      aria-labelledby={`dealerdetail-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div>{children}</div>
      )}
    </div>
  );
}

function allTabProps(index: any) {
  return {
    id: `dealerdetail-tab-${index}`,
    'aria-controls': `dealerdetail-tabpanel-${index}`,
  };
}

function Row(props: any) {
  const [open, setOpen] = React.useState(props.filterByCustomer === 'Dealer' && props.tierName === 'Silver' ? true : props.filterByCustomer !== 'Dealer' ? true : false);
  const [isTierChecked, setIsTierChecked] = React.useState(true);
  const [customerChecked, setCustomerChecked] = React.useState(props.values.map(() => true)); // All customers checked by default
  const handleCustomerCheckboxChange = (index: any) => {
    const updatedChecked = [...customerChecked];
    updatedChecked[index] = !updatedChecked[index];
    setCustomerChecked(updatedChecked);
    // Update tier checkbox based on customer selections
    const allChecked = updatedChecked.every(Boolean);
    const allUnchecked = updatedChecked.every((checked) => !checked);

    setIsTierChecked(allChecked); // Set checked if all are checked
    if (allUnchecked) {
      setIsTierChecked(false); // Uncheck tier if all are unchecked
    }

    const updatedSelectedCustomers = getSelectedCustomers(updatedChecked);
    props.onSelectionChange(updatedSelectedCustomers, props.tierName);
  };

  const handleTierCheckboxChange = () => {
    const newTierChecked = !isTierChecked;
    setIsTierChecked(newTierChecked);
    const updatedCustomerChecked = props.values.map(() => newTierChecked);
    setCustomerChecked(updatedCustomerChecked);

    const updatedSelectedCustomers = getSelectedCustomers(updatedCustomerChecked);
    props.onSelectionChange(updatedSelectedCustomers, props.tierName);
  };

  const getSelectedCustomers = (checkedStates: any) => {
    return props.values
      .map((customer: any, index: any) => {
        if (checkedStates[index] && customer.status.includes('Active')) {
          return {
            "sapCustomerId": customer.sapCustomerId,
            "companyName": customer.companyName,
            "customerTierType": customer.customerTierType,
            "customerEmail": customer.contactDetails && customer.contactDetails?.email ? customer.contactDetails?.email : '',
            "assignKamEmail": customer.assignedKamEmails,
            "assignKamName": customer.assignedKam,
            "customerAgreement": props.tabValue === 0 ? customer.customerAgreement : '',
            "annexureToAgreement": props.tabValue === 0 ? customer.annexureToAgreement : ''
          };
        }
        return null;
      })
      .filter(Boolean) as any;
  };

  return (
    <>
      {props.tierName !== 'otherDealers' && props?.values?.length > 0 && (
        <React.Fragment>
          <TableRow
            className="tableRowPanelsCreateEnquiry"
          >
            <TableCell className='pr-0'>
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => setOpen(!open)}
              >
                {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
              </IconButton>
            </TableCell>
            <TableCell className='px-0 mw-50p'><Checkbox checked={isTierChecked} onChange={handleTierCheckboxChange} /></TableCell>
            <TableCell colSpan={10} className="blueLabelHeaderTable">
              {props.tierName}
            </TableCell>
          </TableRow>
          {open &&
            props.tierName &&
            props.values.map((customer: any, index: any) => {
              return (
                <>
                  {customer.customerTierType !== '' && (
                    <TableRow key={customer.sapCustomerId}>
                      <TableCell className='pr-0'></TableCell>
                      <TableCell className=" px-0 mw-50p"> <Checkbox
                        disabled={props.tabValue === 0 && (!customer.customerAgreementUpdateDate && customer.customerAgreementUpdateDate === '') ? true : !customer.status.includes('Active') ? true : false}
                        checked={props.tabValue === 0 && (!customer.customerAgreementUpdateDate || customer.customerAgreementUpdateDate === '') ? false : customerChecked[index] && customer.status.includes('Active') ? true : false}
                        onChange={() => handleCustomerCheckboxChange(index)}
                      /></TableCell>
                      <TableCell className='max-w-200p pr-0'>
                        <div className="dealer-table-content">{customer.companyName}</div>
                        <div className="dealer-table-subcontent">{customer.sapCustomerId}</div>
                      </TableCell>
                      <TableCell className='pr-0'>
                        <div className="dealer-table-content">{customer.contactDetails?.telephoneNumber ? customer.contactDetails?.telephoneNumber : "-"}</div>
                        <div className="dealer-table-subcontent">{customer.contactDetails.email ? customer.contactDetails.email : "-"}</div>
                      </TableCell>
                      <TableCell className='pr-0'>
                        <div className="dealer-table-content">{customer.assignedKam ? customer.assignedKam : "-"}</div>
                        <div className="dealer-table-subcontent">{customer.assignedFSEs ? customer.assignedFSEs : "-"}</div>
                      </TableCell>
                      {props.tabValue === 0 ?
                        <TableCell className='mw-100p pr-0'>
                          <div className="dealer-table-content">{customer.agreementSentOn ? moment(customer.agreementSentOn).tz('Asia/Dubai').format("DD/MM/YYYY") : "-"}</div>
                          <div className="dealer-table-subcontent">{customer.agreementSentOn ? moment(customer.agreementSentOn).tz('Asia/Dubai').format("HH:mm:ss") : ""}</div>
                        </TableCell>
                        :
                        <TableCell className='mw-100p pr-0'>
                          <div className="dealer-table-content">{customer.categoryCertificateSentOn ? moment(customer.categoryCertificateSentOn).tz('Asia/Dubai').format("DD/MM/YYYY") : "-"}</div>
                          <div className="dealer-table-subcontent">{customer.categoryCertificateSentOn ? moment(customer.categoryCertificateSentOn).tz('Asia/Dubai').format("HH:mm:ss") : ""}</div>
                          {/* <div className="dealer-table-content">{customer.categoryCertificateSentOn ? moment(customer.categoryCertificateSentOn).tz('Asia/Dubai').format('YYYY-MM-DD HH:mm:ss') : "-"}</div> */}
                        </TableCell>
                      }
                      {props.tabValue === 0 && (
                        <TableCell className='mw-100p pr-0'>
                          <div className="dealer-table-content">{customer.customerAgreementUpdateDate ? moment(customer.customerAgreementUpdateDate).tz('Asia/Dubai').format("DD/MM/YYYY") : "-"}</div>
                          <div className="dealer-table-subcontent">{customer.customerAgreementUpdateDate ? moment(customer.customerAgreementUpdateDate).tz('Asia/Dubai').format("HH:mm:ss") : ""}</div>
                        </TableCell>
                      )}
                      {/* <TableCell className="mw-100p pr-0">
                        <div className={customer.status && customer.status === "Active" ? "dealer-table-content status-color" : "dealer-table-content text-red"}>{customer.status ? customer.status === "Document expiry issue" ? "Document Expiry Issue" : customer.status === "Deactive" ? "Inactive" : customer.status : "-"}</div>
                        {customer.status === "Deactive" ? <div className="dealer-table-subcontent dealer-table-content text-red">{!isEmpty(customer && customer.statusPlus && customer.statusPlus) ? `(${customer && customer.statusPlus && customer.statusPlus.toString().split(",").join(', ')})` : ""}</div> : ""}
                        <div className="dealer-table-subcontent dealer-table-content text-red">{customer.status !== "Deactive" && customer.docExpiryAutoDeactivateUntil && customer.docExpiryAutoDeactivate ? "Till :" : ""}{" "}{customer.status !== "Deactive" && customer.docExpiryAutoDeactivateUntil && customer.docExpiryAutoDeactivate ? customer.docExpiryAutoDeactivateUntil : ""}</div>
                      </TableCell> */}
                      {customer.status && customer.status !== "Active" && customer && customer.statusPlus && customer.statusPlus.length > 0 ?
                        <TableCell className="mw-150p">
                          <div style={{ display: "flex" }} className={!isEmpty(customer && customer.statusPlus && customer.statusPlus) && customer.status && customer.status === "Active" ? "dealer-table-content text-red" : customer.status && customer.status === "Active" ? "dealer-table-content status-color" : "dealer-table-content text-red"}>{!isEmpty(customer && customer.statusPlus && customer.statusPlus) && customer.status && customer.status === "Active" ? "Inactive" : customer.status ? customer.status === "Document expiry issue" ? "Document Expiry Issue" : customer.status === "Deactive" ? "Inactive" : customer.status : "-"}
                            </div>
                          <div className="dealer-table-subcontent dealer-table-content text-red">{!isEmpty(customer && customer.statusPlus && customer.statusPlus) ? `(${customer && customer.statusPlus && customer.statusPlus.toString().split(",").join(', ')})` : ""}</div>
                          <div className="dealer-table-subcontent dealer-table-content text-red">{customer.status !== "Deactive" && customer.docExpiryAutoDeactivateUntil && customer.docExpiryAutoDeactivate ? "Till :" : ""}{" "}{customer.status !== "Deactive" && customer.docExpiryAutoDeactivateUntil && customer.docExpiryAutoDeactivate ? customer.docExpiryAutoDeactivateUntil : ""}</div>
                        </TableCell>
                        :
                        customer.status && customer.status === "Active" && customer && customer.statusPlus && customer.statusPlus.length > 0 ?
                          <TableCell className="mw-150p">
                            <div style={{ display: "flex" }} className={!isEmpty(customer && customer.statusPlus && customer.statusPlus) && customer.status && customer.status === "Active" ? "dealer-table-content text-red" : customer.status && customer.status === "Active" ? "dealer-table-content status-color" : "dealer-table-content text-red"}>{!isEmpty(customer && customer.statusPlus && customer.statusPlus) && customer.status && customer.status === "Active" ? "Inactive" : customer.status ? customer.status === "Document expiry issue" ? "Document Expiry Issue" : customer.status === "Deactive" ? "Inactive" : customer.status : "-"}
                              </div>
                            <div className="dealer-table-subcontent dealer-table-content text-red">{!isEmpty(customer && customer.statusPlus && customer.statusPlus) ? `(${customer && customer.statusPlus && customer.statusPlus.toString().split(",").join(', ')})` : ""}</div>
                            <div className="dealer-table-subcontent dealer-table-content text-red">{customer.status !== "Deactive" && customer.docExpiryAutoDeactivateUntil && customer.docExpiryAutoDeactivate ? "Till :" : ""}{" "}{customer.status !== "Deactive" && customer.docExpiryAutoDeactivateUntil && customer.docExpiryAutoDeactivate ? customer.docExpiryAutoDeactivateUntil : ""}</div>
                          </TableCell>
                          :
                          <TableCell className="mw-150p">
                            <div className={!isEmpty(customer && customer.statusPlus && customer.statusPlus) && customer.status && customer.status === "Active" ? "dealer-table-content text-red" : customer.status && customer.status === "Active" ? "dealer-table-content status-color" : "dealer-table-content text-red"}>{!isEmpty(customer && customer.statusPlus && customer.statusPlus) && customer.status && customer.status === "Active" ? "Inactive" : customer.status ? customer.status === "Document expiry issue" ? "Document Expiry Issue" : customer.status === "Deactive" ? "Inactive" : customer.status : "-"}</div>
                            <div className="dealer-table-subcontent dealer-table-content text-red">{!isEmpty(customer && customer.statusPlus && customer.statusPlus) ? `(${customer && customer.statusPlus && customer.statusPlus.toString().split(",").join(', ')})` : ""}</div>
                            {isEmpty(customer && customer.statusPlus && customer.statusPlus) &&
                              <div className="dealer-table-subcontent dealer-table-content text-red">{customer.status !== "Deactive" && customer.docExpiryAutoDeactivateUntil && customer.docExpiryAutoDeactivate ? "Till :" : ""}{" "}{customer.status !== "Deactive" && customer.docExpiryAutoDeactivateUntil && customer.docExpiryAutoDeactivate ? customer.docExpiryAutoDeactivateUntil : ""}</div>
                            }
                          </TableCell>}
                    </TableRow >
                  )}
                </>
              )
            }
            )}
        </React.Fragment >
      )}
    </>
  )
}

const AgreementDialog: React.FC<AgreementDialogProps> = (props: AgreementDialogProps) => {
  const [tabValue, setTabValue] = React.useState(0);
  const [searchValue, setSearchValue] = React.useState<any>('');
  const [selectedRecords, setSelectedRecords] = React.useState<any>([]);
  const [filterByCustomer, setFilterByCustomer] = React.useState("Dealer")
  const [error, setError] = React.useState<any>('');
  const dispatch: Dispatch<any> = useDispatch();
  const [successMessage, setSuccessMessage] = React.useState<any>(null);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [openErrorSnackbar, setOpenErrorSnackbar] = React.useState(false);

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
    setError('');
    dispatch(getAgreementDocs('Dealer'));
    setSearchValue('');
    setFilterByCustomer('Dealer');
    setOpenErrorSnackbar(false);
    setSuccessMessage(null);
  };

  const { agreementDocs }: any = useSelector((state: any) => state.dealer);

  useEffect(() => {
    setTabValue(props.selectedTabValue);
  }, [props.selectedTabValue])


  useEffect(() => {
    if (agreementDocs && agreementDocs.data && agreementDocs.data.data) {
      setError('');
      let initialSelectedRecords: any;
      if (tabValue === 0) {
        initialSelectedRecords = Object.entries(agreementDocs.data.data).flatMap(([tierName, customers]: [any, any]) =>
          customers
            .filter((customer: any) =>
              (customer.status === "Active" || customer.status.includes("Active")) && (customer.customerAgreementUpdateDate && customer.customerAgreementUpdateDate !== '')
            )
            .map((customer: any) => ({
              sapCustomerId: customer.sapCustomerId,
              companyName: customer.companyName,
              customerTierType: customer.customerTierType,
              customerEmail: customer.contactDetails.email || '',
              assignKamEmail: customer.assignedKamEmails,
              assignKamName: customer.assignedKam,
              tierType: tierName === "otherDealers" ? 'Others' : tierName,
              "customerAgreement": tabValue === 0 ? customer.customerAgreement : '',
              "annexureToAgreement": tabValue === 0 ? customer.annexureToAgreement : ''
            }))
        );
      } else {
        initialSelectedRecords = Object.entries(agreementDocs.data.data).flatMap(([tierName, customers]: [any, any]) =>
          customers
            .filter((customer: any) =>
              (customer.status === "Active" || customer.status.includes("Active"))
            )
            .map((customer: any) => ({
              sapCustomerId: customer.sapCustomerId,
              companyName: customer.companyName,
              customerTierType: customer.customerTierType,
              customerEmail: customer.contactDetails.email || '',
              assignKamEmail: customer.assignedKamEmails,
              assignKamName: customer.assignedKam,
              tierType: tierName === "otherDealers" ? 'Others' : tierName,
              "customerAgreement": tabValue === 0 ? customer.customerAgreement : '',
              "annexureToAgreement": tabValue === 0 ? customer.annexureToAgreement : ''
            }))
        );
      }
      setSelectedRecords(initialSelectedRecords);
    }
  }, [agreementDocs]);

  const handleSelectionChange = (updatedTierRecords: any[], tierName: any) => {
    if (tierName === 'Workshop' || tierName === 'Government' || tierName === 'Fleet') {
      setSelectedRecords(updatedTierRecords)
    } else {
      setSelectedRecords((prevRecords: any) => {
        // Filter out records that match the tierName
        const filteredRecords = prevRecords.filter((record: any) => record.tierType !== tierName);
        const uniqueUpdatedRecords = updatedTierRecords.filter((newRecord: any) =>
          !prevRecords.some((existingRecord: any) => existingRecord.sapCustomerId === newRecord.sapCustomerId)
        );
        // Return combined records
        return [...filteredRecords, ...uniqueUpdatedRecords];
      });
    }
  };

  const renderRows = (data: any, onSelectionChange: (selectedCustomers: any, tierName: any) => void) => {
    let mappedData: any;
    if (filterByCustomer === 'Dealer') {
      mappedData = {
        Silver: data.Silver ? data.Silver : [],
        Gold: data.Gold ? data.Gold : [],
        Platinum: data.Platinum ? data.Platinum : [],
        Elite: data.Elite ? data.Elite : [],
        Others: data.otherDealers ? data.otherDealers : [],
      };
    } else {
      if (filterByCustomer === 'Workshop') {
        mappedData = { Workshop: data.IWS ? data.IWS : [] }
      }
      if (filterByCustomer === 'Government') {
        mappedData = { Government: data.Government ? data.Government : [] }
      }
      if (filterByCustomer === 'Fleet') {
        mappedData = { Fleet: data.Fleet ? data.Fleet : [] }
      }
    }

    let finalRows: any = [];
    Object.keys(mappedData).map((key) => {
      if (key) {
        finalRows.push(
          <Row
            tierName={key}
            filterByCustomer={filterByCustomer}
            selectedRecords={selectedRecords}
            values={mappedData[key]}
            onSelectionChange={onSelectionChange}
            tabValue={tabValue}
          />
        );
      }
    });
    return finalRows;
  };


  const sendEmail = async () => {
    if (selectedRecords && selectedRecords.length > 0) {
      let payload: any = { customers: selectedRecords }
      if (tabValue === 0) {
        let data: any = await dispatch(sendCustomerAgreementMail(payload));
        if (data && data.data) {
          setOpenErrorSnackbar(false);
          setOpenSnackbar(true);
          setSuccessMessage('Email sent successfully')
        }
      } else {
        let data: any = await dispatch(sendCategoryCertificateMail(payload));
        if (data && data.data) {
          setOpenErrorSnackbar(false);
          setOpenSnackbar(true);
          setSuccessMessage('Email sent successfully')
        }
      }
    } else {
      setOpenErrorSnackbar(true);
      setOpenSnackbar(true);
      setSuccessMessage('Please select atleast one customer')
    }
  }
  const handleopenCloseSnackbar = (open?: any) => {
    setOpenSnackbar(open ? true : false);
  };

  return (
    <>
      {agreementDocs && agreementDocs.loading && <FullscreenLoader />}
      <Snackbar
        open={openSnackbar}
        type={openErrorSnackbar ? 'error' : ''}
        message={successMessage}
        handleopenClose={() => {
          handleopenCloseSnackbar(false);
        }}
      />
      <Dialog
        open={props.open ? true : false}
        onClose={() => { props.onClose(false) }}
        fullWidth={true}
        maxWidth={'md'}
        disableEscapeKeyDown>
        <DialogTitle className="pb-0" id="form-dialog-title">
          <div className="page-title">
            Email Agreement Docs
            <IconButton aria-label="close" className="float-right" onClick={() => {
              setTabValue(0);
              props.onClose(false);
            }}>
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <div className="p-0 col-12 h-240">
          <div className='flex-end mt-3 float-right'>
            <TextField
              id="kamList"
              select
              label="Filter By CustomerType"
              variant="outlined"
              className="rounded-input-dealer mw-200p mr-2 mb-2"
              size="small"
              onChange={async (e: any) => {
                setError('');
                setFilterByCustomer(e.target.value)
                let data: any = await dispatch(getAgreementDocs(e.target.value === 'Workshop' ? 'IWS' : e.target.value, searchValue ? searchValue : ''));
                if (data && data.message && data.message.status === 'S') {
                } else {
                  setError(data.error ? data.error : data.message)
                }
              }}
              value={filterByCustomer}
            >
              <MenuItem value={"Dealer"}>
                Dealer
              </MenuItem>\ <MenuItem value={"Workshop"}>
                Workshop
              </MenuItem>\ <MenuItem value={"Government"}>
                Government
              </MenuItem>\ <MenuItem value={"Fleet"}>
                Fleet
              </MenuItem>
            </TextField>
            <FormControl variant="outlined">
              <InputLabel htmlFor="dealer-search" className="pl-2" margin="dense">Search by Customer or SAP ID</InputLabel>
              <OutlinedInput
                id="dealer-search"
                value={searchValue}
                onKeyPress={async (ev) => {
                  if (ev.key === 'Enter') {
                    let data: any = await dispatch(getAgreementDocs(filterByCustomer === 'Workshop' ? 'IWS' : filterByCustomer, searchValue ? searchValue : ''));
                    if (data && data.message && data.message.status === 'S') {
                    } else {
                      setError(data.error ? data.error : data.message)
                    }
                  }
                }}
                onChange={(e: any) => {
                  setSearchValue(e.target.value);
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="Search by Customer or SAP ID"
                      edge="end"
                      onClick={async (e: any) => {
                        let data: any = await dispatch(getAgreementDocs(filterByCustomer === 'Workshop' ? 'IWS' : filterByCustomer, searchValue ? searchValue : ''));
                        if (data && data.message && data.message.status === 'S') {
                        } else {
                          setError(data.error ? data.error : data.message)
                        }
                      }}
                    >
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                }
                labelWidth={280} margin="dense" className="rounded-input-dealer bd-highlight w-280p mr-2 mb-2" />
            </FormControl>
          </div>
        </div>
        <DialogContent className="p-0">
          <div className="p-0">
            <Paper square>
              <Tabs className="pl-2" value={tabValue} onChange={handleTabChange} indicatorColor="primary" textColor="primary" aria-label="Agreement Tab">
                <Tab label="Customer Agreement Renewal" {...allTabProps(0)} />
                <Tab label="Category Certificate" {...allTabProps(1)} />
              </Tabs>
            </Paper>
            <TabPanel value={tabValue} index={0}>
              {<Grid item xs={12} className="mt-2 cardCommon">
                <TableContainer component={Paper} className="pb-5 mb-5">
                  <Table aria-label="dealertable">
                    <TableHead>
                      <TableRow>
                        <TableCell>
                        </TableCell>
                        <TableCell>
                        </TableCell>
                        <TableCell>
                          <div className="dealer-table-title">Customer</div>
                          <div className="dealer-table-title-desc">Name &amp; SAP ID</div>
                        </TableCell>
                        <TableCell>
                          <div className="dealer-table-title">Contact</div>
                          <div className="dealer-table-title-desc">Telephone &amp; Email</div>
                        </TableCell>
                        <TableCell>
                          <div className="dealer-table-title">Assigned</div>
                          <div className="dealer-table-title-desc">KAM &amp; FSE</div>
                        </TableCell>
                        <TableCell>
                          <div className="dealer-table-title mb-3">Last Sent</div>
                        </TableCell>
                        <TableCell>
                          <div className="dealer-table-title mb-3">Last Uploaded Doc</div>
                        </TableCell>
                        <TableCell>
                          <div className="dealer-table-title mb-3">Status</div>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <React.Fragment>
                        {agreementDocs && agreementDocs.data && agreementDocs.data.data && (
                          renderRows(agreementDocs.data.data, handleSelectionChange)
                        )}
                      </React.Fragment>
                    </TableBody>
                  </Table>
                </TableContainer>
                <span className='text-center ml-25 flex-center justify-content-center mt-n-5'>{error}</span>
              </Grid>}
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
              {<Grid item xs={12} className="mt-2 cardCommon">
                <TableContainer component={Paper} className="pb-5 mb-5">
                  <Table aria-label="dealertable">
                    <TableHead>
                      <TableRow>
                        <TableCell>
                        </TableCell>
                        <TableCell>
                        </TableCell>
                        <TableCell>
                          <div className="dealer-table-title">Customer</div>
                          <div className="dealer-table-title-desc">Name &amp; SAP ID</div>
                        </TableCell>
                        <TableCell>
                          <div className="dealer-table-title">Contact</div>
                          <div className="dealer-table-title-desc">Telephone &amp; Email</div>
                        </TableCell>
                        <TableCell>
                          <div className="dealer-table-title">Assigned</div>
                          <div className="dealer-table-title-desc">KAM &amp; FSE</div>
                        </TableCell>
                        <TableCell>
                          <div className="dealer-table-title mb-3">Last Sent</div>
                        </TableCell>
                        <TableCell>
                          <div className="dealer-table-title mb-3">Status</div>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <React.Fragment>
                        {agreementDocs && agreementDocs.data && agreementDocs.data.data && (
                          renderRows(agreementDocs.data.data, handleSelectionChange)
                        )}
                      </React.Fragment>
                    </TableBody>
                  </Table>
                </TableContainer>
                <span className='text-center ml-25 flex-center justify-content-center mt-n-5'>{error}</span>
              </Grid>}
            </TabPanel>
          </div>
        </DialogContent>
        <DialogActions className="my-2 mr-4">
          <Button
            variant="contained"
            color="secondary"
            className="rounded-button-dealer pt-2 pb-2 mr-2"
            onClick={() => {
              sendEmail();
            }}
          >
            Send Email
          </Button>
          <Button variant="contained" className="rounded-button-dealer-cancel pl-4 pr-4" onClick={() => { setTabValue(0); props.onClose(false); }} >Close</Button>
        </DialogActions>
      </Dialog >
    </>
  )
}

export default AgreementDialog
