import { API_ENDPOINTS, ORDER_ENQUIRY, ONBOARD, KAMREVIEW, DEALER } from "../constants/apiConfig";
import { makeRequest } from "../middleware/axiosMiddleware"

export const onboardingService = {
	saveDealerDetails,
	getDealerDetails,
	getDetailsBySapId,
	getRegion,
	getDealingIn,
	getTypeOfTrade,
	getPartnerDetails,
	savePartnerDetails,
	editPartnerDetails,
	deletePartnerDetails,
	getAddNewAuthorisedSignatories,
	saveAuthorisedSignatories,
	updateAuthorisedSignatories,
	deleteAuthorisedSignatories,
	saveDocument,
	updateDocument,
	deleteDocument,
	deletePartnerDocument,
	deleteSignatoryDocument,
	getGenrateOTP,
	getVerifyOTP,
	getAllAddress,
	getDeliveryType,
	getAddressDetails,
	getAddressWithCustomerNo,
	updateAddress,
	deleteAddress,
	getTreadeLicenseRegion,
	getDocumentById,
	addNewAddress,
	changeCustomerType,
	changeAcceptingDeliveryCountry,
	getTradeRegion,
	deleteAddressDocument,
	getAllPartners,
	getAllSignatories,
	requestCorrection,
	saveRequestCorrection,
	// saveZone,
	getTransportationZone,
	historyListRequestCorrection,
	getParameters,
	getParameterValues,
	saveParameterValues,
	updateParameterValues,
	getSapDetails,
	saveSapDetails,
	updateSapDetails,
	getKamDetails,
	saveKamReviewDetails,
	deleteKamReviewsDocument,
	deleteSupportDocument,
	getAccessTabData,
	getAllAcessParams,
	getCustomerTypes,
	saveUpdateAcessTab,
	saveUpdateIBAccessTab,
	sendInvitation,
	generateCustomerId,
	approveDealer,
	checkSapAvailability,
	rejectDealer,
	deactivateDealer,
	getNationality,
	getIndustryType,
	getPaymentMode,
	submitAddress,
	updateDeliveryType,
	getCustomerType,
	updateSelectedCustomerType,
	getDeliverType,
	sendToSAP,
	getIBCountryAndSalesRegion,
	getExpiredDocCount,
	downloadCategoryCertificate
};



async function getKamDetails(b2bUserId: string, type: any) {
	const apiPath = API_ENDPOINTS.KAM_REVIEW_ENDPOINT + ONBOARD.API_PATH + `/customer?b2bUserId=${b2bUserId}&details=${type}`;
	const options = {
		path: apiPath,
		method: "GET",
		customHeaders: {},
		formdata: true
	}
	return await makeRequest(options)

}
async function saveKamReviewDetails(data: any) {
	const apiPath = API_ENDPOINTS.KAM_REVIEW_ENDPOINT + KAMREVIEW.SAVE_KAM_REVIEW_DETAILS;
	const options = {
		path: apiPath,
		method: "POST",
		bodyObj: data,
		customHeaders: {},
		formdata: true
	}
	return await makeRequest(options)

}

async function downloadCategoryCertificate(data: any) {
	const apiPath = API_ENDPOINTS.KAM_REVIEW_ENDPOINT + KAMREVIEW.POST_DOWNLOAD_CERTIFICATE;
	const options = {
		path: apiPath,
		method: "POST",
		bodyObj: data,
		customHeaders: {},
		formdata: true
	}
	return await makeRequest(options)

}

async function saveDealerDetails(data: any) {
	const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + ONBOARD.API_PATH;
	const options = {
		path: `${apiPath}/customer`,
		method: "POST",
		bodyObj: data,
		customHeaders: {},
		formdata: true
	};
	return await makeRequest(options)
}

async function getDealerDetails(b2bUserId: any, type: any) {
	const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + ONBOARD.API_PATH + `/customer?b2bUserId=${b2bUserId}&details=${type}`;
	const options = {
		path: apiPath,
		method: "GET",
		customHeaders: {},
		formdata: true
	};
	return await makeRequest(options)
}

async function getExpiredDocCount(sapCustomerId: any) {
	const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + ONBOARD.API_PATH + `/customer/getInvalidDocumentCount/${sapCustomerId}`;
	const options = {
		path: apiPath,
		method: "GET",
		customHeaders: {},
		formdata: true
	};
	return await makeRequest(options)
}

async function getIBCountryAndSalesRegion() {
	const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + ONBOARD.GET_IB_CUST_COUNTRY_REGION + `/masterData?dataType=countryAndSalesRegion`;
	const options = {
		path: apiPath,
		method: "GET",
		customHeaders: {},
		formdata: true
	};
	return await makeRequest(options)
}

async function getRegion(customerTierType: any) {
	let type = customerTierType && customerTierType.charAt(0) && customerTierType.charAt(0).toUpperCase() + customerTierType.slice(1);
	const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + ORDER_ENQUIRY.FORMFIELD + `/region?customerType=${type}`;
	const options = {
		path: apiPath,
		method: "GET",
		customHeaders: {},
		formdata: true
	};
	return await makeRequest(options)
}

async function getTradeRegion(b2bUserId: any) {
	const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + ONBOARD.API_PATH + `/customer/tradeLicenseRegion?id=${b2bUserId}`;
	const options = {
		path: apiPath,
		method: "GET",
		customHeaders: {},
		formdata: true
	};
	return await makeRequest(options)
}

async function getDealingIn() {
	const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + ORDER_ENQUIRY.FORMFIELD + '/dealingIn';
	const options = {
		path: apiPath,
		method: "GET",
		customHeaders: {},
		formdata: true
	};
	return await makeRequest(options)
}

async function getTypeOfTrade() {
	const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + ORDER_ENQUIRY.FORMFIELD + '/typeOfTrade';
	const options = {
		path: apiPath,
		method: "GET",
		customHeaders: {},
		formdata: true
	};
	return await makeRequest(options)
}

async function getDetailsBySapId(sapId: any) {
	const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + ONBOARD.API_PATH + `/sapCustomerDetails?sapCustomerId=${sapId}&fromOnboardingFlow=${true}`;
	const options = {
		path: apiPath,
		method: "GET",
		customHeaders: {},
		formdata: true
	};
	return await makeRequest(options)
}

async function getPartnerDetails(id: any, partnerId: any) {
	const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + ONBOARD.API_PATH + `/partner?id=${id}&partnerId=${partnerId}`;
	const options = {
		path: apiPath,
		method: "GET",
		customHeaders: {},
		formdata: true
	};
	return await makeRequest(options)
}

async function savePartnerDetails(data: any) {
	const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + ONBOARD.API_PATH + `/partner`;
	const options = {
		path: apiPath,
		bodyObj: data,
		method: "POST",
		customHeaders: {},
		formdata: true
	};
	return await makeRequest(options)
}
async function editPartnerDetails(data: any) {
	const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + ONBOARD.API_PATH + `/partner`;
	const options = {
		path: apiPath,
		method: "PUT",
		bodyObj: data,
		customHeaders: {},
		formdata: true
	};
	return await makeRequest(options)
}
async function deletePartnerDetails(id: any, sapId: any) {
	const data = { id: sapId, partnerId: id }
	const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + ONBOARD.API_PATH + `/partner/delete`;
	const options = {
		path: apiPath,
		method: "POST",
		bodyObj: data,
		customHeaders: {},
		formdata: true
	};
	return await makeRequest(options)
}

//ADD NEW AUTHORISED SIGNATORIES FUNCTION CODE

async function getAddNewAuthorisedSignatories(id: any, b2bUserId: any) {
	const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + ONBOARD.API_PATH + `/authorizedSignatories?id=${b2bUserId}&signatorieId=${id}`;
	const options = {
		path: apiPath,
		method: "GET",
		customHeaders: {},
		formdata: true
	};
	return await makeRequest(options)
}

async function saveAuthorisedSignatories(data: any) {
	const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + ONBOARD.API_PATH + `/authorizedSignatories`;
	const options = {
		path: apiPath,
		bodyObj: data,
		method: "POST",
		customHeaders: {},
		formdata: true
	};
	return await makeRequest(options)
}
async function updateAuthorisedSignatories(data: any) {
	const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + ONBOARD.API_PATH + `/authorizedSignatories`;
	const options = {
		path: apiPath,
		method: "PUT",
		bodyObj: data,
		customHeaders: {},
		formdata: true
	};
	return await makeRequest(options)
}
async function deleteAuthorisedSignatories(data: any) {
	const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + ONBOARD.API_PATH + `/authorizedSignatories/delete`;
	const options = {
		path: apiPath,
		bodyObj: data,
		method: "POST",
		customHeaders: {},
		formdata: true
	};
	return await makeRequest(options)
}

// DOCUMENT TAB FUNCTION CODE
async function saveDocument(data: any) {
	const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + ONBOARD.API_PATH + '/customer';
	const options = {
		path: apiPath,
		method: "POST",
		bodyObj: data,
		customHeaders: {},
		formdata: true
	};
	return await makeRequest(options)
}

async function submitAddress(data: any) {
	const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + ONBOARD.API_PATH + '/customer/submitOnboardingRequest';
	const options = {
		path: apiPath,
		method: "POST",
		bodyObj: data,
		customHeaders: {},
		formdata: true
	};
	return await makeRequest(options)
}

async function updateDeliveryType(data: any) {
	const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + ONBOARD.API_PATH + '/deliveryType';
	const options = {
		path: apiPath,
		method: "POST",
		bodyObj: data,
		customHeaders: {},
		formdata: true
	};
	return await makeRequest(options)
}

async function updateDocument(formData: any, headers: any) {
	const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + DEALER.DOCUMENTS;
	const options = {
		path: apiPath,
		bodyObj: formData,
		method: "POST",
		customHeaders: headers,
		formdata: true
	};
	return await makeRequest(options)
}
async function deleteDocument(b2bUserId: any, id: any, type: any) {
	const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + ONBOARD.API_PATH + `/customer/document/delete?b2bUserId=${b2bUserId}&docId=${id}`;
	const options = {
		path: apiPath,
		bodyObj: type,
		method: "POST",
		customHeaders: {},
		formdata: true
	};
	return await makeRequest(options)
}

async function deletePartnerDocument(partnerId: any, documentId: any, data: any, b2bUserId: any) {
	let apiPath = API_ENDPOINTS.DEALER_ENDPOINT + ONBOARD.API_PATH + `/partner/document/delete?docId=${documentId}&id=${b2bUserId}`
	if (partnerId) {
		apiPath += `&partnerId=${partnerId}`
	}
	const options = {
		path: apiPath,
		method: "POST",
		bodyObj: data,
		customHeaders: {},
		formdata: true
	};
	return await makeRequest(options)
}

async function deleteSignatoryDocument(signatoryId: any, documentId: any, data: any, b2bUserId: any) {
	let apiPath = API_ENDPOINTS.DEALER_ENDPOINT + ONBOARD.API_PATH + `/authorizedSignatories/document/delete?docId=${documentId}&id=${b2bUserId}`;
	if (signatoryId) {
		apiPath += `&signatoriId=${signatoryId}`
	}
	const options = {
		path: apiPath,
		method: "POST",
		bodyObj: data,
		customHeaders: {},
		formdata: true
	};
	return await makeRequest(options)
}

// FUNCTION CODE FOR GENRATE OTP
async function getGenrateOTP(sapId: any, b2bUserId: any) {
	const data = { sapCustomerId: sapId, b2bUserId: b2bUserId }
	const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + ONBOARD.API_PATH + `/generatOTP`;
	const options = {
		path: apiPath,
		method: "POST",
		bodyObj: data,
		customHeaders: {},
		formdata: true
	};
	return await makeRequest(options)
}

async function getCustomerType() {
	const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + '/formField/customerTypeList/onboarding';
	const options = {
		path: apiPath,
		method: "GET",
		customHeaders: {},
		formdata: true
	};
	return await makeRequest(options)
}

async function updateSelectedCustomerType(data: any) {
	const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + ONBOARD.API_PATH + `/customerTypeUpdate`;
	const options = {
		path: apiPath,
		method: "PUT",
		bodyObj: data,
		customHeaders: {},
		formdata: true
	};
	return await makeRequest(options)
}

// FUNCTION CODE FOR VERIFY OTP
async function getVerifyOTP(sapVerifyId: any, sapVerifyIdOTP: any, b2bUserId: any) {
	const data = { sapCustomerId: sapVerifyId, otp: sapVerifyIdOTP, b2bUserId: b2bUserId }
	const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + ONBOARD.API_PATH + `/verifyOtp`;
	const options = {
		path: apiPath,
		method: "POST",
		bodyObj: data,
		customHeaders: {},
		formdata: true
	};
	return await makeRequest(options)
}


async function getAllAddress(b2bUserId: any) {

	const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + ONBOARD.API_PATH + `/customer/${b2bUserId}/address`;
	const options = {
		path: apiPath,
		method: "GET",
		customHeaders: {},
		formdata: true
	};
	return await makeRequest(options)
}

async function getDeliveryType() {

	const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + `/formField/deliveryType`;
	const options = {
		path: apiPath,
		method: "GET",
		customHeaders: {},
		formdata: true
	};
	return await makeRequest(options)
}

async function addNewAddress(b2bUserId: any, data: any) {

	const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + ONBOARD.API_PATH + `/customer/${b2bUserId}/address`;
	const options = {
		path: apiPath,
		method: "POST",
		bodyObj: data,
		customHeaders: {},
		formdata: true
	};
	return await makeRequest(options)
}

async function getAddressDetails(b2bUserId: any, addressId: any) {

	const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + ONBOARD.API_PATH + `/customer/${b2bUserId}/address/${addressId}`;
	const options = {
		path: apiPath,
		method: "GET",
		customHeaders: {},
		formdata: true
	};
	return await makeRequest(options)
}

async function getAddressWithCustomerNo(b2bUserId: any, shipToPartyId: any) {
	const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + ONBOARD.API_PATH + `/address/shipToPartyNumber?id=${b2bUserId}&shipToPartyNumber=${shipToPartyId}`;
	const options = {
		path: apiPath,
		method: "GET",
		customHeaders: {},
		formdata: true
	};
	return await makeRequest(options)
}

async function updateAddress(b2bUserId: any, data: any, addressId: any) {

	const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + ONBOARD.API_PATH + `/customer/${b2bUserId}/address/${addressId}`;
	const options = {
		path: apiPath,
		method: "PUT",
		bodyObj: data,
		customHeaders: {},
		formdata: true
	};
	return await makeRequest(options)
}

async function deleteAddress(b2bUserId: any, addressId: any, userType: any) {
	const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + ONBOARD.API_PATH + `/customer/${b2bUserId}/address/${addressId}/delete`;
	const options = {
		path: apiPath,
		method: "POST",
		bodyObj: userType,
		customHeaders: {},
		formdata: true
	};
	return await makeRequest(options)
}


async function deleteAddressDocument(addressId: any, documentId: any, type: any, b2bUserId: any) {
	let apiPath = API_ENDPOINTS.DEALER_ENDPOINT + ONBOARD.API_PATH + `/customer/address/delete?docId=${documentId}&id=${b2bUserId}`;
	if (addressId) {
		apiPath += `&addressId=${addressId}`
	}
	const options = {
		path: apiPath,
		method: "POST",
		bodyObj: type,
		customHeaders: {},
		formdata: true
	};
	return await makeRequest(options)
}

async function deleteKamReviewsDocument(b2bUserId: any, documentId: any, type: any) {
	let apiPath = API_ENDPOINTS.DEALER_ENDPOINT + ONBOARD.API_PATH + `/customer/document/delete?b2bUserId=${b2bUserId}`;
	if (documentId) {
		apiPath += `&docId=${documentId}`
	}
	const options = {
		path: apiPath,
		method: "POST",
		bodyObj: type,
		customHeaders: {},
		formdata: true
	};
	return await makeRequest(options)
}

async function deleteSupportDocument(sapCustomerId: any, documentId: any, type: any) {
	let apiPath = API_ENDPOINTS.DEALER_ENDPOINT + ONBOARD.API_PATH + `/customer/document/delete?sapCustomerId=${sapCustomerId}`;
	if (documentId) {
		apiPath += `&docId=${documentId}`
	}
	const options = {
		path: apiPath,
		method: "POST",
		bodyObj: type,
		customHeaders: {},
		formdata: true
	};
	return await makeRequest(options)
}

async function getTreadeLicenseRegion() {

	const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + ONBOARD.API_PATH + `/customer/b2b0000000682/region`;
	const options = {
		path: apiPath,
		method: "GET",
		customHeaders: {},
		formdata: true
	};
	return await makeRequest(options)
}

async function getDocumentById() {

	const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + ONBOARD.API_PATH + `/customer/b2b0000000682/region`;
	const options = {
		path: apiPath,
		method: "GET",
		customHeaders: {},
		formdata: true
	};
	return await makeRequest(options)
}

async function changeCustomerType(data: any) {

	const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + ONBOARD.API_PATH + `/changeDealerType`;
	const options = {
		path: apiPath,
		method: "POST",
		bodyObj: data,
		customHeaders: {},
		formdata: true
	};
	return await makeRequest(options)
}

async function changeAcceptingDeliveryCountry(data: any) {
	const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + ONBOARD.API_PATH + `/customer/modify`;
	const options = {
		path: apiPath,
		method: "POST",
		bodyObj: data,
		customHeaders: {},
		formdata: true
	};
	return await makeRequest(options)
}
// FOR GET ALL PARTNERS DETAILS
async function getAllPartners(b2bUserId: any) {
	const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + ONBOARD.API_PATH + `/customer/allPartner?b2bUserId=${b2bUserId}`;
	const options = {
		path: apiPath,
		method: "GET",
		customHeaders: {},
		formdata: true
	};
	return await makeRequest(options)
}

// FOR GET ALL SIGANTORIES DETAILS
async function getAllSignatories(b2bUserId: any) {
	const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + ONBOARD.API_PATH + `/customer/allSignatories?b2bUserId=${b2bUserId}`;
	const options = {
		path: apiPath,
		method: "GET",
		customHeaders: {},
		formdata: true
	};
	return await makeRequest(options)
}

// FOR GET REQUEST CORRECTION DETAILS
async function requestCorrection(data: any) {
	const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + ONBOARD.API_PATH + `/dataCorrection`;
	const options = {
		path: apiPath,
		method: "POST",
		bodyObj: data,
		customHeaders: {},
		formdata: true
	};
	return await makeRequest(options)
}

// FOR SAVE REQUEST CORRECTION DETAILS
async function saveRequestCorrection(b2bUserId: any) {
	const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + ONBOARD.API_PATH + ``;
	const options = {
		path: apiPath,
		method: "GET",
		customHeaders: {},
		formdata: true
	};
	return await makeRequest(options)
}

// FOR HISTORY LIST CORRECTION DETAILS
async function historyListRequestCorrection(b2bUserId?: any) {
	const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + ONBOARD.API_PATH + `/dataCorrection?b2bUserId=${b2bUserId}`;
	const options = {
		path: apiPath,
		method: "GET",
		customHeaders: {},
		formdata: true
	};
	return await makeRequest(options)
}

// FOR GET TRANSPORTATION ZONE
async function getTransportationZone(b2bUserId: any) {
	const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + ORDER_ENQUIRY.FORMFIELD + '/transportationZone';;
	const options = {
		path: apiPath,
		method: "GET",
		customHeaders: {},
		formdata: true
	};
	return await makeRequest(options)
}

// FOR SAVE ZONE
// async function saveZone(b2bUserId: any, data: any) {
// 	const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + ONBOARD.API_PATH + `/customer/${b2bUserId}/transportationZone`;
// 	const options = {
// 		path: apiPath,
// 		method: "PUT",
// 		bodyObj: data,
// 		customHeaders: {},
// 		formdata: true
// 	};
// 	return await makeRequest(options)
// }

// FOR SEND DATA TO SAP
async function sendToSAP(data: any) {
	const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + ONBOARD.API_PATH + `/customer/address/sendToSAP`;
	const options = {
		path: apiPath,
		method: "POST",
		bodyObj: data,
		customHeaders: {},
		formdata: true
	};
	return await makeRequest(options)
}



// FOR GET PARAMETERS DETAILS
async function getParameters(b2bUserId: any) {
	const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + ONBOARD.API_PATH + ``;
	const options = {
		path: apiPath,
		method: "GET",
		customHeaders: {},
		formdata: true
	};
	return await makeRequest(options)
}

// FOR GET PARAMETER VALUES
async function getParameterValues(b2bUserId: any) {
	const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + ONBOARD.API_PATH + ``;
	const options = {
		path: apiPath,
		method: "GET",
		customHeaders: {},
		formdata: true
	};
	return await makeRequest(options)
}

// FOR SAVE PARAMETER VALUES
async function saveParameterValues(b2bUserId: any) {
	const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + ONBOARD.API_PATH + ``;
	const options = {
		path: apiPath,
		method: "GET",
		customHeaders: {},
		formdata: true
	};
	return await makeRequest(options)
}

// FOR UPDATE PARAMETER VALUES
async function updateParameterValues(b2bUserId: any) {
	const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + ONBOARD.API_PATH + ``;
	const options = {
		path: apiPath,
		method: "GET",
		customHeaders: {},
		formdata: true
	};
	return await makeRequest(options)
}

// FOR GET SAP DETAILS
async function getSapDetails(b2bUserId: any) {
	const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + ONBOARD.API_PATH + ``;
	const options = {
		path: apiPath,
		method: "GET",
		customHeaders: {},
		formdata: true
	};
	return await makeRequest(options)
}

// FOR SAVE SAP DETAILS
async function saveSapDetails(b2bUserId: any) {
	const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + ONBOARD.API_PATH + ``;
	const options = {
		path: apiPath,
		method: "GET",
		customHeaders: {},
		formdata: true
	};
	return await makeRequest(options)
}

// FOR UPDATE SAP DETAILS
async function updateSapDetails(b2bUserId: any) {
	const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + ONBOARD.API_PATH + ``;
	const options = {
		path: apiPath,
		method: "GET",
		customHeaders: {},
		formdata: true
	};
	return await makeRequest(options)
}

// get Kam details


// FOR UPDATE SAP DETAILS
async function getAccessTabData(b2bUserId: any) {
	const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + ONBOARD.API_PATH + `/accessData/${b2bUserId}`;
	const options = {
		path: apiPath,
		method: "GET",
		customHeaders: {},
		formdata: true
	};
	return await makeRequest(options)
}

// FOR UPDATE SAP DETAILS
async function getAllAcessParams() {
	const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + `/formField/masterListOfAccess`;
	const options = {
		path: apiPath,
		method: "GET",
		customHeaders: {},
		formdata: true
	};
	return await makeRequest(options)
}


// FOR UPDATE SAP DETAILS
async function saveUpdateAcessTab(data: any) {
	const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + ONBOARD.API_PATH + `/saveApproveDealer`;
	const options = {
		path: apiPath,
		bodyObj: data,
		method: "POST",
		customHeaders: {},
		formdata: true
	};
	return await makeRequest(options)
}

async function saveUpdateIBAccessTab(data: any) {
	const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + ONBOARD.API_PATH + `/approveCustomerWithoutAdmin`;
	const options = {
		path: apiPath,
		bodyObj: data,
		method: "POST",
		customHeaders: {},
		formdata: true
	};
	return await makeRequest(options)
}

async function sendInvitation(data: any) {
	const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + ONBOARD.API_PATH + `/sendInvitation`;
	const options = {
		path: apiPath,
		bodyObj: data,
		method: "POST",
		customHeaders: {},
		formdata: true
	};
	return await makeRequest(options)
}

async function generateCustomerId(data: any) {
	const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + ONBOARD.API_PATH + `/onboardDealerInSap`;
	const options = {
		path: apiPath,
		bodyObj: data,
		method: "POST",
		customHeaders: {},
		formdata: true
	};
	return await makeRequest(options)
}

// FOR UPDATE SAP DETAILS
async function checkSapAvailability(data: any) {
	const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + ONBOARD.API_PATH + `/checkUserAvalibility`;
	const options = {
		path: apiPath,
		bodyObj: data,
		method: "POST",
		customHeaders: {},
		formdata: true
	};
	return await makeRequest(options)
}

// FOR UPDATE SAP DETAILS
async function getCustomerTypes() {
	const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + `/formField/customerType`;
	const options = {
		path: apiPath,
		method: "GET",
		customHeaders: {},
		formdata: true
	};
	return await makeRequest(options)
}

// FOR UPDATE SAP DETAILS
async function approveDealer() {
	const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + `/formField/customerType`;
	const options = {
		path: apiPath,
		method: "GET",
		customHeaders: {},
		formdata: true
	};
	return await makeRequest(options)
}

// FOR UPDATE SAP DETAILS
async function rejectDealer(data: any) {
	const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + `/onboarding/rejectCustomer`;
	const options = {
		path: apiPath,
		bodyObj: data,
		method: "POST",
		customHeaders: {},
		formdata: true
	};
	return await makeRequest(options)
}


// FOR UPDATE SAP DETAILS
async function deactivateDealer(data: any, sapCustomerId: any) {
	const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + `/customer/${sapCustomerId}/activeDeactive`;
	const options = {
		path: apiPath,
		bodyObj: data,
		method: "POST",
		customHeaders: {},
		formdata: true
	};
	return await makeRequest(options)
}

// FOR GET NATIONALITY DETAILS FOR OWNER/PARTNER & ADD AUTHORIZED SIGANTORIES DETAILS
async function getNationality() {
	const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + `/formField/countries`;
	const options = {
		path: apiPath,
		method: "GET",
		customHeaders: {},
		formdata: true
	};
	return await makeRequest(options)
}

// FOR GET INDUSTRY TYPE DETAILS ONCLICK OF APPROVE BUTTON ON ACCESS TAB KAM PORTAL
async function getIndustryType(customerType: any) {
	const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + `/formField/industryType?customerType=${customerType}`;
	const options = {
		path: apiPath,
		method: "GET",
		customHeaders: {},
		formdata: true
	};
	return await makeRequest(options)
}

//FOR GETTING PAYMENT MODE
async function getPaymentMode() {
	const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + `/formField/paymentMode`;
	const options = {
		path: apiPath,
		method: "GET",
		customHeaders: {},
		formdata: true
	};
	return await makeRequest(options)
}

async function getDeliverType() {
	const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + '/formField/deliveryType';
	const options = {
		path: apiPath,
		method: "GET",
		bodyObj: {},
		customHeaders: {},
		formdata: true
	}
	return await makeRequest(options)
}