import { SUPER_ADMIN } from "./actionsTypes";
import { apiStart, apiError, apiSuccess } from "./apiActions";
import { superAdminServices } from "../../services/superAdmin.service";

export const superAdminActions = {
    getAllDefaultTargetData,
    updateAllDefaulTargetData,
    editSuperDealer,
    getCustomerDealerDetails,
    getDealerListSetTargetData,
    updateSetTargetCustomerData,
    getRefreshButton
};

export function getAllDefaultTargetData(loginId: any, year: any) {
    return async (dispatch: any) => {
        dispatch(apiStart(SUPER_ADMIN.GET_DEFAULT_TARGET_DATA_FETCHING));
        try {
            const response = await superAdminServices.getDefaultTargetData(loginId, year);
            dispatch(apiSuccess(SUPER_ADMIN.GET_DEFAULT_TARGET_DATA_SUCCESS, response));
            return response || [];
        } catch (error) {
            dispatch(apiError(SUPER_ADMIN.GET_DEFAULT_TARGET_DATA_ERROR, error));
            return error;
        }
    }; 
}

export function updateAllDefaulTargetData(loginId: any, year: any) {
    return async (dispatch: any) => {
        dispatch(apiStart(SUPER_ADMIN.UPDATE_DEFAULT_TARGET_FETCHING));
        try {
            const response = await superAdminServices.updateDefaultTarget(loginId, year);
            dispatch(apiSuccess(SUPER_ADMIN.UPDATE_DEFAULT_TARGET_SUCCESS, response));
            return response || [];
        } catch (error) {
            dispatch(apiError(SUPER_ADMIN.UPDATE_DEFAULT_TARGET_ERROR, error));
            return error;
        }
    };
}

export function getCustomerDealerDetails(id: any) {
    return async (dispatch: any) => {
        dispatch(apiStart(SUPER_ADMIN.GET_SUPER_ADMIN_B2B_FETCHING))
        try {
            const response = await superAdminServices.getCustomerDetails(id);
            dispatch(apiSuccess(SUPER_ADMIN.GET_SUPER_ADMIN_B2B_SUCCESS, response));
            return response || []
        } catch (error) {
            dispatch(apiError(SUPER_ADMIN.GET_SUPER_ADMI_B2B_ERROR, error));
            return error;
        }
    }
}

export function getTierList() {
    return async (dispatch: any) => {
        dispatch(apiStart(SUPER_ADMIN.GET_TIER_FETCHING))
        try {
            const response = await superAdminServices.getTier();
            dispatch(apiSuccess(SUPER_ADMIN.GET_TIER_SUCCESS, response));
            return response || []
        } catch (error) {
            dispatch(apiError(SUPER_ADMIN.GET_TIER_ERROR, error));
            return error;
        }
    }
}

export function editSuperDealer(obj: any, id: any) {
    return async (dispatch: any) => {
        dispatch(apiStart(SUPER_ADMIN.UPDATE_SUPER_ADMIN_EDIT_DEALER_FETCHING));
        try {
            const response = await superAdminServices.editSuperDealer(obj, id);
            dispatch(apiSuccess(SUPER_ADMIN.UPDATE_SUPER_ADMIN_EDIT_DEALER_SUCCESS, response))
            return response || []
        } catch (error) {
            dispatch(apiError(SUPER_ADMIN.UPDATE_SUPER_ADMIN_EDIT_DEALER_ERROR, error))
            return error;
        }
    }
}

export function getRefreshButton(obj: any, id: any) {
    return async (dispatch: any) => {
        dispatch(apiStart(SUPER_ADMIN.GET_ONCLICK_REFRESH_SAP_RESPONSE_FETCHING));
        try {
            const response = await superAdminServices.getRefreshButton(obj, id);
            dispatch(apiSuccess(SUPER_ADMIN.GET_ONCLICK_REFRESH_SAP_RESPONSE_SUCCESS, response))
            return response || []
        } catch (error) {
            dispatch(apiError(SUPER_ADMIN.GET_ONCLICK_REFRESH_SAP_RESPONSE_ERROR, error))
            return error;
        }
    }
}

export function getDealerListSetTargetData(sapCustomerId?: any, year?: any, loginId?: string) {
    return async (dispatch: any) => {
        dispatch(apiStart(SUPER_ADMIN.GET_DEALER_LIST_TARGET_DATA_FETCHING));
        try {
            const response = await superAdminServices.getDealerListSetTarget(sapCustomerId, year, loginId);
            dispatch(apiSuccess(SUPER_ADMIN.GET_DEALER_LIST_TARGET_DATA_SUCCESS, response));
            return response || [];
        } catch (error) {
            dispatch(apiError(SUPER_ADMIN.GET_DEALER_LIST_TARGET_DATA_ERROR, error));
            return error;
        }
    };
}

export function updateSetTargetCustomerData(data?: any, sapCustomerId?: any) {
    return async (dispatch: any) => {
        dispatch(apiStart(SUPER_ADMIN.UPDATE_CUSTOMER_TARGET_DATA_FETCHING));
        try {
            const response = await superAdminServices.updateTargetDataCustomer(data, sapCustomerId);
            dispatch(apiSuccess(SUPER_ADMIN.UPDATE_CUSTOMER_TARGET_DATA_SUCCESS, response));
            return response || [];
        } catch (error) {
            dispatch(apiError(SUPER_ADMIN.UPDATE_CUSTOMER_TARGET_DATA_ERROR, error));
            return error;
        }
    };
}
