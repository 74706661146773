/* eslint-disable */
import React, { Dispatch } from 'react';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Menu, { MenuProps } from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Badge from '@material-ui/core/Badge';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import NotificationsOutlinedIcon from '@material-ui/icons/NotificationsOutlined';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import { useDispatch, useSelector } from 'react-redux';
import { getAllNotifications, getAllOnbardingNotifications, markNotificationRead } from '../../store/actions/notifications.action';
import moment from "moment-timezone";
import { get } from 'lodash';
import { useHistory } from 'react-router-dom';

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    '&:focus': {
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
  gutters: {
    paddingLeft: "2px",
    paddingRight: "4px"
  }
}))(MenuItem);

const NotificationIcon = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { notificationsList }: any = useSelector((state: any) => state.notifications);
  const dispatch: Dispatch<any> = useDispatch();
  const { profileDetails }: any = useSelector((state: any) => state.profileDetails);
  const userType = sessionStorage.getItem('userType');

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    getNotifications();
    setAnchorEl(event.currentTarget);
  };

  const history = useHistory();
  const [markAllReadcount, setMarkAllReadcount] = React.useState<boolean>(false);

  function getNotifications() {
    if (userType === 'dealerUser') {
      if (profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.basicInfo && profileDetails.data.data.basicInfo.userType === 'Admin') {
        dispatch(getAllNotifications(sessionStorage.getItem('customerId'), "dealer"));
      } else if (profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.basicInfo && profileDetails.data.data.basicInfo.userType === 'User') {
        dispatch(getAllNotifications(sessionStorage.getItem('webApplicationLoginId'), "user"));
      } else if (profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.basicInfo && profileDetails.data.data.basicInfo.userType === 'guestAdmin') {
        dispatch(getAllOnbardingNotifications(profileDetails.data.data.basicInfo.b2bUserId, "guestUser"));
      }
    } else if (userType === 'kam') {
      dispatch(getAllNotifications(sessionStorage.getItem('webApplicationLoginId'), "kam"));
    } else if (userType && userType.toLowerCase() === 'fse') {
      dispatch(getAllNotifications(sessionStorage.getItem('webApplicationLoginId'), "fse"));
    } else if (userType === 'creditTeam') {
      dispatch(getAllNotifications(sessionStorage.getItem('webApplicationLoginId'), "creditTeam"));
    } else if (userType === "pricing_team") {
      dispatch(getAllNotifications(sessionStorage.getItem('webApplicationLoginId'), "pricing_team"));
    }
  }



  // useEffect(() => {
  //   getNotifications();
  // }, [markAsRead, userType]); // eslint-disable-line

  const handleClose = () => {
    setAnchorEl(null);
  };

  const menuStyle = {
    fontFamily: "Nunito !important",
  };
  const menuHeaderStyle = {
    fontSize: "0.7rem",
    fontWeight: 600,
    color: "#666666",
    fontFamily: "Nunito !important"
  };
  const menuItemStyle = {
    width: "350px"
  };
  const menuItemDescStyle = {
    fontSize: "0.75rem",
    fontWeight: 500,
    color: "#666666",
    fontFamily: "Nunito !important"
  };
  const menuItemDateStyle = {
    fontSize: "0.65rem",
    fontWeight: 600,
    color: "#999999",
    fontFamily: "Nunito !important"
  };
  const menuItemIconCenter = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  };
  const menuFooterStyle = {
    fontSize: "0.7rem",
    fontWeight: 700,
    color: "#1A9FE0",
    fontFamily: "Nunito !important",
    cursor: "pointer"
  };

  const sapCustomerId = get(profileDetails, 'data.data.basicInfo.sapCustomerId', "");

  const markAllNotications = (notification: any, markAllRead: any) => {
    if (markAllRead !== null) {
      const data = {
        userType: userType === 'kam' ? 'kam' : userType && userType.toLowerCase() === 'fse' ? 'fse' : userType === 'creditTeam' ? 'creditTeam' : userType === 'pricing_team' ? 'pricing_team' : "dealer",
        makeAllRead: markAllRead ? true : false,
        id: userType === 'kam' || userType === 'creditTeam' || userType === 'pricing_team' || (userType && userType.toLowerCase() === 'fse') ? sessionStorage.getItem('webApplicationLoginId') : sapCustomerId,
        notificationId: notification && notification.id ? notification.id : ''
      }
      dispatch(markNotificationRead(data));
    }
    setTimeout(() => {
      getNotifications();
    }, 4000)
  }

  const regex: any = /<br\s*[\/]?>/gi;

  return (
    <>
      {markAllReadcount === true ?
        <IconButton
          aria-controls="customized-menu"
          aria-haspopup="true"
          onClick={handleClick}>
          <NotificationsOutlinedIcon />
        </IconButton>
        :
        <IconButton
          aria-controls="customized-menu"
          aria-haspopup="true"
          onClick={handleClick}>
          <Badge badgeContent={notificationsList && notificationsList.data && notificationsList.data.unreadCount} color="error">
            <NotificationsOutlinedIcon />
          </Badge>
        </IconButton>
      }
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        className="notification-menu"
        open={Boolean(anchorEl)}
        onClose={handleClose}
        onClick={handleClose}
        style={menuStyle}
      >
        <Typography className="ml-2 mb-2" style={menuHeaderStyle}>Notifications</Typography>
        <Divider />
        <div className="notification-menu-item">
          {notificationsList && notificationsList.data && notificationsList.data.notifications && notificationsList.data.notifications.length > 0 && notificationsList.data.notifications.map((notification: any) => (
            <div key={notification.id} className={notification.isRead ? '' : 'unread'}>
              <StyledMenuItem className={notification.isRead ? '' : 'light-blue-bg'} onClick={() => { notification.isRead ? markAllNotications(null, null) : markAllNotications(notification, false) }}>
                <Grid container style={menuItemStyle} >
                  <Grid item xs={2} style={menuItemIconCenter}>
                    <PersonAddIcon />
                    <Divider orientation="vertical" className="ml-2" flexItem />
                  </Grid>
                  <Grid item xs={10}>
                    <div style={menuItemDescStyle} className="text-preline pb-1" onClick={(e: any) => {
                      if (profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.basicInfo && profileDetails.data.data.basicInfo.userType !== "FSE" && profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.basicInfo && profileDetails.data.data.basicInfo.userType !== "KAM" &&  profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.basicInfo && profileDetails.data.data.basicInfo.userType === "Admin") {
                        if (notification && notification.notificationType === "document_expired" || notification && notification.notificationType === "document_review") {
                          history.push('/orgsetting')
                        } else {
 
                        }
                      }
                      // if (notification && notification.notificationType === "document_expired") {
                      //   if (userType === 'kam' || (userType && userType.toLowerCase() === 'fse') || userType === 'creditTeam' || userType === "pricing_team") {
                      //   } else {
                      //     history.push('/orgsetting')
                      //   }
                      // } else {
                      // }
                    }}>{notification && notification.messageText ? notification.messageText.replace(regex, "\n") : '-'}</div>
                    <Typography style={menuItemDateStyle}>{moment(notification.updatedOn).tz('Asia/Dubai').format('YYYY-MM-DD HH:mm:ss')}</Typography>
                  </Grid>
                </Grid>
              </StyledMenuItem>
              <Divider />
            </div>
          ))}
          {notificationsList && notificationsList.data && notificationsList.data.notifications && notificationsList.data.notifications.length === 0 && (
            <StyledMenuItem>
              <div className="text-center mx-auto mt-4">
                No Notifications Found
              </div>
            </StyledMenuItem>
          )}
        </div>
        {notificationsList && notificationsList.data && notificationsList.data.unreadCount !== 0 && (
          <>
            <Divider />
            <div style={menuFooterStyle} className="pl-3 pt-2" onClick={() => {
              setMarkAllReadcount(true);
              markAllNotications(null, true);
            }}><Link>Mark all notifications read</Link></div>
          </>
        )}
      </StyledMenu>
    </>
  )
}

export default NotificationIcon
