/* eslint-disable  */
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import React, { Dispatch, useState, useEffect } from "react";
import { FormControlLabel, Radio, RadioGroup, } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import TextField from "@material-ui/core/TextField";
import Menu, { MenuProps } from '@material-ui/core/Menu';
import { withStyles } from "@material-ui/core/styles";
import FullscreenLoader from '../../common/components/FullscreenLoader';
import Toolbar from '@material-ui/core/Toolbar';
import { OnboardingAddOwnerPartnerValidation } from '../../constants/messages';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import clsx from 'clsx';
import CancelIcon from '@material-ui/icons/Cancel';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { deleteSupportDocument, resetValues, updateDocument } from '../../store/actions/onboard.actions';
import Snackbar from '../../common/components/CreatedEnquirySnackbar';
import DeleteDialog from '../../common/components/deleteDialog';
import { supportPostAPI } from "../../store/actions/profile.action";
import DialogContentText from '@material-ui/core/DialogContentText';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';


const useStyles = makeStyles(() => (
    {
        root: {
            width: '100%',
        },
        title: {
            flex: '1 1 100%',
        },
        closeButton: {
            backgroundColor: '#EAEAEA',
            width: 120,
            fontWeight: 800,
            textTransform: 'capitalize',
            fontFamily: 'Nunito',
            fontSize: '0.688rem',
            color: '#666666',
            borderRadius: '2.5rem',
            height: 35,
            textAlign: 'center',
            marginRight: 24
        },
        icon: {
            float: "left",
            margin: "0 20px 20px 0",
            height: "100%",
            fill: "white",
        },
        cancel: {
            fontSize: '0.813rem',
            fontFamily: 'Nunito',
            fontWeight: 700,
            color: '#919191',
            textTransform: 'capitalize'
        },
        rejectText: {
            color: '#616161',
            fontSize: '5rem',
            fontFamily: 'Nunito',
            fontWeight: 900,
            textTransform: 'inherit'
        },
        addUpdateDailogTitle: {
            position: "absolute",
            top: 10,
            left: 10
        }
    }));

const StyledMenu = withStyles({
    paper: {
        border: '1px solid #d3d4d5',
    },
})((props: MenuProps) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
));

const ReportOrderDialog = (props: any) => {
    const classes = useStyles();
    const history = useHistory();
    const dispatch: Dispatch<any> = useDispatch();
    const { getCustomerDetailsBySapId, getFSECustomerListData }: any = useSelector((state: any) => state.dealer);
    const { profileDetails, isSellingPriceData }: any = useSelector((state: any) => state.profileDetails);
    const [loading, setLoading] = React.useState<any>(false);
    const [name, setName] = React.useState<string>("");
    const [nameError, setNameError] = React.useState<boolean>(false);
    const [inavlidNameError, setInvalidNameError] = React.useState<boolean>(false);
    const [mobile, setMobile] = React.useState<string>("");
    const [adminMobileError, setAdminMobileError] = React.useState<boolean>(false);
    const [invalidMobile, setInvalidMobile] = React.useState<boolean>(false);
    const [email, setEmail] = React.useState<string>("");
    const [invalidEmail, setInvalidEmail] = React.useState<boolean>(false);
    const [adminEmailError, setAdminEmailError] = React.useState<boolean>(false);
    const [subject, setSubject] = React.useState<string>("");
    const [subjectErrorMessage, setSubjectErrorMessage] = React.useState<any>("");
    const [subjectError, setSubjectError] = React.useState<boolean>(false);
    const [invalidSubjectError, setInvalidSubjectError] = React.useState<boolean>(false);
    const [browseFile, setBrowseFile] = React.useState<any>([]);
    const [deleteMessage, setDeleteMessage] = React.useState<any>();
    const [submitted, setSubmitted] = React.useState<any>(false);
    const [descriptionText, setDescriptionText] = React.useState<any>("");
    const [descriptionTextErrorMessage, setDescriptionTextErrorMessage] = React.useState<any>("");
    const [descriptionTextError, setDescriptionTextError] = React.useState<boolean>(false);
    const [openSnackbar, setOpenSnackbar] = React.useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
    const [successMessage, setSuccessMessage] = React.useState<any>(null);
    const [errorMessage, showErrorMessage] = React.useState<any>(null);
    const [openErrorSnackbar, setOpenErrorSnackbar] = React.useState(false);
    const [currentIndex, setCurrentIndex] = React.useState<any>();
    const [selectedRadioButton, setSelectedRadioButton] = React.useState<any>("");
    const [radioButton, setRadioButton] = React.useState<any>("");
    const [radioButtonError, setRadioButtonError] = React.useState<boolean>(false);
    const customerTierType = profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.customerInfo && profileDetails.data.data.customerInfo.customerTierType
    const b2bUserId = profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.basicInfo && profileDetails.data.data.basicInfo.b2bUserId
    const firstName = profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.basicInfo && profileDetails.data.data.basicInfo.firstName
    const lastName = profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.basicInfo && profileDetails.data.data.basicInfo.lastName
    const userEmail = profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.basicInfo && profileDetails.data.data.basicInfo.email
    const sapCustomerId = profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.basicInfo && profileDetails.data.data.basicInfo.sapCustomerId
    const mobileNumber = profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.basicInfo && profileDetails.data.data.basicInfo.firstTelephoneNumber
    const userName = firstName + ' ' + lastName;
    const companyName = profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.customerInfo && profileDetails.data.data.customerInfo.companyName

    const resetData = () => {
        setSelectedRadioButton("");
        setRadioButton("");
        setRadioButtonError(false);
        setDescriptionTextError(false);
        setDescriptionTextErrorMessage("");
        setDescriptionText("");
        setDeleteMessage("");
        setBrowseFile([]);
        setSubjectError(false);
        setSubjectErrorMessage("");
        setInvalidSubjectError(false);
        setSubject("");
        setAdminEmailError(false);
        setInvalidEmail(false);
        setInvalidMobile(false);
        setAdminMobileError(false);
        setInvalidNameError(false);
        setNameError(false);
        setName(userName);
        setMobile(mobileNumber);
        setEmail(userEmail);
    };

    useEffect(() => {
        setName(userName ? userName : name);
        setMobile(mobileNumber ? mobileNumber : mobile);
        setEmail(userEmail ? userEmail : email);
    }, [profileDetails]);

    const handleopenClose = (open?: any) => {
        setOpenDeleteDialog(open ? true : false);
    };

    const onRadioButtonChange = (event: any) => {
        setSelectedRadioButton(event.target.value);
        setRadioButton(event.target.value);
    };


    const handleopenCloseErrorSnackbar = (open?: any) => {
        setOpenErrorSnackbar(open ? true : false);
    };

    const handleopenCloseSnackbar = (open?: any) => {
        setOpenSnackbar(open ? true : false);
    };

    function deleteDocumentDialog() {
        setDeleteMessage("Are you sure you want to delete this document")
        setOpenDeleteDialog(true);
        setSuccessMessage(null);
    }

    const handleCustomerChange = (e: any) => {
        setDescriptionText(e.target.value);
        if (e.target.required) {
            if (e.target.value === "" || e.target.value.trim() === "") {
                setDescriptionTextError(true);
            }
            else {
                setDescriptionTextError(false);
            }
        }
    }

    const onClickBrowseFileasync = async (type: any, item: any) => {
        showErrorMessage(null);
        setSuccessMessage(null);
        handleopenCloseSnackbar(false);
        handleopenCloseErrorSnackbar(false);
        const name = item && item.name ? item.name : '',
            doctExtension = (name && name.substring(name.lastIndexOf('.') + 1, name.length)) || (name),
            doctExtensions = ['jpg', 'jpeg', 'png', 'pdf', '.PDF', '.PNG', '.JPEG', '.JPG'],
            canUploadFile = doctExtensions.indexOf(doctExtension.toLowerCase()) !== -1;
        if (!canUploadFile) {
            showErrorMessage('Invalid File type format');
            handleopenCloseErrorSnackbar(true);
            return;
        }

        if (item && item.size < 2000000 && browseFile.length < 3) {
            let keyAttribute = `fieldname=${type}&docExtension=${doctExtension}`;

            const customHeaders = {
                "Accept": "application/json",
                "Lob-Id": "AUTO",
                "Channel-Id": "B2B",
                "Org-Id": "",
                "Accept-Language": "EN",
            };

            let docType = '';
            if (type === 'reportFileDoc') { docType = 'reportFileDoc' }
            const formData: any = new FormData();
            formData.append("entityName", "CUSTOMER");
            formData.append("keyAttribute", keyAttribute);
            formData.append("fileUpload", item);
            formData.append("document", 'true');
            formData.append("Attributes", `key=Brand&value=${item}`);
            formData.append("sapCustomerId", sapCustomerId);
            formData.append("docType", docType);
            formData.append("label", type);
            formData.append("b2bUserId", b2bUserId ? b2bUserId : '');
            formData.append("uploadedByRole", 'Dealer');
            formData.append("uploadedBywebApplicationLoginId", '');
            formData.append("selectedFileName", item.name);
            let documentRes: any = await dispatch(updateDocument(formData, customHeaders));
            if (documentRes && documentRes.message && documentRes.message.status === 'S') {
                const { label, id } = documentRes.data;
                if (label === "reportFileDoc") {
                    let po = [...browseFile];
                    po.push({ documentId: documentRes.data });
                    setBrowseFile(po);
                }
            } else {
                showErrorMessage('Document upload failed');
                handleopenCloseErrorSnackbar(true);
            }
        }
        else if (browseFile.length >= 3) {
            showErrorMessage('Should not exceed 3 files.');
            handleopenCloseErrorSnackbar(true);

        } else if (item && item.size > 2000000) {
            showErrorMessage('Please upload less than 2MB file');
            handleopenCloseErrorSnackbar(true);
        }
    }

    function validateEmail(email: string) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    function validateMobile(number: string) {
        const re = /^\d{5,30}$/;
        return re.test(number)
    }

    const handleInputChange = (e: any, setterName: any, errorSetterName?: any, emiratesId?: any) => {
        if (emiratesId === "mobileNo") {
            let value = e.target.value;
            value = value.replace(/[^0-9]/g, '');
            setterName(value)
            if (validateMobile(value) === false) {
                setInvalidMobile(true);
            } else {
                setInvalidMobile(false);
            }
        }
        else {
            setterName(e.target.value);
        }
        if (errorSetterName && e.target.required) {
            if (e.target.value === "" || e.target.value.trim() === "")
                errorSetterName(true);
            else
                errorSetterName(false);
        }
    }

    let selectedAttachment: any = [];
    if (browseFile && browseFile.length > 0) {
        browseFile.map((po: any) => {
            if (po.documentId && po.documentId.id) {
                selectedAttachment.push({ documentId: po.documentId.id })
            }
        })
    }

    const SupportPayload = async () => {
        setLoading(true);
        let data = {
            "sessionDetails": {
                "clientIPAddress": sessionStorage.getItem('clientIp'),
                "webApplicationLoginId": sessionStorage.getItem('webApplicationLoginId'),
            },
            "reqType": "orderReport",
            "userName": name,
            "userId": b2bUserId,
            "customerName": companyName,
            "sapCustomerId": sapCustomerId,
            "ticketType": selectedRadioButton,
            "mobileNo": mobile,
            "emailId": email,
            "subject": subject,
            "description": descriptionText,
            "attachments": selectedAttachment,
            "nowOrderNo":props.orderNumber,
            "backOrderNo": sessionStorage.getItem('orderBackNumber'),
        }
        let savedData: any = await dispatch(supportPostAPI(data));
        if (savedData && savedData.message && savedData.message.status === 'S') {
            handleopenCloseErrorSnackbar(false);
            handleopenCloseSnackbar(true);
            setSuccessMessage(savedData && savedData.message && savedData.message.message);
            setSubmitted(true);
            setLoading(false);
        } else if (savedData && savedData.message && savedData.message.status === "E" || savedData && savedData.error) {
            handleopenCloseErrorSnackbar(true);
            showErrorMessage(savedData && savedData.error);
            handleopenCloseSnackbar(false);
            setSubmitted(false);
            setLoading(false);
        }
    }

    function validateFormFields(action: string, value: any) {
        switch (action) {
            // case "name": {
            //     const re = /^[a-zA-Z0-9 ]{1,250}$/;
            //     return !re.test(value) ? false : true;
            // }
            case "subject": {
                const re = /^[a-zA-Z0-9 ]{1,250}$/;
                return !re.test(value) ? false : true;
            }
            default:
                return true

        }
    }

    function ValidateSupportDialog() {
        let isValid: boolean = true;
        if (!descriptionText || descriptionText.trim() == "") {
            setDescriptionTextError(true);
            isValid = false;
        } else {
            if (descriptionText.split('').length > 500) {
                setDescriptionTextError(false);
                setDescriptionTextErrorMessage(true);
                isValid = false;
            }
            else {
                setDescriptionTextError(false);
                setDescriptionTextErrorMessage(false);
            }
        }
        if (email.trim() === "") {
            setAdminEmailError(true);
            isValid = false;
        }
        if (email.trim() !== "") {
            if (!validateEmail(email)) {
                isValid = false;
            }
            if (validateEmail(email) === false) {
                setInvalidEmail(true);
            }
            else {
                setInvalidEmail(false);
            }
        }
        if (mobile.trim() === "") {
            setAdminMobileError(true);
            isValid = false;
        }
        if (mobile.trim() !== "") {
            if (!validateMobile(mobile)) {
                isValid = false;
            }
            if (validateMobile(mobile) === false) {
                setInvalidMobile(true);
            }
            else {
                setInvalidMobile(false);
            }
        }
        if (name.trim() === "") {
            setNameError(true);
            isValid = false;
        }
        if (name.trim() !== "") {
            if (name.split('').length > 250) {
                setInvalidNameError(true);
                isValid = false;
            }
            else if (!validateFormFields("name", name)) {
                setInvalidNameError(true);
                isValid = false;
            }
            else {
                setInvalidNameError(false);
            }
        }
        if (subject.trim() === "") {
            setSubjectError(true);
            isValid = false;
        }
        if (subject.trim() !== "") {
            if (subject.split('').length > 50) {
                setInvalidSubjectError(true);
                setSubjectErrorMessage(true);
                isValid = false;
            }
            else if (!validateFormFields("name", name)) {
                setInvalidSubjectError(true);
                setSubjectErrorMessage(false);
                isValid = false;
            }
            else {
                setInvalidSubjectError(false);
                setSubjectErrorMessage(false);
            }
        }
        if (isValid) {
            SupportPayload();
        }
    }

    const removeDocument = async (type: any) => {
        // dispatch(resetValues());
        let po = [...browseFile]
        const obj = {
            "type": type
        }
        const documentId = po[currentIndex].documentId && po[currentIndex].documentId.id;
        let response: any = await dispatch(deleteSupportDocument(sapCustomerId, documentId, obj));
        if (response && response.message && response.message.status === 'S') {
            po[currentIndex].documentId = null;
            po.splice(currentIndex, 1);
            setBrowseFile(po);
            setSuccessMessage(response.message.message);
            handleopenCloseSnackbar(true);
            setOpenDeleteDialog(false);
        } else {
            showErrorMessage(response.error);
            handleopenCloseErrorSnackbar(true);
        }
    }

    return (
        <div>
            {loading && (<FullscreenLoader />)}
            <Dialog
                open={props.openCloseSupportDialog}
                aria-abelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
                maxWidth="md"
            >
                <Snackbar
                    open={openSnackbar}
                    handleopenClose={() => {
                        handleopenCloseSnackbar(false)
                    }} message={successMessage} />
                <Snackbar
                    open={openErrorSnackbar}
                    type="error"
                    handleopenClose={() => {
                        handleopenCloseErrorSnackbar(false)
                    }} message={errorMessage} />
                <Toolbar
                    className={clsx(classes.root)}>
                    <div className="col-12 mb-4 mt-4">
                        <Typography className={classes.title} variant="h4" id="tableTitle" component="div">
                            <h4 className="shift-to-party-page-title">
                                <div className={`px-3 ${classes.addUpdateDailogTitle}`}>
                                    Report Order
                                </div>
                                <IconButton aria-label="close" className="float-right" onClick={() => { props.setOpenCloseSupportDialog(false); setSubmitted(false); resetData(); }}>
                                    <CloseIcon />
                                </IconButton>
                            </h4>
                        </Typography>
                    </div>
                </Toolbar>
                {submitted === true && (
                    <div className='mb-5'>
                        <div className="d-flex justify-content-center mt-4">
                            <CheckCircleIcon className="quotation-created" fontSize="small" />
                        </div>
                        <div className='mt-2 mb-5'>
                            <DialogContentText id="alert-dialog-description">
                                <div className="mt-2">
                                    <h5 className="text-center submit-quotation-dialog-content ">Issue reported successfully.</h5>
                                </div>
                                <div className="pr-2 mb-2">
                                    {/* <h3 className="text-center document-content-fontcolor pt-3 pb-2 pr-3 pl-3">need to dicuss the text message with sumeet</h3> */}
                                </div>
                            </DialogContentText>
                        </div>
                    </div>
                )}
                {submitted === false && (
                    <div className="row m-0 p-0">
                        <div className="col-12 mt-1 mb-1 px-4">
                            <h6 className="info-sub-header-document-support px-4">  <span className='red'>*</span> Mandatory Fields.</h6>
                        </div>
                    </div>
                )}
                {submitted === false && (
                    <>
                        <div className='px-3'>
                            <div className="px-4 col-12 col-sm-12 mt-2 col-md-12 mb-2 col-lg-12 d-inline-flex pl-0">
                                <TextField
                                    required
                                    id="subject"
                                    value={subject}
                                    error={subjectError || invalidSubjectError}
                                    inputProps={{ maxLength: 250 }}
                                    onChange={(e: any) => handleInputChange(e, setSubject, setSubjectError)}
                                    size="small"
                                    className="smallText mt-2 px-1 mr-3 commonRoundedInputs w-100"
                                    label="Subject"
                                    variant="outlined"
                                />
                            </div>
                            {subjectError &&
                                <span className="col-12 px-4" style={{ color: 'red' }}><span className='px-1'></span>Please enter subject.</span>
                            }
                            {subjectErrorMessage &&
                                <span className="col-12 px-4" style={{ color: 'red' }}><span className='px-1'></span>Only 50 characters are allowed.</span>
                            }
                        </div>
                        <div className="row col-md-12 mb-1 mt-3 px-5">
                            <div className="col-lg-12 col-md-12 col-sm-12">
                                <TextareaAutosize id="descriptionText" value={descriptionText} onChange={(e: any) => handleCustomerChange(e)}
                                    aria-label="minimum height" minRows={3}
                                    placeholder="Description..." className="support-decription col-lg-12 col-md-12 col-sm-12 p-4"
                                />
                            </div>
                            {descriptionTextError &&
                                <span className="col-12" style={{ color: 'red' }}>Please enter description.</span>
                            }
                            {descriptionTextErrorMessage &&
                                <span className="col-12" style={{ color: 'red' }}>Only 500 characters are allowed.</span>
                            }
                        </div>
                        <Toolbar className="mb-2 mt-2 px-5">
                            <TextField
                                required
                                id="name"
                                value={name}
                                error={nameError || inavlidNameError}
                                inputProps={{ maxLength: 250 }}
                                helperText={inavlidNameError &&
                                    <span style={{ color: 'red' }}> {OnboardingAddOwnerPartnerValidation.OWNER_PARTNER_FIRST_NAME_TEXT}</span>
                                }
                                onChange={(e: any) => handleInputChange(e, setName, setNameError)}
                                size="small"
                                className=" col-lg-4 col-md-4 col-sm-4 pr-3 commonRoundedInputs common-input-font-size"
                                label="Name"
                                variant="outlined"
                            />
                            <TextField
                                required
                                id="mobile"
                                error={adminMobileError || invalidMobile}
                                placeholder={OnboardingAddOwnerPartnerValidation.OWNER_PARTNER_IB_CUSTOMER_MOBILE_TEXT}
                                value={mobile}
                                helperText={invalidMobile && <span style={{ color: 'red' }}>{"It can be upto 30 digits."}</span>}
                                onChange={(e: any) => handleInputChange(e, setMobile, setAdminMobileError, "mobileNo")}
                                size="small"
                                className=" col-lg-4 col-md-4 col-sm-4 pr-3 commonRoundedInputs common-input-font-size"
                                label="Mobile Number"
                                variant="outlined"
                            />
                            <TextField
                                required
                                id="email"
                                value={email}
                                error={adminEmailError || invalidEmail}
                                helperText={invalidEmail &&
                                    <span style={{ color: 'red' }}>Please enter a valid email address.</span>
                                }
                                onChange={(e: any) => handleInputChange(e, setEmail, setAdminEmailError)}
                                size="small"
                                className="col-lg-4 col-md-4 col-sm-4 pr-3 commonRoundedInputs common-input-font-size"
                                label="Email"
                                variant="outlined"
                            />
                        </Toolbar>
                        <p className='px-5 dealer-table-title-desc mt-3 mb-0'>Upload Proof/Reference (File Format: JPG, PNG, PDF)</p>
                        <div className="row ml-auto col-12 mb-4 mt-0">
                            <div className="col-lg-3 col-md-3 col-sm-3 mb-0  mt-0">
                                <div className="row justify-content-start mt-3 px-4">
                                    <Button variant="contained"
                                        component="label" color="secondary"
                                        className="rounded-button-dealer px-4 mt-0">
                                        Browse File
                                        <input type="file" hidden multiple
                                            onChange={(e) =>
                                                (e.target && e.target.files) ?
                                                    onClickBrowseFileasync("reportFileDoc", e.target.files[0]) : ""
                                            }
                                        />
                                    </Button>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-6 col-sm-6 mb-2 pl-0 mt-2">
                                {browseFile && browseFile.length > 0 && browseFile.map((file: any, index: any) => (
                                    <div className="file-name-kam">
                                        <span className="cursor-pointer"
                                            onClick={() => {
                                                window.open(file.documentId.url)
                                            }}
                                        >{file.documentId && file.documentId.name ? file.documentId.name : file.documentId && file.documentId.selectedFileName}</span>
                                        {file.documentId && file.documentId.selectedFileName ? (
                                            <IconButton className="p-1" aria-label="delete"
                                                onClick={(e: any) => { deleteDocumentDialog(); setCurrentIndex(index) }}>
                                                <CancelIcon className="file-close-color" fontSize="small" />
                                            </IconButton>
                                        ) : ''}
                                    </div>
                                ))}
                            </div>
                            <DialogActions className="mt-0 col-lg-3 col-md-3 col-sm-3 mb-3 justify-content-end">
                                <Button variant="contained"
                                    color="primary" className="submit-button-chooseCustomer"
                                    onClick={() => {
                                        setLoading(true);
                                        ValidateSupportDialog();
                                        setLoading(false);
                                    }}>Submit
                                </Button>
                                <Button variant="contained" className={`${classes.closeButton}`}
                                    onClick={() => {
                                        props.setOpenCloseSupportDialog(false);
                                        resetData();
                                        setSubmitted(false);
                                    }}>Cancel
                                </Button>
                                <DeleteDialog
                                    open={openDeleteDialog}
                                    text={deleteMessage}
                                    handleopenClose={(data: any) => {
                                        if (data === 'delete') {
                                            removeDocument("supportTicket");
                                        }
                                        if (!data) handleopenClose(false);
                                    }} />
                            </DialogActions>
                        </div>
                    </>
                )}

            </Dialog >
        </div >
    )
}
export default ReportOrderDialog