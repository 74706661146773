/* eslint-disable */
import React, { Fragment, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import DeactivateDialogSuperAdmin from './DeactivateDialogSuperAdmin';
import ActivateDialogSuperAdmin from './ActivateDialogSuperAdmin';

export default function ActionButtonsSuperAdmin(props: any) {
    const [showApproveDialog, setshowApproveDialog] = React.useState<boolean>(false);
    const [showDeactivateDialog, setshowDeactivateDialog] = React.useState<boolean>(false);
    const [customerTypes, setcustomerTypes] = React.useState<any>([]);
    const [openSnackbar, setOpenSnackbar] = React.useState(false);
    const [status, setdealerStatus] = React.useState<string>("");
    const { deactiveShow } = props;
    const leftMenuVisibility: any = useSelector(
        (state: any) => state.global && state.global.sideMenuOpened
    );
    const [isHidden, setIsHidden] = React.useState(false);
    const { getCustomerDetails }: any = useSelector((state: any) => state.superAdmin);
    const userCurrentStatus = sessionStorage.getItem('userCurrentStatus');
    const isStatusPlusComing = sessionStorage.getItem('isStatusPlusComing');

    useEffect(() => {
        // loadCustomerTypes();
    }, [])

    useEffect(() => {
        if (userCurrentStatus === "Document expiry issue") {
            setdealerStatus("greenActionButton")
        }
        if (getCustomerDetails?.data?.data?.status == "Deactive") {
            setdealerStatus("greenActionButton")
        }
        else if (getCustomerDetails?.data?.data?.status == "Active" && userCurrentStatus !== "Document expiry issue" && isStatusPlusComing === "false") {
            setdealerStatus("redActionButton")
        }
        else if (getCustomerDetails?.data?.data?.status == "Active" && isStatusPlusComing === "true") {
            setdealerStatus("greenActionButton")
        } else if(getCustomerDetails?.data?.data?.status == "Active" && isStatusPlusComing === "false"){
            setdealerStatus("redActionButton")
        }
    }, [props])

    // const loadCustomerTypes = async () => {
    //     const data = await onboardingService.getCustomerTypes();
    //     if (data && data.data && data.data[0])
    //         setcustomerTypes(data.data[0].customerType)
    // }

    const handleopenCloseSnackbar = (openRequestSnackbar?: any) => {
        setOpenSnackbar(openRequestSnackbar ? true : false);
    };

    return (
        <Fragment>
            <div className={leftMenuVisibility ? "row card card-body p-2 fixed-bottom ml-280" : "row card card-body p-2 fixed-bottom ml-70"}>
                <div className="col-12 d-flex mt-2 p-0">
                    <div className="col-9">
                        <div className="">
                            {deactiveShow && getCustomerDetails?.data?.data?.status ?
                                <Button variant="contained"
                                    onClick={() => {
                                        if (deactiveShow) {
                                            setshowDeactivateDialog(true);
                                            setOpenSnackbar(false)
                                        }
                                    }}
                                    className={`${status} border-left rounded-pill ml-4 mb-2`} >
                                    {getCustomerDetails?.data?.data?.status == "Active" && isStatusPlusComing === "true" ? 'Activate Customer' : userCurrentStatus === "Document expiry issue" ? 'Activate Customer' : getCustomerDetails?.data?.data?.status == "Deactive" ? 'Activate Customer' : getCustomerDetails?.data?.data?.status == "Active" && userCurrentStatus !== "Document expiry issue" ? 'Deactivate Customer' : 'Deactivate Customer'}</Button> : ""
                            }
                            {getCustomerDetails?.data?.data?.status === "Deactive" && "Activate Customer" && userCurrentStatus !== "Document expiry issue" ?
                                <div className="row px-2">
                                    <span className="row text-info px-5">Deactivation Reason :
                                        <div className="row px-3 text-info-deactivationReason">
                                            {getCustomerDetails && getCustomerDetails.data &&
                                                getCustomerDetails.data.data &&
                                                getCustomerDetails.data.data.deactivationReason.slice(0, 100)}
                                            {isHidden === false && getCustomerDetails?.data?.data?.status ? getCustomerDetails && getCustomerDetails.data && getCustomerDetails.data.data &&
                                                getCustomerDetails.data.data.deactivationReason.length > 100 &&
                                                <button
                                                    className='text-info mb-2'
                                                    style={{ border: "none" }}
                                                    onClick={() => {
                                                        setIsHidden(true);
                                                    }}
                                                >..read more</button> : ""}
                                            {isHidden && getCustomerDetails?.data?.data?.status ?
                                                getCustomerDetails && getCustomerDetails.data && getCustomerDetails.data.data &&
                                                getCustomerDetails.data.data.deactivationReason.slice(100) : ""}
                                        </div>
                                    </span>
                                </div>
                                : ""}
                            {userCurrentStatus === "Document expiry issue" ?
                                <div className="row px-2">
                                    <span className="row text-info px-5">Deactivation Reason :
                                        <div className="row px-3 text-info-deactivationReason">Document expiry issue</div>
                                    </span>
                                </div>
                                : ""}
                        </div>
                    </div>
                    <div className="col-3 onboard-footer">
                        <div className="">
                            <Button
                                className={`blueActionButton-superAdmin border-left rounded-pill ml-5 mb-1`}
                                size="small"
                                variant="contained"
                                color="primary"
                                type="submit"
                                onClick={() => { props.handleSubmit(); }}
                            >Update
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
            <ActivateDialogSuperAdmin
                customerTypes={customerTypes}
                open={showApproveDialog}
                getCustomerDetails={getCustomerDetails}
                openRequestSnackbar={openSnackbar}
                handleopenCloseSnackbar={handleopenCloseSnackbar}
                handleopenClose={(data: any) => {
                    setshowApproveDialog(data ? true : false)
                }}
            />
            <DeactivateDialogSuperAdmin
                open={showDeactivateDialog}
                getCustomerDetails={getCustomerDetails}
                openRequestSnackbar={openSnackbar}
                handleopenCloseSnackbar={handleopenCloseSnackbar}
                handleopenClose={(data: any) => {
                    setshowDeactivateDialog(data ? true : false)
                }}
            />
        </Fragment>
    );
}
