import { API_ENDPOINTS, ORDER_ENQUIRY } from "../constants/apiConfig";
import { makeRequest } from "../middleware/axiosMiddleware"

export const orderEnquiryService = {
    getEnquiriesList,
    getEnquiryStatus,
    getLimitedEnquiriesList,
    getFSEList,
    getFSEEnquiriesList,
    getLimitedFSEEnquiriesList,
};

async function getEnquiriesList(customerTierType: any, filterParams: any, isMyEnquiryChecked?: any, page?: any,) {
    const apiPath = API_ENDPOINTS.ENQUIRY + ORDER_ENQUIRY.API_PATH;
    const type = customerTierType && customerTierType.toLowerCase();
    const options = {
        path: `${apiPath}/customers/${sessionStorage.getItem('customerId')}`,
        method: "POST",
        bodyObj: {
            "sessionDetails": {
                "webApplicationLoginId": sessionStorage.getItem('webApplicationLoginId'),
                "clientIPAddress": sessionStorage.getItem('clientIp'),
            },
            "searchParams": filterParams,
            customerType: type,
            b2cUserId: isMyEnquiryChecked === true ? sessionStorage.getItem('userId') : ""
        },
        customHeaders: {
            "Lob-Id": "AUTO",
            "Channel-Id": "B2B",
            "Paging-Info": `start-index=${page ? (parseInt(page) * 10) : 0}|no-of-records=10`
        },
        formdata: true
    };
    return await makeRequest(options)
}

async function getFSEEnquiriesList(customerTypeAccess: any, filterParams: any, page?: any,) {
    const apiPath = API_ENDPOINTS.ENQUIRY + ORDER_ENQUIRY.API_PATH;
    const options = {
        path: `${apiPath}/FSE/${sessionStorage.getItem('webApplicationLoginId')}`,
        method: "POST",
        bodyObj: {
            "sessionDetails": {
                "webApplicationLoginId": sessionStorage.getItem('webApplicationLoginId'),
                "clientIPAddress": sessionStorage.getItem('clientIp'),
            },
            "searchParams": filterParams,
            "FseCustomerType": customerTypeAccess,
            "userRole": "FSE"
        },
        customHeaders: {
            "Lob-Id": "AUTO",
            "Channel-Id": "B2B",
            "Paging-Info": `start-index=${page ? (parseInt(page) * 10) : 0}|no-of-records=10`
        },
        formdata: true
    };
    return await makeRequest(options)
}

async function getLimitedFSEEnquiriesList(customerTypeAccess: any, filterParams: any, page?: any,) {
    const apiPath = API_ENDPOINTS.ENQUIRY + ORDER_ENQUIRY.API_PATH;
    const options = {
        path: `${apiPath}/FSE/${sessionStorage.getItem('webApplicationLoginId')}`,
        method: "POST",
        bodyObj: {
            "sessionDetails": {
                "webApplicationLoginId": sessionStorage.getItem('webApplicationLoginId'),
                "clientIPAddress": sessionStorage.getItem('clientIp'),
            },
            "searchParams": filterParams,
            "FseCustomerType": customerTypeAccess,
            "userRole": "FSE"
        },
        customHeaders: {
            "Lob-Id": "AUTO",
            "Channel-Id": "B2B",
            "Paging-Info": `start-index=${page ? (parseInt(page) * 5) : 0}|no-of-records=5`
        },
        formdata: true
    };
    return await makeRequest(options)
}


async function getLimitedEnquiriesList(customerTierType: any, filterParams: any, page?: any) {
    const apiPath = API_ENDPOINTS.ENQUIRY + ORDER_ENQUIRY.API_PATH;
    const type = customerTierType && customerTierType.toLowerCase();
    const options = {
        path: `${apiPath}/customers/${sessionStorage.getItem('customerId')}`,
        method: "POST",
        bodyObj: {
            "sessionDetails": {
                "webApplicationLoginId": sessionStorage.getItem('webApplicationLoginId'),
                "clientIPAddress": sessionStorage.getItem('clientIp'),
            },
            "searchParams": filterParams,
            customerType: type,
        },
        customHeaders: {
            "Lob-Id": "AUTO",
            "Channel-Id": "B2B",
            "Paging-Info": `start-index=${page ? (parseInt(page) * 5) : 0}|no-of-records=5`
        },
        formdata: true
    };
    return await makeRequest(options)
}

async function getEnquiryStatus(customerTierType: any) {
    const type = customerTierType && customerTierType.toLowerCase();
    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + ORDER_ENQUIRY.FORMFIELD + `/enquiryStatus?customerType=${type}`;
    const options = {
        path: apiPath,
        method: "GET",
        formdata: true
    };
    return await makeRequest(options)
}

async function getFSEList() {
    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + `/formField/masterData?dataType=fseUserListForFsePortal&loginId=${sessionStorage.getItem('webApplicationLoginId')}`
    const options = {
        path: apiPath,
        method: "GET",
        formdata: true
    };
    return await makeRequest(options)
}

