import { DEALER } from "./actionsTypes";
import { apiStart, apiError, apiSuccess } from "./apiActions";
import { dealerService } from "../../services/dealer.service";

export const dealerActions = {
  createDealer,
  createDealerUser,
  getAllDealers,
  updateDealerInProgressUser,
  getDealerDetails,
  checkUserIdAvailability,
  updateDealerData,
  updateDealerUser,
  verifySAPCustomerId,
  getAccessParam,
  addAccessParam,
  updateAccessParam,
  sendEmail,
  getkamUserList,
  getDealerCreditRequest,
  saveAdditionalCreditRequest,
  deleteBankStatementOfCreditRequest,
  generateB2bUserId,
  sendInvitationLink,
  confirmDataFromDealer,
  getCustomerFilterStatus,
  getRefreshDataFromSAP,
  getCurrency,
  getRefreshDataFromSAPCustomerDetails,
  getCustomerDetailsByCustomerID,
  getFSECustomerList,
  getFSEEnquiryStatus,
  getInactiveStatusDetails
};

export function createDealer(data: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(DEALER.POST_DEALER_FETCHING));
    try {
      const response = await dealerService.addDealer(data);
      dispatch(apiSuccess(DEALER.POST_DEALER_SUCCESS, response));
      return response || [];
    } catch (error) {
      dispatch(apiError(DEALER.POST_DEALER_ERROR, error));
      return error;
    }
  };
}

export function createDealerUser(data: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(DEALER.POST_DEALER_USER_FETCHING));
    try {
      const response = await dealerService.addDealerUser(data);
      dispatch(apiSuccess(DEALER.POST_DEALER_USER_SUCCESS, response));
      return response || [];
    } catch (error) {
      dispatch(apiError(DEALER.POST_DEALER_USER_ERROR, error));
      return error;
    }
  };
}

export function getAllDealers(filterBy?: string, filterBySalesRegion ?: string, customerTier?: any, searchBy?: string, page?: number) {
  return async (dispatch: any) => {
    dispatch(apiStart(DEALER.GET_ALL_DEALERS_FETCHING));
    try {
      const response = await dealerService.getAllDealers(filterBy, filterBySalesRegion, customerTier, searchBy, page);
      dispatch(apiSuccess(DEALER.GET_ALL_DEALERS_SUCCESS, response));
      return response || [];
    } catch (error) {
      dispatch(apiError(DEALER.GET_ALL_DEALERS_ERROR, error));
      return error;
    }
  };
}

export function getAgreementDocs(type: any, sapId?: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(DEALER.GET_AGREEMENT_DOCS_FETCHING));
    try {
      const response = await dealerService.getAgreementDocs(type, sapId);
      dispatch(apiSuccess(DEALER.GET_AGREEMENT_DOCS_SUCCESS, response));
      return response || [];
    } catch (error) {
      dispatch(apiError(DEALER.GET_AGREEMENT_DOCS_ERROR, error));
      return error;
    }
  };
}

export function sendCustomerAgreementMail(data: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(DEALER.SEND_CUSTOMER_AGREEMENT_MAIL_FETCHING));
    try {
      const response = await dealerService.sendCustomerAgreementMail(data);
      dispatch(apiSuccess(DEALER.SEND_CUSTOMER_AGREEMENT_MAIL_SUCCESS, response));
      return response || [];
    } catch (error) {
      dispatch(apiError(DEALER.SEND_CUSTOMER_AGREEMENT_MAIL_ERROR, error));
      return error;
    }
  };
}

export function sendCategoryCertificateMail(data: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(DEALER.SEND_CATEGORY_CERTIFICATE_MAIL_FETCHING));
    try {
      const response = await dealerService.sendCategoryCertificateMail(data);
      dispatch(apiSuccess(DEALER.SEND_CATEGORY_CERTIFICATE_MAIL_SUCCESS, response));
      return response || [];
    } catch (error) {
      dispatch(apiError(DEALER.SEND_CATEGORY_CERTIFICATE_MAIL_ERROR, error));
      return error;
    }
  };
}

export function getAllFSEDealers(filterBy?: string, filterBySalesRegion ?: string, customerTier?: any, searchBy?: string, page?: number) {
  return async (dispatch: any) => {
    dispatch(apiStart(DEALER.GET_ALL_DEALERS_FETCHING));
    try {
      const response = await dealerService.getAllFSEDealers(filterBy, filterBySalesRegion, customerTier, searchBy, page);
      dispatch(apiSuccess(DEALER.GET_ALL_DEALERS_SUCCESS, response));
      return response || [];
    } catch (error) {
      dispatch(apiError(DEALER.GET_ALL_DEALERS_ERROR, error));
      return error;
    }
  };
}

export function getInactiveStatusDetails(sapCustomerId: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(DEALER.GET_INACTIVE_STATUS_DETAILS_FETCHING));
    try {
      const response = await dealerService.getInactiveStatusDetails(sapCustomerId);
      dispatch(apiSuccess(DEALER.GET_INACTIVE_STATUS_DETAILS_SUCCESS, response));
      return response || [];
    } catch (error) {
      dispatch(apiError(DEALER.GET_INACTIVE_STATUS_DETAILS_ERROR, error));
      return error;
    }
  };
}

export function getFSECustomerTypes() {
  return async (dispatch: any) => {
    dispatch(apiStart(DEALER.GET_FSE_CUSTOMERS_FETCHING));
    try {
      const response = await dealerService.getFSECustomerTypes();
      dispatch(apiSuccess(DEALER.GET_FSE_CUSTOMERS_SUCCESS, response));
      return response || [];
    } catch (error) {
      dispatch(apiError(DEALER.GET_FSE_CUSTOMERS_ERROR, error));
      return error;
    }
  };
}

export function getCustomerDetailsByCustomerID(sapCustomerId: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(DEALER.GET_FSE_CUSTOMER_DETAILS_BY_ID_FETCHING));
    try {
      const response = await dealerService.getCustomerDetailsByCustomerID(sapCustomerId);
      dispatch(apiSuccess(DEALER.GET_FSE_CUSTOMER_DETAILS_BY_ID_SUCCESS, response));
      return response || [];
    } catch (error) {
      dispatch(apiError(DEALER.GET_FSE_CUSTOMER_DETAILS_BY_ID_ERROR, error));
      return error;
    }
  };
}

export function resetValuesForChooseCustomer(type?: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(DEALER.RESET_VALUES_FOR_ChOOSE_CUSTOMER, type));
  };
}

export function getDataFromChooseCustomer(type?: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(DEALER.STORE_VALUES_FOR_ChOOSE_CUSTOMER, type));
  };
}

export function setValuesForChooseCustomer() {
  return async (dispatch: any) => {
    dispatch(apiStart(DEALER.SET_VALUES_FOR_ChOOSE_CUSTOMER_FETCHING));
    try {
      const response = await dealerService.getFSECustomerList();
      dispatch(apiSuccess(DEALER.SET_VALUES_FOR_ChOOSE_CUSTOMER_SUCCESS, response));
      return response || [];
    } catch (error) {
      dispatch(apiError(DEALER.SET_VALUES_FOR_ChOOSE_CUSTOMER_ERROR, error));
      return error;
    }
  };
}

export function getFSECustomerList() {
  return async (dispatch: any) => {
    dispatch(apiStart(DEALER.GET_FSE_CUSTOMERS_LIST_FETCHING));
    try {
      const response = await dealerService.getFSECustomerList();
      dispatch(apiSuccess(DEALER.GET_FSE_CUSTOMERS_LIST_SUCCESS, response));
      return response || [];
    } catch (error) {
      dispatch(apiError(DEALER.GET_FSE_CUSTOMERS_LIST_ERROR, error));
      return error;
    }
  };
}

export function getFSEEnquiryStatus() {
  return async (dispatch: any) => {
    dispatch(apiStart(DEALER.GET_FSE_ENQUIRY_STATUS_FETCHING));
    try {
      const response = await dealerService.getFSEEnquiryStatus();
      dispatch(apiSuccess(DEALER.GET_FSE_ENQUIRY_STATUS_SUCCESS, response));
      return response || [];
    } catch (error) {
      dispatch(apiError(DEALER.GET_FSE_ENQUIRY_STATUS_ERROR, error));
      return error;
    }
  };
}

export function getCustomerFilterStatus() {
  return async (dispatch: any) => {
    dispatch(apiStart(DEALER.GET_CUSTOMER_FILTER_STATUS_FETCHING));
    try {
      const response = await dealerService.getCustomerFilterStatus();
      dispatch(apiSuccess(DEALER.GET_CUSTOMER_FILTER_STATUS_SUCCESS, response));
      return response || [];
    } catch (error) {
      dispatch(apiError(DEALER.GET_CUSTOMER_FILTER_STATUS_ERROR, error));
      return error;
    }
  };
}

export function getAllDealersRequests(loginId?: string, customerTier?: string, searchBy?: string, filterValue?: any, filterByStatus?: string, page?: number) {
  return async (dispatch: any) => {
    dispatch(apiStart(DEALER.GET_ALL_DEALER_REQUESTS_FETCHING));
    try {
      const response = await dealerService.getAllDealersRequests(loginId, customerTier, searchBy, filterValue, filterByStatus, page);
      dispatch(apiSuccess(DEALER.GET_ALL_DEALER_REQUESTS_SUCCESS, response));
      return response || [];
    } catch (error) {
      dispatch(apiError(DEALER.GET_ALL_DEALER_REQUESTS_ERROR, error));
      return error;
    }
  };
}

export function getDealerDetails(customerId: string, type: any, webApplicationLoginId: any = null) {
  return async (dispatch: any) => {
    dispatch(apiStart(DEALER.GET_DEALER_DETAILS_FETCHING));
    try {
      const response = await dealerService.getDealerDetails(customerId, type, webApplicationLoginId);
      dispatch(apiSuccess(DEALER.GET_DEALER_DETAILS_SUCCESS, response));
      return response || [];
    } catch (error) {
      dispatch(apiError(DEALER.GET_DEALER_DETAILS_ERROR, error));
      return error;
    }
  };
}

export function getRefreshDataFromSAP(refreshAddress?: any, id?: any, shipToPartyNumber?: any, webApplicationLoginId?: any, userName?: any, addressId?: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(DEALER.GET_ON_REFRESH_SAP_DATA_FETCHING));
    try {
      const response = await dealerService.getRefreshDataFromSAP(refreshAddress, id, shipToPartyNumber, webApplicationLoginId, userName.replace(/\s+/g, ''), addressId);
      dispatch(apiSuccess(DEALER.GET_ON_REFRESH_SAP_DATA_SUCCESS, response));
      return response || [];
    } catch (error) {
      dispatch(apiError(DEALER.GET_ON_REFRESH_SAP_DATA_ERROR, error));
      return error;
    }
  };
}

export function getRefreshDataFromSAPCustomerDetails(refreshCustomerDetails?: any, id?: any, webApplicationLoginId?: any, userName?: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(DEALER.GET_CUSTOMER_DETAILS_REFRESH_SAP_DATA_FETCHING));
    try {
      const response = await dealerService.getRefreshDataFromSAPCustomerDetails(refreshCustomerDetails, id, webApplicationLoginId, userName.replace(/\s+/g, ''));
      dispatch(apiSuccess(DEALER.GET_CUSTOMER_DETAILS_REFRESH_SAP_DATA_SUCCESS, response));
      return response || [];
    } catch (error) {
      dispatch(apiError(DEALER.GET_CUSTOMER_DETAILS_REFRESH_SAP_DATA_ERROR, error));
      return error;
    }
  };
}

export function getCurrency() {
  return async (dispatch: any) => {
    dispatch(apiStart(DEALER.GET_ALL_CURRENCY_DATA_FETCHING));
    try {
      const response = await dealerService.getCurrency();
      dispatch(apiSuccess(DEALER.GET_ALL_CURRENCY_DATA_SUCCESS, response));
      return response || [];
    } catch (error) {
      dispatch(apiError(DEALER.GET_ALL_CURRENCY_DATA_ERROR, error));
      return error;
    }
  };
}
export function postSetDefaultAddress(data: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(DEALER.POST_DEALER_SET_DEFAULT_ADDRESS_FETCHING));
    try {
      const response = await dealerService.postSetDefaultAddress(data);
      dispatch(apiSuccess(DEALER.POST_DEALER_SET_DEFAULT_ADDRESS_SUCCESS, response));
      return response || [];
    } catch (error) {
      dispatch(apiError(DEALER.POST_DEALER_SET_DEFAULT_ADDRESS_ERROR, error));
      return error;
    }
  };
}

export function checkUserIdAvailability(data: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(DEALER.CHECK_USER_AVAILABILITY_FETCHING));
    try {
      const response = await dealerService.checkUserIdAvailability(data);
      dispatch(apiSuccess(DEALER.CHECK_USER_AVAILABILITY_SUCCESS, response));
      return response || [];
    } catch (error) {
      dispatch(apiError(DEALER.CHECK_USER_AVAILABILITY_ERROR, error));
      return error;
    }
  };
}

export function updateDealerData(customerId: string, data: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(DEALER.PUT_DEALER_FETCHING));
    try {
      const response = await dealerService.updateDealerData(customerId, data);
      dispatch(apiSuccess(DEALER.PUT_DEALER_SUCCESS, response));
      return response || [];
    } catch (error) {
      dispatch(apiError(DEALER.PUT_DEALER_ERROR, error));
      return error;
    }
  };
}

export function updateDealerUser(data: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(DEALER.PUT_DEALER_USER_FETCHING));
    try {
      const response = await dealerService.updateDealerUser(data);
      dispatch(apiSuccess(DEALER.PUT_DEALER_USER_SUCCESS, response));
      return response || [];
    } catch (error) {
      dispatch(apiError(DEALER.PUT_DEALER_USER_ERROR, error));
      return error;
    }
  };
}

export function updateDealerInProgressUser(data: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(DEALER.PUT_DEALER_USER_IN_PROGRESS_FETCHING));
    try {
      const response = await dealerService.updateDealerInProgressUser(data);
      dispatch(apiSuccess(DEALER.PUT_DEALER_USER_IN_PROGRESS_SUCCESS, response));
      return response || [];
    } catch (error) {
      dispatch(apiError(DEALER.PUT_DEALER_USER_IN_PROGRESS_ERROR, error));
      return error;
    }
  };
}

export function getAllUsers(id?: any, page?: number, searchBy?: string, isModifyParameter?: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(DEALER.GET_USER_LIST_FETCHING));
    const response = await dealerService.getAllUsers(id, page, searchBy, isModifyParameter);
    if (response.error) {
      dispatch(apiError(DEALER.GET_USER_LIST_ERROR, response));
      return response || [];
    } else {
      dispatch(apiSuccess(DEALER.GET_USER_LIST_SUCCESS, response));
      return response || [];
    }
  }
}

export function verifySAPCustomerId(sapCustomerId: string) {
  return async (dispatch: any) => {
    dispatch(apiStart(DEALER.VERIFY_SAPCUSTOMERID_FETCHING));
    try {
      const response = await dealerService.verifySAPCustomerId(sapCustomerId);
      dispatch(apiSuccess(DEALER.VERIFY_SAPCUSTOMERID_SUCCESS, response));
      return response || [];
    } catch (error) {
      dispatch(apiError(DEALER.VERIFY_SAPCUSTOMERID_ERROR, error));
      return error;
    }
  };
}

export function setUserDetails(user: any) {
  return async (dispatch: any) => {
    dispatch(apiSuccess(DEALER.SET_USER_DETAILS, user));
    return user || {};
  };
}

export function getUserDetails(b2bUserId: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(DEALER.GET_USER_DETAILS_FETCHING));
    const response = await dealerService.getUserDetailsInfo(b2bUserId);
    if (response.error) {
      dispatch(apiError(DEALER.GET_USER_DETAILS_ERROR, response));
      return response || [];
    } else {
      dispatch(apiSuccess(DEALER.GET_USER_DETAILS_SUCCESS, response));
      return response || [];
    }
  }
}

export function storeBrandValues(values: any) {
  return async (dispatch: any) => {
    dispatch(apiSuccess(DEALER.STORE_BRAND_VALUES, values));
    return values || {};
  };
}

export function getUserParameters(sapCustomerId: any, b2bUserId: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(DEALER.GET_USER_PARAMETER_FETCHING));
    const response = await dealerService.getUserParameter(sapCustomerId, b2bUserId);
    if (response.error) {
      dispatch(apiError(DEALER.GET_USER_PARAMETER_ERROR, response));
      return response || [];
    } else {
      dispatch(apiSuccess(DEALER.GET_USER_PARAMETER_SUCCESS, response));
      return response || [];
    }
  }
}

export function resendInvitationLink(b2bUserId: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(DEALER.RESEND_INVITATION_LINK_FETCHING));
    const response = await dealerService.resendInvitationLink(b2bUserId);
    if (response.error) {
      dispatch(apiError(DEALER.RESEND_INVITATION_LINK_ERROR, response));
      return response || [];
    } else {
      dispatch(apiSuccess(DEALER.RESEND_INVITATION_LINK_SUCCESS, response));
      return response || [];
    }
  }
}

export function addUserParameters(data: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(DEALER.ADD_USER_PARAMETER_FETCHING));
    const response = await dealerService.addUserParameter(data);
    if (response.error) {
      dispatch(apiError(DEALER.ADD_USER_PARAMETER_ERROR, response));
      return response || [];
    } else {
      dispatch(apiSuccess(DEALER.ADD_USER_PARAMETER_SUCCESS, response));
      return response || [];
    }
  }
}

export function editUserParameters(data: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(DEALER.EDIT_USER_PARAMETER_FETCHING));
    const response = await dealerService.editUserParameter(data);
    if (response.error) {
      dispatch(apiError(DEALER.EDIT_USER_PARAMETER_ERROR, response));
      return response || [];
    } else {
      dispatch(apiSuccess(DEALER.EDIT_USER_PARAMETER_SUCCESS, response));
      return response || [];
    }
  }
}

export function getAccessParam(id: any, adminId: any, action: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(DEALER.GET_ACCESS_PARAM_FETCHING));
    try {
      const response = await dealerService.getAccessParam(id, adminId, action);
      dispatch(apiSuccess(DEALER.GET_ACCESS_PARAM_SUCCESS, response));
      return response || [];
    } catch (error) {
      dispatch(apiError(DEALER.GET_ACCESS_PARAM_ERROR, error));
      return error;
    }
  };
}

export function getKamAccessParam(id: any, sapId: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(DEALER.GET_ACCESS_PARAM_FETCHING));
    try {
      const response = await dealerService.getKamAccessParam(id, sapId);
      dispatch(apiSuccess(DEALER.GET_ACCESS_PARAM_SUCCESS, response));
      return response || [];
    } catch (error) {
      dispatch(apiError(DEALER.GET_ACCESS_PARAM_ERROR, error));
      return error;
    }
  };
}

export function addAccessParam(data: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(DEALER.POST_ACCESS_PARAM_FETCHING));
    try {
      const response = await dealerService.addAccessParam(data);
      dispatch(apiSuccess(DEALER.POST_ACCESS_PARAM_SUCCESS, response));
      return response || [];
    } catch (error) {
      dispatch(apiError(DEALER.POST_ACCESS_PARAM_ERROR, error));
      return error;
    }
  };
}

export function updateAccessParam(data: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(DEALER.PUT_ACCESS_PARAM_FETCHING));
    try {
      const response = await dealerService.updateAccessParam(data);
      dispatch(apiSuccess(DEALER.PUT_ACCESS_PARAM_SUCCESS, response));
      return response || [];
    } catch (error) {
      dispatch(apiError(DEALER.PUT_ACCESS_PARAM_ERROR, error));
      return error;
    }
  };
}

export function uploadDocument(data: any, customHeaders: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(DEALER.POST_UPLOAD_DOCUMENT_FETCHING));
    try {
      const response = await dealerService.uploadDocument(data, customHeaders);
      dispatch(apiSuccess(DEALER.POST_UPLOAD_DOCUMENT_SUCCESS, response));
      return response || [];
    } catch (error) {
      dispatch(apiError(DEALER.POST_UPLOAD_DOCUMENT_ERROR, error));
      return error;
    }
  };
}

export function deleteDocument(id: any, sapCustomerId: any, document: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(DEALER.DELETE_DOCUMENT_FETCHING));
    try {
      const response = await dealerService.deleteDocument(id, sapCustomerId, document);
      dispatch(apiSuccess(DEALER.DELETE_DOCUMENT_SUCCESS, response));
      return response || [];
    } catch (error) {
      dispatch(apiError(DEALER.DELETE_DOCUMENT_ERROR, error));
      return error;
    }
  };
}

export function getUploadedDocuments(customHeaders: any, sapCustomerId: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(DEALER.Get_UPLOADED_DOCUMENT_FETCHING));
    try {
      const response = await dealerService.getUploadedDocuments(customHeaders, sapCustomerId);
      dispatch(apiSuccess(DEALER.Get_UPLOADED_DOCUMENT_SUCCESS, response.data));
      return response || [];
    } catch (error) {
      dispatch(apiError(DEALER.Get_UPLOADED_DOCUMENT_ERROR, error));
      return error;
    }
  };
}

export function sendEmail(data: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(DEALER.SEND_EMAIL_FETCHING));
    const response = await dealerService.sendEmailTemplate(data);
    if (response.error) {
      dispatch(apiError(DEALER.SEND_EMAIL_ERROR, response));
      return response || [];
    } else {
      dispatch(apiSuccess(DEALER.SEND_EMAIL_SUCCESS, response));
      return response || [];
    }
  }
}

export function getkamUserList(data?: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(DEALER.GET_KAMUSER_FETCHING));
    try {
      const response = await dealerService.getKAMUserList(data);
      dispatch(apiSuccess(DEALER.GET_KAMUSER_SUCCESS, response));
      return response || [];
    } catch (error) {
      dispatch(apiError(DEALER.GET_KAMUSER_ERROR, error));
      return error;
    }
  };
}

export function getFSEKAMUserList(data?: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(DEALER.GET_FSEKAMUSER_FETCHING));
    try {
      const response = await dealerService.getFSEKAMUserList(data);
      dispatch(apiSuccess(DEALER.GET_FSEKAMUSER_SUCCESS, response));
      return response || [];
    } catch (error) {
      dispatch(apiError(DEALER.GET_FSEKAMUSER_ERROR, error));
      return error;
    }
  };
}

export function getKAMUserListForKAM(data?: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(DEALER.GET_KAMUSERFORKAM_FETCHING));
    try {
      const response = await dealerService.getKAMUserListForKAM(data);
      dispatch(apiSuccess(DEALER.GET_KAMUSERFORKAM_SUCCESS, response));
      return response || [];
    } catch (error) {
      dispatch(apiError(DEALER.GET_KAMUSERFORKAM_ERROR, error));
      return error;
    }
  };
}

export function getShipToPartyDropDownValues(data?: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(DEALER.GET_DROPDOWN_FETCHING));
    try {
      const response = await dealerService.getShipToPartyDropDownValues(data);
      dispatch(apiSuccess(DEALER.GET_DROPDOWN_SUCCESS, response));
      return response || [];
    } catch (error) {
      dispatch(apiError(DEALER.GET_DROPDOWN_ERROR, error));
      return error;
    }
  };
}

export function getDeliveryBlockReason(data?: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(DEALER.GET_DDL_FETCHING));
    try {
      const response = await dealerService.getDeliveryBlockReason(data);
      dispatch(apiSuccess(DEALER.GET_DDL_SUCCESS, response));
      return response || [];
    } catch (error) {
      dispatch(apiError(DEALER.GET_DDL_ERROR, error));
      return error;
    }
  };
}


export function getFSESalesRegions(data?: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(DEALER.GET_FSE_SALES_REGION_FETCHING));
    try {
      const response = await dealerService.getFSESalesRegions(data);
      dispatch(apiSuccess(DEALER.GET_FSE_SALES_REGION_SUCCESS, response));
      return response || [];
    } catch (error) {
      dispatch(apiError(DEALER.GET_FSE_SALES_REGION_ERROR, error));
      return error;
    }
  };
}

export function getAllSapCustomerIds() {
  return async (dispatch: any) => {
    dispatch(apiStart(DEALER.GET_SAP_CUSTOMER_IDS_FETCHING));
    const response = await dealerService.getAllSapCustomerId();
    if (response.error) {
      dispatch(apiError(DEALER.GET_SAP_CUSTOMER_IDS_ERROR, response));
      return response || [];
    } else {
      dispatch(apiSuccess(DEALER.GET_SAP_CUSTOMER_IDS_SUCCESS, response));
      return response || [];
    }
  }
}

export function getAllLostSaleReasons() {
  return async (dispatch: any) => {
    dispatch(apiStart(DEALER.GET_LOST_SALE_REASON_FETCHING));
    const response = await dealerService.getAllLostSaleReason();
    if (response.error) {
      dispatch(apiError(DEALER.GET_LOST_SALE_REASON_ERROR, response));
      return response || [];
    } else {
      dispatch(apiSuccess(DEALER.GET_LOST_SALE_REASON_SUCCESS, response));
      return response || [];
    }
  }
}

export function getAllBrands(tierType?: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(DEALER.GET_ALL_BRANDS_FETCHING));
    const response = await dealerService.getAllBrand(tierType);
    if (response.error) {
      dispatch(apiError(DEALER.GET_ALL_BRANDS_ERROR, response));
      return response || [];
    } else {
      dispatch(apiSuccess(DEALER.GET_ALL_BRANDS_SUCCESS, response));
      return response || [];
    }
  }
}

export function getAllCreditRequestStatus(type: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(DEALER.GET_ALL_CR_FETCHING));
    const response = await dealerService.getAllCRStatus(type);
    if (response.error) {
      dispatch(apiError(DEALER.GET_ALL_CR_ERROR, response));
      return response || [];
    } else {
      dispatch(apiSuccess(DEALER.GET_ALL_CR_SUCCESS, response));
      return response || [];
    }
  }
}

export function getLostSaleReport(data: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(DEALER.GET_LOST_SALE_REPORT_FETCHING));
    const response = await dealerService.getLostSaleReportData(data);
    if (response.error) {
      dispatch(apiError(DEALER.GET_LOST_SALE_REPORT_ERROR, response));
      return response || [];
    } else {
      dispatch(apiSuccess(DEALER.GET_LOST_SALE_REPORT_SUCCESS, response));
      return response || [];
    }
  }
}

export function resetReportData() {
  return async (dispatch: any) => {
    dispatch(apiStart(DEALER.RESET_REPORT_DATA));
  };
}

export function selectedNewDealer(item: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(DEALER.SET_NEW_DEALER_DATA, item));
  };
}

export function setTabValue(tabValue: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(DEALER.SET_TAB_VALUE, tabValue));
  };
}


export function isEdit(value: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(DEALER.IS_EDIT, value));
  };
}

export function getDealerCreditRequest(id: any, sapCustomerId: any, loginId: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(DEALER.GET_DEALER_CREDIT_REQUEST_FETCHING));
    const response = await dealerService.getDealerCreditRequest(id, sapCustomerId, loginId);
    if (response.error) {
      dispatch(apiError(DEALER.GET_DEALER_CREDIT_REQUEST_ERROR, response));
      return response || [];
    } else {
      dispatch(apiSuccess(DEALER.GET_DEALER_CREDIT_REQUEST_SUCCESS, response));
      return response || [];
    }
  }
}

export function saveAdditionalCreditRequest(sapCustomerId: any, data: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(DEALER.SAVE_ADDITIONAL_CREDIT_REQUEST_FETCHING));
    const response = await dealerService.saveAdditionalCreditRequest(sapCustomerId, data);
    if (response.error) {
      dispatch(apiError(DEALER.SAVE_ADDITIONAL_CREDIT_REQUEST_ERROR, response));
      return response || [];
    } else {
      dispatch(apiSuccess(DEALER.SAVE_ADDITIONAL_CREDIT_REQUEST_SUCCESS, response));
      return response || [];
    }
  }
}

export function deleteBankStatementOfCreditRequest(documentId: any, data: any, requestId: any,) {
  return async (dispatch: any) => {
    dispatch(apiStart(DEALER.DELETE_BANK_STATEMENT_CREDIT_REQUEST_FETCHING));
    const response = await dealerService.deleteBankStatementOfCreditRequest(documentId, requestId, data);
    if (response.error) {
      dispatch(apiError(DEALER.DELETE_BANK_STATEMENT_CREDIT_REQUEST_ERROR, response));
      return response || [];
    } else {
      dispatch(apiSuccess(DEALER.DELETE_BANK_STATEMENT_CREDIT_REQUEST_SUCCESS, response));
      return response || [];
    }
  }
}

export function confirmDataFromDealer(data: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(DEALER.CONFIRM_DATA_FROM_DEALER_FETCHING));
    const response = await dealerService.confirmDataFromDealer(data);
    if (response.error) {
      dispatch(apiError(DEALER.CONFIRM_DATA_FROM_DEALER_ERROR, response));
      return response || [];
    } else {
      dispatch(apiSuccess(DEALER.CONFIRM_DATA_FROM_DEALER_SUCCESS, response));
      return response || [];
    }
  }
}

export function sendInvitationLink() {
  return async (dispatch: any) => {
    dispatch(apiStart(DEALER.SEND_INVITATION_LINK_FETCHING));
    const response = await dealerService.sendInvitationLink();
    if (response.error) {
      dispatch(apiError(DEALER.SEND_INVITATION_LINK_ERROR, response));
      return response || [];
    } else {
      dispatch(apiSuccess(DEALER.SEND_INVITATION_LINK_SUCCESS, response));
      return response || [];
    }
  }
}

export function generateB2bUserId(data: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(DEALER.GENERATE_B2B_USER_ID_FETCHING));
    const response = await dealerService.generateB2bUserId(data);
    if (response.error) {
      dispatch(apiError(DEALER.GENERATE_B2B_USER_ID_ERROR, response));
      return response || [];
    } else {
      dispatch(apiSuccess(DEALER.GENERATE_B2B_USER_ID_SUCCESS, response));
      return response || [];
    }
  }
}

export function viewCreditDealer(id: any, year: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(DEALER.GET_VIEWCREDIT_DEALER_FETCHING));
    const response = await dealerService.getViewTarget(id, year);
    if (response.error) {
      dispatch(apiError(DEALER.GET_VIEWCREDIT_DEALER_ERROR, response))
      return response || []
    } else {
      dispatch(apiSuccess(DEALER.GET_VIEWCREDIT_DEALER_SUCCESS, response))
      return response || []
    }
  }
}


export function getShipToParty(sapId: any, b2bId: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(DEALER.GET_SHIP_TO_PARTY_FETCHING));
    const response = await dealerService.getShipToParty(sapId, b2bId);
    if (response.error) {
      dispatch(apiError(DEALER.GET_SHIP_TO_PARTY_ERROR, response));
      return response || [];
    } else {
      dispatch(apiSuccess(DEALER.GET_SHIP_TO_PARTY_SUCCESS, response));
      return response || [];
    }
  };
}
