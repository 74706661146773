import { SUPER_ADMIN } from "../actions/actionsTypes";
import { IGlobal } from "../models/global.interface";
import { IActionBase } from "../models/root.interface";

const initialState: any = {
  getDefaultTargetDetails: null,
  updateDefaultTargetData: null,
  getCustomerDetails: null,
  getDealerListSetTargetDetails: null,
  updateCustomerTargetData: null,
  tierList: null,
  getRefreshButtonSAPResponse: null

};

function superAdminReducer(state: any = initialState, action: IActionBase): IGlobal {
  switch (action.type) {
    case SUPER_ADMIN.GET_DEFAULT_TARGET_DATA_FETCHING:
      return {
        ...state,
        getDefaultTargetDetails: { loading: true, data: null, err: null }
      };
    case SUPER_ADMIN.GET_DEFAULT_TARGET_DATA_SUCCESS:
      return {
        ...state,
        getDefaultTargetDetails: { loading: false, data: action.payload.data, err: null }
      };
    case SUPER_ADMIN.GET_DEFAULT_TARGET_DATA_ERROR:
      return {
        ...state,
        getDefaultTargetDetails: { loading: false, data: null, err: action.payload }
      };
    case SUPER_ADMIN.UPDATE_DEFAULT_TARGET_FETCHING:
      return {
        ...state,
        updateDefaultTargetData: { loading: true, data: null, err: null }
      };
    case SUPER_ADMIN.UPDATE_DEFAULT_TARGET_SUCCESS:
      return {
        ...state,
        updateDefaultTargetData: { loading: false, data: action.payload.data, err: null }
      };
    case SUPER_ADMIN.UPDATE_DEFAULT_TARGET_ERROR:
      return {
        ...state,
        updateDefaultTargetData: { loading: false, data: null, err: action.payload }
      };
    case SUPER_ADMIN.GET_SUPER_ADMIN_B2B_SUCCESS:
      return {
        ...state,
        getCustomerDetails: { loading: false, data: action.payload.data, err: null }
      };
    case SUPER_ADMIN.GET_SUPER_ADMI_B2B_ERROR:
      return {
        ...state,
        getCustomerDetails: { loading: false, data: null, err: action.payload }
      };
    case SUPER_ADMIN.UPDATE_SUPER_ADMIN_EDIT_DEALER_SUCCESS:
      return {
        ...state,
        updateCustomerDetails: { loading: false, data: action.payload.data, err: null }
      };
    case SUPER_ADMIN.UPDATE_SUPER_ADMIN_EDIT_DEALER_ERROR:
      return {
        ...state,
        getCustomerDetails: { loading: false, data: null, err: action.payload }
      };
    case SUPER_ADMIN.GET_DEALER_LIST_TARGET_DATA_FETCHING:
      return {
        ...state,
        getDealerListSetTargetDetails: { loading: true, data: null, err: null }
      };
    case SUPER_ADMIN.GET_TIER_SUCCESS:
      return {
        ...state,
        tierList: { loading: false, data: action.payload.data.data, err: null }
      };
    case SUPER_ADMIN.GET_TIER_ERROR:
      return {
        ...state,
        tierList: { loading: false, data: null, err: action.payload }
      };
    case SUPER_ADMIN.GET_TIER_FETCHING:
      return {
        ...state,
        tierList: { loading: true, data: null, err: null }
      };
    case SUPER_ADMIN.GET_DEALER_LIST_TARGET_DATA_SUCCESS:
      return {
        ...state,
        getDealerListSetTargetDetails: { loading: false, data: action.payload.data, err: null }
      };
    case SUPER_ADMIN.GET_DEALER_LIST_TARGET_DATA_ERROR:
      return {
        ...state,
        getDealerListSetTargetDetails: { loading: false, data: null, err: action.payload }
      };

    case SUPER_ADMIN.UPDATE_CUSTOMER_TARGET_DATA_FETCHING:
      return {
        ...state,
        updateCustomerTargetData: { loading: true, data: null, err: null }
      };
    case SUPER_ADMIN.UPDATE_CUSTOMER_TARGET_DATA_SUCCESS:
      return {
        ...state,
        updateCustomerTargetData: { loading: false, data: action.payload.data, err: null }
      };
    case SUPER_ADMIN.UPDATE_CUSTOMER_TARGET_DATA_ERROR:
      return {
        ...state,
        updateCustomerTargetData: { loading: false, data: null, err: action.payload }
      };

    case SUPER_ADMIN.GET_ONCLICK_REFRESH_SAP_RESPONSE_FETCHING:
      return {
        ...state,
        getRefreshButtonSAPResponse: { loading: true, data: null, err: null }
      };
    case SUPER_ADMIN.GET_ONCLICK_REFRESH_SAP_RESPONSE_SUCCESS:
      return {
        ...state,
        getRefreshButtonSAPResponse: { loading: false, data: action.payload.data, err: null }
      };
    case SUPER_ADMIN.GET_ONCLICK_REFRESH_SAP_RESPONSE_ERROR:
      return {
        ...state,
        getRefreshButtonSAPResponse: { loading: false, data: null, err: action.payload }
      };

    default:
      return state;
  }
};
export default superAdminReducer;
