import { getUniqueTimestamp } from "../common/components/util";
import { API_ENDPOINTS, PRODUCTS } from "../constants/apiConfig";
import { makeRequest } from "../middleware/axiosMiddleware"

export const productsService = {
    searchProducts
};

async function searchProducts(customerTierType: any, parts: any, enquirySessionId: any, firstName: any, sapCustomerId: any, b2bUserId: any, products: any, region?: any) {
    const type = customerTierType && customerTierType.toLowerCase();
    const apiPath = API_ENDPOINTS.CMS_ENDPOINT + PRODUCTS.API_PATH;
    let sessionId = !enquirySessionId ? `${getUniqueTimestamp()}`  : enquirySessionId && enquirySessionId.toString();
    const data = {
        id: parts,
        sapCustomerId: sapCustomerId && sapCustomerId !== '' ? sapCustomerId : sessionStorage.getItem('customerId'),
        b2bUserId: b2bUserId,
        region: region ? region : '',
        products,
        customerName: sessionStorage.getItem('customerName'),
        b2bUserName: firstName,
        enquirySessionId: sessionId && sessionId.includes('b2bLSR') && sessionId.length > 6 ? sessionId : sessionId !== null && sessionId.length > 5 ? `b2bLSR${sessionId}` : `b2bLSR${sessionId}`,
        webApplicationLoginId: sessionStorage.getItem('webApplicationLoginId'),
        customerType: type
    }
    const options = {
        path: apiPath,
        method: "POST",
        customHeaders: {
            "Lob-Id": "AUTO",
            "Channel-Id": "B2B",
            "Org-Id": 2001,
            "Accept-Language": "EN",
            "Paging-Info": `start-index=0|no-of-records=${parts && Array.isArray(parts) ? parts.length : '250'}`
        },
        bodyObj: data,
        formdata: true
    };
    return await makeRequest(options)
}
