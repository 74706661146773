/* eslint-disable  */
import React, { Fragment, Dispatch, useEffect, useState } from "react";
import "./leftMenu.css";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import HistoryIcon from "@material-ui/icons/History";
import logoicon from "../../assets/mainLogoBlue.png";
import tempKamLogo from "./afg.jpeg"
import smallLogoicon from "../../assets/smallLogoBlue.png";
import NoImage from "../../assets/NoImage.png";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import Tooltip from '@material-ui/core/Tooltip';
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import SettingsIcon from "@material-ui/icons/Settings";
import ListItemText from "@material-ui/core/ListItemText";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import DashboardOutlinedIcon from '@material-ui/icons/DashboardOutlined';
import EmojiEventsOutlinedIcon from "@material-ui/icons/EmojiEventsOutlined";
import CardGiftcard from "@material-ui/icons/CardGiftcard";
import CalendarToday from "@material-ui/icons/CalendarToday";
import PersonIcon from "@material-ui/icons/Person";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import { logout } from "../../store/actions/account.actions";
import { openSideMenu } from "../../store/actions/global.actions";
import { useDispatch, useSelector } from "react-redux";
import { signOut, useUserDispatch } from "../../context/UserContext.js";
import { useHistory } from "react-router-dom";
import LocalMallIcon from '@material-ui/icons/LocalMall';
import LocalMallOutlinedIcon from '@material-ui/icons/LocalMallOutlined';
import RoomIcon from '@material-ui/icons/Room';
import Typography from '@material-ui/core/Typography';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { useMsal } from "@azure/msal-react";
import { userAccessControl } from "./UserAccessControl";
import { RESTRICTED_ACCESS } from "../../constants/restrictedAccessConstants";
import LogoutDialog from "./logoutDialog";
import { getProfile, isSellingPrice } from "../../store/actions/profile.action";
import TermsAndConditionsDialog from "./TermsAndConditionsDialog";
import { userTypes } from "../../constants/userTypes";
import { useCallback } from "react";
import { logoutUser } from "../../store/actions/logon.actions";
import { getUniqueEnquirySessionId, resetEnquiryInfo } from "../../store/actions/createUpdateEnquiry.actions";
import { getUniqueTimestamp } from "../../common/components/util";
import _ from "lodash";
import { getTermsAndConditions } from "../../store/actions/termsAndConditions.action";
import { profileservice } from "../../services/profile.service";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import WarningIcon from "@material-ui/icons/Warning";
import { Badge, Button } from "@material-ui/core";
import { withStyles, Theme, createStyles, makeStyles, createTheme } from '@material-ui/core/styles';
import { getAllCreditRequestStatus, setTabValue } from "../../store/actions/dealer.action";
import FullscreenLoader from "../../common/components/FullscreenLoader";
import { EmojiEventsOutlined, PeopleAlt, ViewStreamOutlined } from "@material-ui/icons";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';
import creditcardicon from '../../assets/creditcard.png';
import SvgIcon from "@material-ui/core/SvgIcon"
import ChooseCustomerDialog from "../CreateEnquiry/ChooseCustomerDialog";
import { getCustomerDetailsByCustomerID, getFSECustomerList } from "../../store/actions/dealer.action";
import Snackbar from './../../common/components/Snackbar';
import { getAllNotifications, getAllOnbardingNotifications, markNotificationRead } from '../../store/actions/notifications.action';
import SupportDialog from "./SupportDialog";
import { get } from 'lodash';
import { getExpiredDocCount } from "../../store/actions/onboard.actions";
import { getDocumentExpiryStatus } from "../../store/actions/profile.action";
import DocumentExpiryStatus from "../Dealers/AddDealer/DocumentExpiryStatus";
import { addNotifications } from '../../store/actions/notifications.action';

const useStyles = makeStyles(() => ({
  icon: {
    float: "left",
    margin: "0 20px 20px 0",
    height: "100%",
    fill: "white",
  },
  toggle: {
    marginLeft: 5,
    width: "115px",
  },
  createButton: {
    backgroundColor: '#1A9FE0',
    width: 140,
    fontWeight: 800,
    textTransform: 'inherit',
    fontFamily: 'Nunito',
    fontSize: '0.688rem',
    color: '#FFFFFF',
    borderRadius: '2.5rem',
    height: 35,
    textAlign: 'center',
    marginRight: 8
  },
  closeButton: {
    backgroundColor: '#EAEAEA',
    width: 140,
    fontWeight: 800,
    textTransform: 'capitalize',
    fontFamily: 'Nunito',
    fontSize: '0.688rem',
    color: '#666666',
    borderRadius: '2.5rem',
    height: 35,
    textAlign: 'center',
    marginRight: 24
  }
}));

const theme = createTheme({
  palette: {
    primary: {
      main: '#1A9FE0'
    },
  },
});

const useStyles2 = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

const AntSwitch = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 150,
      height: 28,
      padding: 0,
    },
    switchBase: {
      padding: 5,
      color: '#FFFFFF',
    },
    thumb: {
      width: 17,
      height: 17,
    },
    track: {
      background: '#444444',
      opacity: '1 !important',
      borderRadius: '2rem',
      position: 'relative',
      '&:before, &:after': {
        display: 'inline-block',
        position: 'absolute',
        top: '50%',
        width: '50%',
        transform: 'translateY(-50%)',
        color: '#FFFFFF',
        textAlign: 'center',
        fontSize: 11,
        fontFamily: 'Nunito',
        fontWeight: 500
      },
      '&:before': {
        content: '"Purchase Price"',
        left: 7,
        opacity: 0,
      },
      '&:after': {
        content: '"Selling Price"',
        right: 6,
      },
    },
    checked: {
      '&$switchBase': {
        color: '#FFFFFF',
        transform: 'translateX(122px)',
      },
      '& $thumb': {
        backgroundColor: '#FFFFFF',
      },
      '& + $track': {
        '&:before': {
          opacity: 1,
        },
        '&:after': {
          opacity: 0,
        }
      },
    },
  }),
)(Switch);

const List = (props: any) => {
  return (
    <SvgIcon {...props}>
      <path d="M2,6V8H14V6H2M2,10V12H14V10H2M20.04,10.13C19.9,10.13 19.76,10.19 19.65,10.3L18.65,11.3L20.7,13.35L21.7,12.35C21.92,12.14 21.92,11.79 21.7,11.58L20.42,10.3C20.31,10.19 20.18,10.13 20.04,10.13M18.07,11.88L12,17.94V20H14.06L20.12,13.93L18.07,11.88M2,14V16H10V14H2Z"></path>
    </SvgIcon>
  )
}

const LeftMenu: React.FC = () => {
  const [showText, setShowText] = React.useState<any>(false);

  const classes = useStyles();
  const classes2 = useStyles2();
  const [currentUrl, setCurrentUrl] = React.useState<any>(window.location.href);
  const [menuItems, setMenuItems] = React.useState<any>([]);
  const [displayPrice, setDisplayPrice] = React.useState<any>([]);
  const [showAchievements, isShowAchievements] = React.useState<any>(false);
  const [showQuotation, setShowQuotation] = React.useState<any>(false);
  const [adminMenuItems, setAdminMenuItems] = React.useState<any>([]);
  const history = useHistory();
  const dispatch: Dispatch<any> = useDispatch();
  const userDispatch = useUserDispatch();
  const leftMenuVisibility: any = useSelector(
    (state: any) => state.global && state.global.sideMenuOpened
  );
  const { profileDetails, isSellingPriceData }: any = useSelector((state: any) => state.profileDetails);
  const { isAccessAchievement }: any = useSelector((state: any) => state.achievement);
  const isAdmin: any = useSelector((state: any) => state.global && state.global.isAdmin);
  const [openDeactivatedDialog, setOpenDeactivatedDialog] = React.useState<any>('');
  const [openTradepointAccessDialog, setOpenTradepointAccessDialog] = React.useState<any>('');
  const [openTAndCDialog, setOpenTAndCDialog] = React.useState(false);
  const [showDeactivateDialog, setshowDeactivateDialog] = React.useState<boolean>(false);
  const [expiredCount, setCount] = React.useState(0);
  const [openLogoutDialog, setOpenLogoutDialog] = React.useState(false);
  const sapCustomerId = _.get(profileDetails, "data.data.basicInfo.sapCustomerId", "");
  const [userConfirmation, setUserConfirmation] = React.useState<any>(false);
  const { instance } = useMsal();
  const [openChooseCustomerDialog, setOpenChooseCustomerDialog] = React.useState(false);
  const [iwsFseOpenChooseCustomerDialog, setIwsFseOpenChooseCustomerDialog] = React.useState(false);
  const [isOrderEnquiryCreated, setIsOrderEnquiryCreated] = React.useState<boolean>(false);
  const [successMessage, setSuccessMessage] = React.useState<any>(null);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [openErrorSnackbar, setOpenErrorSnackbar] = React.useState(false);
  const [loading, setLoading] = React.useState<any>(false);
  const [errorMessage, setErrorMessage] = React.useState<any>();
  const { getCustomerDetailsBySapId, getFSECustomerListData }: any = useSelector((state: any) => state.dealer);
  const [getAccInfodata, setGetAccInfodata] = React.useState<any>('');
  const [openCloseSupportDialog, setOpenCloseSupportDialog] = React.useState(false);
  const [assignedKAMs, setAssignedKAMs] = React.useState<any>([]);
  let userTypeByProfileAPI = sessionStorage.getItem('userType-Profile-API');
  const { getDocumentExpiryStatusData }: any = useSelector((state: any) => state.profileDetails);
  const [documentExpiryStatusData, setDocumentExpiryStatusData] = React.useState<any>([]);
  const [access, setAccess] = React.useState(true);
  const blockIntent = profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.customerInfo && profileDetails.data.data.customerInfo.blockIntent;
  const customerCurrentStatus = profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.customerInfo && profileDetails.data.data.customerInfo.status;

  useEffect(() => {
    if (!isAdmin) {
      userAccessControl(profileDetails);
    }
  }, [isAdmin, profileDetails])

  const restrictedSideMenuItems = sessionStorage.getItem('restrictFeat');
  const showCreateChangeEnquiry = !sessionStorage.getItem('restrictFeat')?.includes(RESTRICTED_ACCESS.CREATE_CHANGE_ENQUIRY);

  let sideMenuItems = [
    {
      featureCode: "ACHIEVEMENTS",
      icon: <EmojiEventsOutlined fontSize="inherit" />,
      name: "Achievements",
      route: "achievements",
    },
    {
      featureCode: "DASHBOARD",
      icon: <DashboardOutlinedIcon fontSize="inherit" />,
      name: "Dashboard",
      route: "dashboard",
    },
    {
      featureCode: "ENQ_LIST",
      icon: <AssignmentOutlinedIcon fontSize="inherit" />,
      name: "Order Enquiry",
      route: "orderenquiry",
    },
    {
      featureCode: "ORD_LIST",
      icon: <LocalMallOutlinedIcon fontSize="inherit" />,
      name: "Order History",
      route: "orderhistory",
    },
    // {
    //   featureCode: "ARC_LIST",
    //   icon: <LocalMallOutlinedIcon fontSize="inherit" />,
    //   name: "Archived Data",
    //   route: "archiveddata",
    // },
    {
      featureCode: "QUO_LIST",
      icon: <List fontSize="inherit" />,
      name: "Quotation",
      route: "quotation",
    },
    {
      featureCode: "LIST_FAVORIT",
      icon: <FavoriteBorderIcon fontSize="inherit" />,
      name: "Favorites",
      route: "favorites",
    },
    {
      featureCode: "FIND_SUPERSESSION",
      icon: <HistoryIcon fontSize="inherit" />,
      name: "Supersession",
      route: "partshistory",
    },
    {
      featureCode: "SPECIAL_OFFERS",
      icon: <LocalOfferIcon fontSize="inherit" />,
      name: "Special Offers",
      route: "specialofferdetail",
    },
    {
      featureCode: "ORG_SETTINGS",
      icon: <SettingsIcon fontSize="inherit" />,
      name: "Org. Setting",
      route: "orgsetting",
    },
    {
      featureCode: "MY_PROFILE",
      icon: <PersonIcon fontSize="inherit" />,
      name: "My Profile",
      route: "profile",
    },
    {
      featureCode: "LOGOUT",
      icon: <ExitToAppIcon fontSize="inherit" />,
      name: "Logout",
    },
  ];

  let workshopSideMenuItems = [
    {
      featureCode: "ACHIEVEMENTS",
      icon: <EmojiEventsOutlined fontSize="inherit" />,
      name: "Achievements",
      route: "achievements",
    },
    {
      featureCode: "DASHBOARD",
      icon: <DashboardOutlinedIcon fontSize="inherit" />,
      name: "Dashboard",
      route: "dashboard",
    },
    {
      featureCode: "ENQ_LIST",
      icon: <AssignmentOutlinedIcon fontSize="inherit" />,
      name: "Order Enquiry",
      route: "orderenquiry",
    },
    {
      featureCode: "ORD_LIST",
      icon: <LocalMallOutlinedIcon fontSize="inherit" />,
      name: "Order History",
      route: "orderhistory",
    },
    // {
    //   featureCode: "ARC_LIST",
    //   icon: <LocalMallOutlinedIcon fontSize="inherit" />,
    //   name: "Archived Data",
    //   route: "archiveddata",
    // },
    {
      featureCode: "CAT-LOG",
      icon: <img src="./catalogue.svg" style={{ fill: "#757575", height: "1.3rem", width: "1.3rem", opacity: "0.5" }} />,
      name: "Catalogue",
      route: "#",
    },
    {
      featureCode: "LIST_FAVORIT",
      icon: <FavoriteBorderIcon fontSize="inherit" />,
      name: "Favorites",
      route: "favorites",
    },
    {
      featureCode: "FIND_SUPERSESSION",
      icon: <HistoryIcon fontSize="inherit" />,
      name: "Supersession",
      route: "partshistory",
    },
    {
      featureCode: "SPECIAL_OFFERS",
      icon: <LocalOfferIcon fontSize="inherit" />,
      name: "Special Offers",
      route: "specialofferdetail",
    },
    {
      featureCode: "ORG_SETTINGS",
      icon: <SettingsIcon fontSize="inherit" />,
      name: "Org. Setting",
      route: "orgsetting",
    },
    {
      featureCode: "MY_PROFILE",
      icon: <PersonIcon fontSize="inherit" />,
      name: "My Profile",
      route: "profile",
    },
    {
      featureCode: "LOGOUT",
      icon: <ExitToAppIcon fontSize="inherit" />,
      name: "Logout",
    },
  ];

  let onBoardMenuItems = [
    {
      featureCode: "ORG_SETTINGS",
      icon: <SettingsIcon fontSize="inherit" />,
      name: "Complete Registration",
      route: "onboarding",
    },
    // {
    //   featureCode: "SHIP_TO_PARTY",
    //   icon: <RoomIcon fontSize="inherit" />,
    //   name: "Ship to Party",
    //   route: "shiptoparty",
    // },
    {
      featureCode: "MY_PROFILE",
      icon: <PersonIcon fontSize="inherit" />,
      name: "My Profile",
      route: "profile",
    },
    {
      featureCode: "LOGOUT",
      icon: <ExitToAppIcon fontSize="inherit" />,
      name: "Logout",
    },
  ];

  // USER ADDED BY KAM FOR CONFIRMATION OF INFO
  let onBoardMenuItemsForUserConfirmation = [
    {
      featureCode: "ORG_SETTINGS",
      icon: <SettingsIcon fontSize="inherit" />,
      name: "Complete Registration",
      route: "onboarding",
    },
    {
      featureCode: "MY_PROFILE",
      icon: <PersonIcon fontSize="inherit" />,
      name: "My Profile",
      route: "profile",
    },
    {
      featureCode: "LOGOUT",
      icon: <ExitToAppIcon fontSize="inherit" />,
      name: "Logout",
    },
  ];

  let creditRequestMenuItems = [

    {
      featureCode: "ORD_LIST",
      icon: <LocalMallIcon fontSize="inherit" />,
      name: "Credit Request",
      route: "creditteam",
    },
    {
      featureCode: "MY_PROFILE",
      icon: <PersonIcon fontSize="inherit" />,
      name: "My Profile",
      route: "adminprofile",
    },
    {
      featureCode: "LOGOUT",
      icon: <ExitToAppIcon fontSize="inherit" />,
      name: "Logout",
    },
  ];

  // SUPER ADMIN LEFT - MENU ITEMS 
  let superAdminMenuItems = [
    {
      featureCode: "PLAN_FEATURES",
      icon: <PersonOutlineIcon fontSize="inherit" />,
      name: "Plan Features",
      route: "planfeatures",
    },
    {
      featureCode: "SET_TARGET",
      icon: <img src="./settarget.svg" />,
      selectedIcon: <img src="./settargetinfo.svg" />,
      name: "Set Target",
      route: "settarget",
    },
    {
      featureCode: "DEALERS",
      icon: <PeopleAlt fontSize="inherit" />,
      name: "Customers",
      route: "superadmindealers",
    },
    {
      featureCode: "MY_PROFILE",
      icon: <PersonIcon fontSize="inherit" />,
      name: "My Profile",
      route: "adminprofile",
    },
    {
      featureCode: "LOGOUT",
      icon: <ExitToAppIcon fontSize="inherit" />,
      name: "Logout",
    },
  ];

  //Special Price Request Menuitems
  let specialPriceRequest = [
    {
      featureCode: "SPECIAL_PRICE_REQUEST",
      icon: <CardGiftcard fontSize="inherit" />,
      name: "Special Price Request",
      route: "specialpricerequest",
    },
    {
      featureCode: "MY_SCHEDULE",
      icon: <CalendarToday fontSize="inherit" />,
      name: "My Schedule",
      route: "myschedule",
    },
    {
      featureCode: "MY_PROFILE",
      icon: <PersonIcon fontSize="inherit" />,
      name: "My Profile",
      route: "adminprofile",
    },
    {
      featureCode: "LOGOUT",
      icon: <ExitToAppIcon fontSize="inherit" />,
      name: "Logout",
    },

  ];

  if (restrictedSideMenuItems) {
    sideMenuItems = sideMenuItems.filter((item: any) => {
      return !restrictedSideMenuItems?.includes(item.featureCode)
    })
    workshopSideMenuItems = workshopSideMenuItems.filter((item: any) => {
      return !restrictedSideMenuItems?.includes(item.featureCode)
    })
  }

  const adminSideMenuItems = [
    {
      icon: <DashboardOutlinedIcon fontSize="inherit" />,
      name: "Dashboard",
      route: "dashboard",
    },
    {
      icon: <PersonOutlineIcon fontSize="inherit" />,
      name: "Customers",
      route: "dealers",
    },
    {
      icon: <CardGiftcard fontSize="inherit" />,
      name: "Special Price Request",
      route: "specialpricekam",
    },
    {
      icon: <CreditCardIcon fontSize="inherit" />,
      name: "Credit Request",
      route: "creditlist",
    },
    {
      icon: <LocalOfferIcon fontSize="inherit" />,
      name: "Special Offers",
      route: "adminspecialoffers",
    },
    {
      icon: <PersonIcon fontSize="inherit" />,
      name: "My Profile",
      route: "adminprofile",
    },
    {
      icon: <ExitToAppIcon fontSize="inherit" />,
      name: "Logout",
    },
  ];

  const FSESideMenuItems = [
    {
      icon: <DashboardOutlinedIcon fontSize="inherit" />,
      name: "Dashboard",
      route: "dashboard",
    },
    {
      icon: <PersonOutlineIcon fontSize="inherit" />,
      name: "Customers",
      route: "dealers",
    },
    {
      featureCode: "ENQ_LIST",
      icon: <AssignmentOutlinedIcon fontSize="inherit" />,
      name: "Order Enquiry",
      route: "orderenquiry",
    },
    {
      featureCode: "ORD_LIST",
      icon: <LocalMallOutlinedIcon fontSize="inherit" />,
      name: "Order History",
      route: "orderhistory",
    },
    // {
    //   featureCode: "ARC_LIST",
    //   icon: <LocalMallOutlinedIcon fontSize="inherit" />,
    //   name: "Archived Data",
    //   route: "archiveddata",
    // },
    {
      featureCode: "QUO_LIST",
      icon: <List fontSize="inherit" />,
      name: "Quotation",
      route: "quotation",
    },
    {
      icon: <LocalOfferIcon fontSize="inherit" />,
      name: "Special Offers",
      route: "adminspecialoffers",
    },
    {
      icon: <PersonIcon fontSize="inherit" />,
      name: "My Profile",
      route: "adminprofile",
    },
    {
      icon: <ExitToAppIcon fontSize="inherit" />,
      name: "Logout",
    },
  ];



  const FSEWorkshopSideMenuItems = [
    {
      icon: <DashboardOutlinedIcon fontSize="inherit" />,
      name: "Dashboard",
      route: "dashboard",
    },
    {
      icon: <PersonOutlineIcon fontSize="inherit" />,
      name: "Customers",
      route: "dealers",
    },
    {
      featureCode: "ENQ_LIST",
      icon: <AssignmentOutlinedIcon fontSize="inherit" />,
      name: "Order Enquiry",
      route: "orderenquiry",
    },
    {
      featureCode: "ORD_LIST",
      icon: <LocalMallOutlinedIcon fontSize="inherit" />,
      name: "Order History",
      route: "orderhistory",
    },
    {
      featureCode: "CAT-LOG",
      icon: <img src="./catalogue.svg" style={{ fill: "#757575", height: "1.3rem", width: "1.3rem", opacity: "0.5" }} />,
      name: "Catalogue",
      route: "#",
    },
    {
      featureCode: "QUO_LIST",
      icon: <List fontSize="inherit" />,
      name: "Quotation",
      route: "quotation",
    },
    {
      icon: <LocalOfferIcon fontSize="inherit" />,
      name: "Special Offers",
      route: "adminspecialoffers",
    },
    {
      icon: <PersonIcon fontSize="inherit" />,
      name: "My Profile",
      route: "adminprofile",
    },
    {
      icon: <ExitToAppIcon fontSize="inherit" />,
      name: "Logout",
    },
  ];

  const callLogout = async () => {
    await dispatch(logoutUser());
    await dispatch(logout());
    signOut(userDispatch);
    instance.logoutRedirect({ postLogoutRedirectUri: "/" });
  }

  const loadProfileDetails = useCallback(() => {
    const b2cUid = sessionStorage.getItem('userId');
    const userType = sessionStorage.getItem('userType');

    if (b2cUid !== "undefined" && b2cUid !== null && userType !== 'undefined' && userType !== null) {
      dispatch(getProfile(b2cUid, userType));
    }
  }, [dispatch]);

  useEffect(() => {
    history.listen(() => {
      setCurrentUrl(window.location.href);
      if (window.location.href.includes('/oescatalogue')) {
        dispatch(openSideMenu(false));
      } else { dispatch(openSideMenu(true)); }
    })
  }, [history]);

  useEffect(() => {
    if (!profileDetails)
      loadProfileDetails();
    //  let data: any = profileservice.profileStatus(sapCustomerId)
    // setGetAccInfodata(data);
  }, [profileDetails, loadProfileDetails]);

  const getCount = async (sapCustomerId: any) => {
    let count: any = await dispatch(getExpiredDocCount(sapCustomerId));
    if (count && count.data) {
      setCount(count?.data?.count);
    }
  }

  const documentExpiryData = async () => {
    let expiryStatusData: any
    expiryStatusData = await dispatch(getDocumentExpiryStatus(sapCustomerId, true));
    if (expiryStatusData) {
      setDocumentExpiryStatusData(expiryStatusData);

      if (sessionStorage.getItem('userType-Profile-API') === 'Admin' && blockIntent !== false && customerCurrentStatus !== "Deactive") {
        if (sessionStorage.getItem('userType-Profile-API') === 'Admin' && expiryStatusData && expiryStatusData.data && expiryStatusData.data.messages && expiryStatusData.data.messages.length > 0) {
          setshowDeactivateDialog(true);
        }
      } else if (sessionStorage.getItem('userType-Profile-API') === 'Admin' && blockIntent === true && customerCurrentStatus === "Deactive" && expiryStatusData && expiryStatusData.data && expiryStatusData.data.messages && expiryStatusData.data.messages.length > 0) {
        setshowDeactivateDialog(true);
      }
    }
  }

  useEffect(() => {
    if (profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.basicInfo.userType !== 'guestAdmin') {
      documentExpiryData();
    }
    sessionStorage.setItem('customerId-Profile-API', profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.basicInfo && profileDetails.data.data.basicInfo.sapCustomerId);
    sessionStorage.setItem('webApplicationLoginId-Profile-API', profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.basicInfo && profileDetails.data.data.basicInfo.webApplicationLoginId);
    sessionStorage.setItem('userType-Profile-API', profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.basicInfo && profileDetails.data.data.basicInfo.userType);

    const data = {
      userType: "DEALER",
      sapCustomerId: sapCustomerId,
      webApplicationLoginId: sessionStorage.getItem('webApplicationLoginId-Profile-API')
    }

    if (profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.basicInfo && profileDetails.data.data.basicInfo.userType === 'Admin') {
      dispatch(addNotifications(data));
    }

    dispatch(getTermsAndConditions());
    if (profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.basicInfo && profileDetails.data.data.basicInfo.userType === 'Admin') {
      setTimeout(() => {
        dispatch(getAllNotifications(sessionStorage.getItem('customerId-Profile-API'), "dealer"));
      }, 1000)
      getCount(sapCustomerId);

      let data: any = profileservice.profileStatus(sapCustomerId)
      if (data.data && !data.data.isActive || profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.customerInfo && profileDetails.data.data.customerInfo.deactivationReason && profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.customerInfo && profileDetails.data.data.customerInfo.isActive === false) {
        setOpenDeactivatedDialog(profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.customerInfo && profileDetails.data.data.customerInfo.deactivationReason)
      }
      if (data.data && data.data.tradepointAccessToCustomer === false || profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.customerInfo && profileDetails.data.data.customerInfo.tradepointAccessToCustomer === false) {
        setOpenTradepointAccessDialog("Please contact Al-Futtaim Sales Support for more information.")
      }
    } else if (profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.basicInfo && profileDetails.data.data.basicInfo.userType === 'User') {
      dispatch(getAllNotifications(sessionStorage.getItem('webApplicationLoginId-Profile-API'), "user"));
      getCount(sapCustomerId);
      let data: any = profileservice.profileStatus(sapCustomerId)
      if (data.data && !data.data.isActive || profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.customerInfo && profileDetails.data.data.customerInfo.deactivationReason && profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.customerInfo && profileDetails.data.data.customerInfo.isActive === false) {
        setOpenDeactivatedDialog(profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.customerInfo && profileDetails.data.data.customerInfo.deactivationReason)
      }
      if (data.data && data.data.tradepointAccessToCustomer === false || profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.customerInfo && profileDetails.data.data.customerInfo.tradepointAccessToCustomer === false) {
        setOpenTradepointAccessDialog("Please contact Al-Futtaim Sales Support for more information.")
      }
    } else if (profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.basicInfo && profileDetails.data.data.basicInfo.userType === 'guestAdmin') {
      dispatch(getAllOnbardingNotifications(profileDetails.data.data.basicInfo.b2bUserId, "guestUser"));
    } else if (userType === 'kam' || sessionStorage.getItem('userType-Profile-API') === "KAM") {
      dispatch(getAllNotifications(sessionStorage.getItem('webApplicationLoginId-Profile-API'), "kam"));
    } else if (userType && userType.toLowerCase() === 'fse' || sessionStorage.getItem('userType-Profile-API') === "FSE") {
      dispatch(getAllNotifications(sessionStorage.getItem('webApplicationLoginId-Profile-API'), "fse"));
    } else if (userType === 'creditTeam' || sessionStorage.getItem('userType-Profile-API') === "CREDIT_CONTROL_MANAGER") {
      dispatch(getAllNotifications(sessionStorage.getItem('webApplicationLoginId-Profile-API'), "creditTeam"));
    } else if (userType === "pricing_team" || sessionStorage.getItem('userType-Profile-API') === "PRICING_TEAM") {
      dispatch(getAllNotifications(sessionStorage.getItem('webApplicationLoginId-Profile-API'), "pricing_team"));
    }
  }, []);

  let dealerCountry;
  let dealerLogo;
  let customerType: any;
  let companyName;
  let userName;
  let userType: any;
  let customerTypeAccess: any;
  let region;
  let isKamOnboarded: any;
  let percentageForSellingPrice: any;
  let markupPercentage: any;
  let featureAccessibility: any;
  let customerTierType: any;
  let uType = sessionStorage.getItem('userType');

  // dealerCountry = (profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.customerInfo && profileDetails.data.data.customerInfo.addresses && profileDetails.data.data.customerInfo.addresses.length > 0 && profileDetails.data.data.customerInfo.addresses.split(',')[0]) || "";
  dealerLogo = (profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.customerInfo && profileDetails.data.data.customerInfo.logo) || "";
  customerType = (profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.customerInfo && profileDetails.data.data.customerInfo.customerType) || "";
  companyName = (profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.customerInfo && profileDetails.data.data.customerInfo.companyName) || "";
  userName = (profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.basicInfo.firstName + " " + profileDetails.data.data.basicInfo.lastName) || "";
  region = (profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.basicInfo.region) || '';
  userType = (profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.basicInfo.userType) || "";
  isKamOnboarded = (profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.basicInfo.isAddedByKam) || false;
  percentageForSellingPrice = (profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.customerInfo && profileDetails.data.data.customerInfo.percentageForSellingPriceApply) || false;
  markupPercentage = (profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.customerInfo && profileDetails.data.data.customerInfo.markupPercentage) || false;
  customerTierType = (profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.customerInfo && profileDetails.data.data.customerInfo.customerTierType);
  featureAccessibility = (profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.accessParameter && profileDetails.data.data.accessParameter.featureAccessibility);

  if (sessionStorage.getItem('userType') === 'FSE' || userType === "FSE") {
    let filteredTypes: any = profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.basicInfo.customerTypeAccess && profileDetails.data.data.basicInfo.customerTypeAccess.length > 0 && profileDetails.data.data.basicInfo.customerTypeAccess.filter((type: any) => type.toLowerCase() !== "all");

    let formattedTypes = filteredTypes && filteredTypes.length > 0 && filteredTypes.map((type: any) => {
      if (type.toLowerCase() === "intbusiness") {
        return "Int. Business";
      } return type.replace(/\b\w/g, (char: string) => char.toUpperCase());
    });

    customerTypeAccess = formattedTypes && formattedTypes.join(', ');

    if (profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.basicInfo.customerTypeAccess && profileDetails.data.data.basicInfo.customerTypeAccess.length > 0 && profileDetails.data.data.basicInfo.customerTypeAccess && profileDetails.data.data.basicInfo.customerTypeAccess.includes("workshop")) {
      customerTierType = 'Workshop'
    }
  }

  useEffect(() => {
    if (currentUrl) {
      if ((((currentUrl.includes('onboarding')) || (currentUrl.includes('profile') && menuItems && menuItems.length === 3)) && !isKamOnboarded)) {
        setMenuItems(onBoardMenuItems);
      } else if (((currentUrl.includes('onboarding')) || (currentUrl.includes('profile') && menuItems && menuItems.length === 3)) && isKamOnboarded) {
        setMenuItems(onBoardMenuItemsForUserConfirmation);
      } else if ((currentUrl.includes('creditteam')) || (currentUrl.includes('creditteamdetails')) || (currentUrl.includes('profile') && adminMenuItems && adminMenuItems.length === 3)) {
        setAdminMenuItems(creditRequestMenuItems);
      } else if (sessionStorage.getItem('userType') === 'FSE' && customerTierType === 'Workshop') {
        setAdminMenuItems(FSEWorkshopSideMenuItems);
      } else if (sessionStorage.getItem('userType') === 'FSE' && customerTierType !== 'Workshop') {
        setAdminMenuItems(FSESideMenuItems);
      } else if ((currentUrl.includes('specialpricerequest')) || (currentUrl.includes('myschedule')) || currentUrl.includes("/viewspecialprice") && currentUrl.includes("/viewspecialpricekam") || (currentUrl.includes('/adminprofile') && adminMenuItems && adminMenuItems.length === 4 && sessionStorage.getItem('userType') !== 'FSE')) {
        setAdminMenuItems(specialPriceRequest);
      } else if ((currentUrl.includes('planfeatures')) || (currentUrl.includes('settarget')) || (currentUrl.includes('superadmindealers')) || (currentUrl.includes('profile') && adminMenuItems && adminMenuItems.length === 5 && sessionStorage.getItem('userType') !== 'FSE')) {
        setAdminMenuItems(superAdminMenuItems);
      } else if ((customerTierType === 'Dealer' || !customerTierType) && userType !== "guestAdmin") {
        setMenuItems(sideMenuItems);
      } else if (customerTierType === 'Workshop' && userType !== "guestAdmin") {
        setMenuItems(workshopSideMenuItems);
      } else if (customerTierType === 'Government' && userType !== "guestAdmin") {
        setMenuItems(sideMenuItems)
      } else if (customerTierType === "Fleet" && userType !== "guestAdmin") {
        setMenuItems(sideMenuItems)
      } else if (customerTierType === "Int. Business" && userType !== "guestAdmin") {
        setMenuItems(sideMenuItems)
      }
    }
  }, [currentUrl, userType, customerTierType]);

  useEffect(() => {
    if (customerTierType === 'Workshop' || customerTierType === "Fleet" || customerTierType === "Government") {
      isShowAchievements(false)
    } else {
      if (isAccessAchievement && isAccessAchievement.data && isAccessAchievement.data.accessToViewAchievementModule && isAccessAchievement.data.customerType === 'dealer') {
        isShowAchievements(true)
      } else {
        isShowAchievements(false);
      }
      if (isAccessAchievement && isAccessAchievement.data && isAccessAchievement.data.isTierFound && isAccessAchievement.data.customerType === 'dealer') {
        sessionStorage.setItem('currentTier', isAccessAchievement.data.currentTier)
      }
    }

    if (customerTierType === "Fleet" || customerTierType === "Government" || sessionStorage.getItem('userType') === 'FSE') {
      setShowQuotation(true);
    } else {
      setShowQuotation(false);
    }
  }, [isAccessAchievement, customerTierType]);

  useEffect(() => {
    if (featureAccessibility && featureAccessibility.length > 0) {
      isDisplaySellingPrice(featureAccessibility);
    }
  }, [featureAccessibility]);

  useEffect(() => {
    setAssignedKAMs(get(profileDetails, 'data.data.basicInfo.assignedKamAndFse', []));
  }, [profileDetails]);

  useEffect(() => {
    if (isAdmin) {
      if (userType === "CREDIT_CONTROL_MANAGER") {
        setAdminMenuItems(creditRequestMenuItems);
      } else if (userType === "AFG_SUPER_ADMIN") {
        setAdminMenuItems(superAdminMenuItems);
      } else if (userType === "PRICING_TEAM") {
        setAdminMenuItems(specialPriceRequest)
      } else if (sessionStorage.getItem('userType') === 'FSE' || userType === "FSE") {
        setAdminMenuItems(customerTierType === 'Workshop' ? FSEWorkshopSideMenuItems : FSESideMenuItems)
      } else {
        setAdminMenuItems(adminSideMenuItems);
      }
    }
  }, [userType, isAdmin]);

  const handleopenClose = (open?: any) => {
    setOpenLogoutDialog(open ? true : false);
  };

  const handleOpenCloseTAndC = (open?: any) => {
    setOpenTAndCDialog(open ? true : false);
  };

  const handleopenCloseSnackbar = (open?: any) => {
    setOpenSnackbar(open ? true : false);
  };

  const handleopenCloseErrorSnackbar = (open?: any) => {
    setOpenErrorSnackbar(open ? true : false);
  };

  const isDisplaySellingPrice = (accessParam: any) => {
    if (accessParam && accessParam.length > 0) {
      let data = accessParam.filter((param: any) => param.featureCode === 'DISPLAY_SELLING_PRICE');
      if (data && data.length > 0 && data[0].value === true) {
        dispatch(isSellingPrice(false))
      }
      setDisplayPrice(data);
    }
  }

  const isAccessToQuotation = () => {
    if ((customerTierType && customerTierType.toLowerCase() === "fleet") || (customerTierType && customerTierType.toLowerCase() === "government") || (sessionStorage.getItem('userType') == 'FSE')) {
      return true
    } else {
      return false
    }
  }

  const openCloseChooseCustomer = () => {
    setOpenChooseCustomerDialog(false);
  }

  const handleChooseCustomer = () => {
    setOpenChooseCustomerDialog(true);
  }


  const openCloseChooseCustomerIWSFSEDialog = () => {
    setIwsFseOpenChooseCustomerDialog(false);
  }

  const handleChooseCustomerIWSFSEDialog = () => {
    setIwsFseOpenChooseCustomerDialog(true);
  }

  const ENQ_LIST = !sessionStorage.getItem('restrictFeat')?.includes(RESTRICTED_ACCESS.ENQ_LIST);
  return (
    <Fragment>
      {loading && <FullscreenLoader />}
      {(menuItems && menuItems.length === 0) && (adminMenuItems && adminMenuItems.length === 0) && <FullscreenLoader />}
      {
        <div className={leftMenuVisibility ? "leftMenuGlobal" : "collasped-menu"}>
          <Drawer
            variant={leftMenuVisibility ? "persistent" : "temporary"}
            anchor={"left"}
            open={leftMenuVisibility}
          >
            <div className="px-2 my-3 align-items-center">
              <IconButton
                onClick={() => {
                  dispatch(openSideMenu(false));
                }}
              >
                <MenuIcon />
              </IconButton>
              <img src={logoicon} onClick={() => {
                let userRole: any = _.get(profileDetails, "data.data.basicInfo.userType", '')
                if (userRole && userRole.toLowerCase() == "guestadmin") {
                  history.push('/onboarding')
                } else if (userRole === "CREDIT_CONTROL_MANAGER") {
                  history.push('/creditteam')
                } else if (userRole === "AFG_SUPER_ADMIN") {
                  history.push('/planfeatures')
                } else if (userRole === "PRICING_TEAM") {
                  history.push("/specialpricerequest")
                }
                else {
                  history.push('/dashboard')
                }
              }} alt="Logo" className="h-40 ml-2 " style={{ cursor: 'pointer' }} />
            </div>
            <div className="media bg-primary p-4">
              <img className="mr-3 rounded-circle hw-64" src={(userType === userTypes.kam || userType === userTypes.fse || userType === "AFG_SUPER_ADMIN" || userType === "PRICING_TEAM") ? tempKamLogo : dealerLogo ? dealerLogo : NoImage} alt="Logo" />
              <div className="media-body">
                {userType === userTypes.kam || userType === userTypes.fse || userType === "AFG_SUPER_ADMIN" ?
                  <>
                    <p className="clientNameSidebar d-flex align-items-center ml-1">
                      &nbsp;{userName}
                    </p>
                    <p className="clientNameSidebar d-flex align-items-center ml-1">
                      {Array.isArray(region) ? (
                        <>
                          &nbsp;{region && Array.isArray(region) && region.length === 0 ? '' :
                            region && Array.isArray(region) && region.length > 0 && region.map((value: any) => value).join(", ")
                          }
                        </>
                      ) : (
                        <>
                          {region && typeof region === 'string' ? region : ''}
                        </>)}
                    </p>
                    <p className="companyLocationSidebar ml-2 mb-1">{userType === "AFG_SUPER_ADMIN" ? 'Super Admin' : userType === 'FSE' ? `FSE / Sales advisor (${customerTypeAccess}) ` : userType}</p> </> : <><p className="companyNameSidebar ml-1 pl-1 mb-1">{companyName}</p>
                    <p className="companyLocationSidebar ml-2 mb-1">{userType === 'CREDIT_CONTROL_MANAGER' ? 'Credit Control Manager' : userType === "AFG_SUPER_ADMIN" ? 'Super Admin' : userType} {dealerCountry}</p> <p className="clientNameSidebar d-flex align-items-center ml-1">
                      <PersonOutlineIcon fontSize="inherit" />
                      &nbsp;{userName}
                    </p>
                    {/* <p className="companyLocationSidebar ml-2 mb-1">{userType === 'SUPER_ADMIN' ? 'Super Admin' : userType} {dealerCountry}</p> <p className="clientNameSidebar d-flex align-items-center ml-1">
                    <PersonOutlineIcon fontSize="inherit" />
                    &nbsp;{userName}
                  </p> */}
                  </>}
              </div>
            </div>
            <Dialog
              open={openDeactivatedDialog ? true : false}
              onClose={() => {
                setOpenDeactivatedDialog('')
                callLogout()
              }}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              fullWidth={true}>
              <Snackbar
                open={openSnackbar}
                handleopenClose={() => {
                  handleopenCloseSnackbar(false)
                }} text={successMessage} />
              <Snackbar
                open={openErrorSnackbar}
                type="error"
                handleopenClose={() => {
                  handleopenCloseErrorSnackbar(false)
                }} text={errorMessage} />
              <DialogContent>
                <div className={classes.icon}>
                  <WarningIcon className="delete-warning" fontSize="small" />
                </div>
                <div>
                  <DialogContentText id="alert-dialog-description">
                    <div className="mt-2">
                      <h5 className="submit-dialog-content">Your account has been deactivated</h5>
                      <p className="mt-3 dealer-table-subcontent-reason dealer-table-content text-red">Reason: {openDeactivatedDialog}</p>
                    </div>
                  </DialogContentText>
                </div>
              </DialogContent>
              <DialogActions className="mt-3 mb-3 justify-content-end">
                <Button variant="contained"
                  color="secondary" className={`createFavourite ${classes.createButton}`}
                  onClick={() => {
                    setOpenDeactivatedDialog('')
                    callLogout()
                  }}>Logout
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog
              open={openTradepointAccessDialog ? true : false}
              onClose={() => {
                setOpenTradepointAccessDialog('')
                callLogout()
              }}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              fullWidth={true}>

              <DialogContent>
                <div className={classes.icon}>
                  <WarningIcon className="delete-warning" fontSize="small" />
                </div>
                <div>
                  <DialogContentText id="alert-dialog-description">
                    <div className="mt-2">
                      <h5 className="submit-dialog-content">We are sorry that you no longer have access to the portal.</h5>
                      <p>{openTradepointAccessDialog}</p>
                    </div>
                  </DialogContentText>
                </div>
              </DialogContent>
              <DialogActions className="mt-3 mb-3 justify-content-end">
                <Button variant="contained"
                  color="secondary" className={`createFavourite ${classes.createButton}`}
                  onClick={() => {
                    setOpenTradepointAccessDialog('')
                    callLogout()
                  }}>Logout
                </Button>
              </DialogActions>
            </Dialog>
            <div className="w-100 p-3 left-nav">
              {!isAdmin ? menuItems.map((item: any, index: any) => {
                return (
                  <>
                    {
                      item.route === 'achievements' ? (
                        <>
                          {showAchievements && (
                            <ListItem
                              disabled={blockIntent && item.route !== 'orgsetting' && item.name !== 'Logout' ? true : false}
                              onClick={async () => {
                                // alert(item.route)
                                if (item.name.toLowerCase() === "logout") {
                                  handleopenClose(true);
                                } else {
                                  if (item.route) {
                                    // if (userType.toLowerCase().includes('admin') || userType.toLowerCase().includes('user')) {
                                    //   let data = await profileservice.profileStatus(sapCustomerId)
                                    //   if (data.data && !data.data.isActive) {
                                    //     setOpenDeactivatedDialog(data.data.deactivationReason)
                                    //   }
                                    //   if (data.data && data.data.tradepointAccessToCustomer === false) {
                                    //     setOpenTradepointAccessDialog("Please contact Al-Futtaim Sales Support for more information.")
                                    //   }
                                    // }
                                    history.push(item.route);
                                    if (item.route.includes('/oescatalogue')) {
                                      dispatch(openSideMenu(false));
                                    } else { dispatch(openSideMenu(true)); }
                                  }
                                }
                                setCurrentUrl(window.location.href);
                              }}
                              button
                              key={item.name}
                              className={currentUrl && currentUrl.includes(item.route) ? "active-menu" : ""}
                            >
                              <ListItemIcon>{item.icon}</ListItemIcon>
                              <ListItemText primary={item.name} />
                              {item.route === 'orderenquiry' && showCreateChangeEnquiry &&
                                <ListItemIcon>
                                  <IconButton className="p-1 ml-auto toast-header" edge="end" aria-label="add" onClick={(e) => {
                                    dispatch(resetEnquiryInfo());
                                    e.stopPropagation(); dispatch(getUniqueEnquirySessionId(getUniqueTimestamp()));
                                    sessionStorage.setItem('Order-log-UnReadCount', "")
                                    sessionStorage.setItem('Order-log-ReadCount', "")
                                    sessionStorage.setItem('reset-the-orderlog-count', "true");
                                    sessionStorage.setItem('subEnquiryId', "");
                                    sessionStorage.setItem("enquiry-current-status", "");
                                    sessionStorage.setItem('isCopyandCreate', "");
                                    sessionStorage.setItem("partsData", "")
                                    history.push('/createenquiry');
                                  }}>
                                    <AddCircleIcon fontSize="medium" />
                                  </IconButton>
                                </ListItemIcon>
                              }
                              {item.route === 'orgsetting' && expiredCount > 0 && profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.basicInfo && profileDetails.data.data.basicInfo.userType === "Admin" &&
                                <Badge badgeContent={expiredCount} color="error">
                                </Badge>
                              }
                            </ListItem>
                          )}
                        </>
                      ) : item.route === "#" ?
                        (<>
                          <div className={classes2.root}>
                            <Accordion className="border-accordion">
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                style={{ borderBottom: "none" }}
                                className="p-0 m-0"
                              >
                                <Typography className={classes2.heading}>
                                  <ListItem
                                    disabled={blockIntent && item.route !== 'orgsetting' && item.name !== 'Logout' ? true : false}
                                    onClick={async () => {
                                      // alert(item.route)
                                      if (item.name.toLowerCase() === "logout") {
                                        handleopenClose(true);
                                      } else {
                                        if (item.route) {
                                          if (userType.toLowerCase().includes('admin') || userType.toLowerCase().includes('user')) {
                                            let data = await profileservice.profileStatus(sapCustomerId)
                                            if (data.data && !data.data.isActive) {
                                              setOpenDeactivatedDialog(data.data.deactivationReason)
                                            }
                                            if (data.data && data.data.tradepointAccessToCustomer === false) {
                                              setOpenTradepointAccessDialog("Please contact Al-Futtaim Sales Support for more information.")
                                            }
                                          }
                                          history.push(item.route);
                                        }
                                      }
                                      setCurrentUrl(window.location.href);
                                    }}
                                    button
                                    key={item.name}
                                    className={currentUrl && currentUrl.includes(item.route) ? "active-menu" : ""}
                                  >
                                    <ListItemIcon className="">{item.icon}</ListItemIcon>
                                    <ListItemText primary={item.name} />
                                    {item.route === 'orderenquiry' && showCreateChangeEnquiry &&
                                      <ListItemIcon>
                                        <IconButton className="ml-auto toast-header" edge="end" aria-label="add" onClick={(e) => {
                                          dispatch(resetEnquiryInfo());
                                          e.stopPropagation(); dispatch(getUniqueEnquirySessionId(getUniqueTimestamp()));
                                          sessionStorage.setItem('Order-log-UnReadCount', "")
                                          sessionStorage.setItem('Order-log-ReadCount', "")
                                          sessionStorage.setItem('reset-the-orderlog-count', "true");
                                          sessionStorage.setItem('subEnquiryId', "");
                                          sessionStorage.setItem("enquiry-current-status", "");
                                          sessionStorage.setItem('isCopyandCreate', "");
                                          sessionStorage.setItem("partsData", "")
                                          history.push('/createenquiry');
                                        }}>
                                          <AddCircleIcon fontSize="medium" />
                                        </IconButton>
                                      </ListItemIcon>
                                    }
                                    {item.route === 'orgsetting' && expiredCount > 0 && profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.basicInfo && profileDetails.data.data.basicInfo.userType === "Admin" &&
                                      <Badge badgeContent={expiredCount} color="error">
                                      </Badge>
                                    }
                                  </ListItem>

                                  {/* <h5>Hello world</h5> */}
                                </Typography>
                              </AccordionSummary>
                              <AccordionDetails className="pt-0 mt-0">
                                <Typography className="d-flex flex-row pl-4" onClick={() => { history.push("/oescatalogue"); if (window.location.href.includes('/oescatalogue')) { dispatch(openSideMenu(false)); } else { dispatch(openSideMenu(true)); } }}>
                                  <>
                                    <p style={{ color: "black", fontWeight: 500 }} className="pl-3"><ChevronRightRoundedIcon /></p>
                                    <p className="pl-3 pt-1 font-catalogue" style={{ fontSize: "12px" }}>OES Catalogue</p>
                                  </>
                                </Typography>
                              </AccordionDetails>
                            </Accordion>
                          </div>
                        </>)
                        : item.route === "quotation" ? (
                          isAccessToQuotation() && <>
                            < ListItem
                              disabled={blockIntent && item.route !== 'orgsetting' && item.name !== 'Logout' ? true : false}
                              onClick={async () => {
                                // alert(item.route)
                                if (item.name.toLowerCase() === "logout") {
                                  handleopenClose(true);
                                } else {
                                  if (item.route) {
                                    // if (userType.toLowerCase().includes('admin') || userType.toLowerCase().includes('user')) {
                                    //   let data = await profileservice.profileStatus(sapCustomerId)
                                    //   if (data.data && !data.data.isActive) {
                                    //     setOpenDeactivatedDialog(data.data.deactivationReason)
                                    //   }
                                    //   if (data.data && data.data.tradepointAccessToCustomer === false) {
                                    //     setOpenTradepointAccessDialog("Please contact Al-Futtaim Sales Support for more information.")
                                    //   }
                                    // }
                                    history.push(item.route);
                                    if (item.route.includes('/oescatalogue')) {
                                      dispatch(openSideMenu(false));
                                    } else { dispatch(openSideMenu(true)); }
                                  }
                                }
                                setCurrentUrl(window.location.href);
                              }}
                              button
                              key={item.name}
                              className={currentUrl && currentUrl.includes(item.route) ? "active-menu" : ""}
                            >
                              <ListItemIcon>{item.icon}</ListItemIcon>
                              <ListItemText primary={item.name} />
                              {item.route === 'orderenquiry' && showCreateChangeEnquiry &&
                                <ListItemIcon>
                                  <IconButton className="p-1 ml-auto toast-header" edge="end" aria-label="add" onClick={(e) => {
                                    dispatch(resetEnquiryInfo());
                                    e.stopPropagation(); dispatch(getUniqueEnquirySessionId(getUniqueTimestamp()));
                                    sessionStorage.setItem('Order-log-UnReadCount', "")
                                    sessionStorage.setItem('Order-log-ReadCount', "")
                                    sessionStorage.setItem('reset-the-orderlog-count', "true");
                                    sessionStorage.setItem('subEnquiryId', "");
                                    sessionStorage.setItem('isCopyandCreate', "");
                                    sessionStorage.setItem("partsData", "")
                                    sessionStorage.setItem("enquiry-current-status", "");
                                    history.push('/createenquiry');
                                  }}>
                                    <AddCircleIcon fontSize="medium" />
                                  </IconButton>
                                </ListItemIcon>
                              }
                              {item.route === 'orgsetting' && expiredCount > 0 && profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.basicInfo && profileDetails.data.data.basicInfo.userType === "Admin" &&
                                <Badge badgeContent={expiredCount} color="error">
                                </Badge>
                              }
                            </ListItem>
                          </>
                        ) : (
                          (
                            < ListItem
                              disabled={blockIntent && item.route !== 'orgsetting' && item.name !== 'Logout' ? true : false}
                              onClick={async () => {
                                // alert(item.route)
                                if (item.name.toLowerCase() === "logout") {
                                  handleopenClose(true);
                                } else {
                                  if (item.route) {
                                    // if (userType.toLowerCase().includes('admin') || userType.toLowerCase().includes('user')) {
                                    //   let data = await profileservice.profileStatus(sapCustomerId)
                                    //   if (data.data && !data.data.isActive) {
                                    //     setOpenDeactivatedDialog(data.data.deactivationReason)
                                    //   }
                                    //   if (data.data && data.data.tradepointAccessToCustomer === false) {
                                    //     setOpenTradepointAccessDialog("Please contact Al-Futtaim Sales Support for more information.")
                                    //   }
                                    // }
                                    history.push(item.route);
                                    if (item.route.includes('/oescatalogue')) {
                                      dispatch(openSideMenu(false));
                                    } else { dispatch(openSideMenu(true)); }
                                  }
                                }
                                setCurrentUrl(window.location.href);
                              }}
                              button
                              key={item.name}
                              className={currentUrl && currentUrl.includes(item.route) ? "active-menu" : ""}
                            >
                              <ListItemIcon>{item.icon}</ListItemIcon>
                              <ListItemText primary={item.name} />
                              {item.route === 'orderenquiry' && showCreateChangeEnquiry &&
                                <ListItemIcon>
                                  <IconButton className="p-1 ml-auto toast-header" edge="end" aria-label="add" onClick={(e) => {
                                    dispatch(resetEnquiryInfo());
                                    let enquiryStatusForInternalId: any = sessionStorage.getItem('Enquiry-Status')
                                    e.stopPropagation(); dispatch(getUniqueEnquirySessionId(getUniqueTimestamp()));
                                    sessionStorage.setItem('Order-log-UnReadCount', "")
                                    sessionStorage.setItem('Order-log-ReadCount', "")
                                    sessionStorage.setItem('reset-the-orderlog-count', "true");
                                    sessionStorage.setItem("enquiry-current-status", "");
                                    sessionStorage.setItem('isCopyandCreate', "");
                                    sessionStorage.setItem("partsData", "")
                                    sessionStorage.setItem('subEnquiryId', "");
                                    history.push('/createenquiry');
                                  }}>
                                    <AddCircleIcon fontSize="medium" />
                                  </IconButton>
                                </ListItemIcon>
                              }
                              {item.route === 'orgsetting' && expiredCount > 0 && profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.basicInfo && profileDetails.data.data.basicInfo.userType === "Admin" &&
                                <Badge badgeContent={expiredCount} color="error">
                                </Badge>
                              }
                            </ListItem>
                          )
                        )
                    }
                  </>
                );
              }) : adminMenuItems.map((item: any, index: any) => {

                return (
                  <>
                    {item.route === "#" ?
                      (<>
                        <div className={classes2.root}>
                          <Accordion className="border-accordion">
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                              style={{ borderBottom: "none" }}
                              className="p-0 m-0"
                            >
                              <Typography className={classes2.heading}>
                                <ListItem
                                  onClick={async () => {
                                    // alert(item.route)
                                    if (item.name.toLowerCase() === "logout") {
                                      handleopenClose(true);
                                    } else {
                                      if (item.route) {
                                        if (userType.toLowerCase().includes('admin') || userType.toLowerCase().includes('user') || userType.toLowerCase().includes('fse')) {
                                          let data = await profileservice.profileStatus(sapCustomerId)
                                          if (data.data && !data.data.isActive) {
                                            setOpenDeactivatedDialog(data.data.deactivationReason)
                                          }
                                          if (data.data && data.data.tradepointAccessToCustomer === false) {
                                            setOpenTradepointAccessDialog("Please contact Al-Futtaim Sales Support for more information.")
                                          }
                                        }
                                        history.push(item.route);
                                      }
                                    }
                                    setCurrentUrl(window.location.href);
                                  }}
                                  button
                                  key={item.name}
                                  className={currentUrl && currentUrl.includes(item.route) ? "active-menu" : ""}
                                >
                                  <ListItemIcon className="">{item.icon}</ListItemIcon>
                                  <ListItemText primary={item.name} />
                                  {item.route === 'orderenquiry' && showCreateChangeEnquiry &&
                                    <ListItemIcon>
                                      <IconButton className="ml-auto toast-header" edge="end" aria-label="add" onClick={(e) => {
                                        dispatch(resetEnquiryInfo());
                                        e.stopPropagation(); dispatch(getUniqueEnquirySessionId(getUniqueTimestamp()));
                                        sessionStorage.setItem('Order-log-UnReadCount', "")
                                        sessionStorage.setItem('subEnquiryId', "");
                                        sessionStorage.setItem('Order-log-ReadCount', "")
                                        sessionStorage.setItem('reset-the-orderlog-count', "true");
                                        sessionStorage.setItem("enquiry-current-status", "");
                                        sessionStorage.setItem('isCopyandCreate', "");
                                        sessionStorage.setItem("partsData", "")
                                        history.push('/createenquiry');
                                      }}>
                                        <AddCircleIcon fontSize="medium" />
                                      </IconButton>
                                    </ListItemIcon>
                                  }
                                </ListItem>

                                {/* <h5>Hello world</h5> */}
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails className="pt-0 mt-0">
                              <Typography className="d-flex flex-row pl-4" onClick={() => { history.push("/oescatalogue"); if (window.location.href.includes('/oescatalogue')) { dispatch(openSideMenu(false)); } else { dispatch(openSideMenu(true)); } }}>
                                <>
                                  <p style={{ color: "black", fontWeight: 500 }} className="pl-3"><ChevronRightRoundedIcon /></p>
                                  <p className="pl-3 pt-1 font-catalogue" style={{ fontSize: "12px" }}>OES Catalogue</p>
                                </>
                              </Typography>
                            </AccordionDetails>
                          </Accordion>
                        </div>
                      </>)
                      : <ListItem
                        onClick={async () => {
                          if (item.name.toLowerCase() === "logout") {
                            handleopenClose(true);
                          } else {
                            if (item.route) {
                              // if (item.route === "creditlist") {
                              //   dispatch(getAllCreditRequestStatus('kam'));
                              // }
                              history.push(item.route);
                            }
                          }
                          setCurrentUrl(window.location.href);
                          dispatch(setTabValue(0));
                        }}
                        button
                        key={item.name}
                        className={currentUrl && currentUrl.includes(item.route) ? "active-menu" : ""}
                      >
                        <ListItemIcon>{currentUrl && currentUrl.includes(item.route) ? item.selectedIcon ? item.selectedIcon : item.icon : item.icon}</ListItemIcon>
                        <ListItemText primary={item.name} />
                        {item.route === 'orderenquiry' &&
                          <ListItemIcon>
                            <IconButton className="p-1 ml-auto toast-header" edge="end" aria-label="add" onClick={(e) => {
                              if (sessionStorage.getItem('userType') === 'FSE' || userType === "FSE") {
                                handleChooseCustomer();
                                // handleChooseCustomerIWSFSEDialog();
                                setLoading(true);
                                let data: any = dispatch(getFSECustomerList());
                                if (data && data.error) {
                                  setErrorMessage(data && data.error);
                                  handleopenCloseErrorSnackbar(true);
                                  setLoading(false);
                                } else {
                                  handleopenCloseErrorSnackbar(false);
                                  setLoading(false);
                                }
                                setLoading(false);
                                e.stopPropagation(); dispatch(getUniqueEnquirySessionId(getUniqueTimestamp()));
                              } else {
                                dispatch(resetEnquiryInfo());
                                e.stopPropagation(); dispatch(getUniqueEnquirySessionId(getUniqueTimestamp()));
                                sessionStorage.setItem('Order-log-UnReadCount', "")
                                sessionStorage.setItem('Order-log-ReadCount', "")
                                sessionStorage.setItem('reset-the-orderlog-count', "true")
                                sessionStorage.setItem('subEnquiryId', "");
                                sessionStorage.setItem("enquiry-current-status", "");
                                sessionStorage.setItem('isCopyandCreate', "");
                                sessionStorage.setItem("partsData", "")
                                history.push('/createenquiry');
                              }
                            }}>
                              <AddCircleIcon fontSize="medium" />
                            </IconButton>
                          </ListItemIcon>
                        }
                        {item.route === 'dealers' &&
                          <ListItemIcon hidden>
                            <IconButton className="p-0 m-1 toast-header" edge="end" aria-label="add" onClick={(e) => {
                              if (sessionStorage.getItem('userType') === 'FSE' || userType === "FSE") {
                                // handleChooseCustomer();
                                handleChooseCustomerIWSFSEDialog();
                                e.stopPropagation(); dispatch(getUniqueEnquirySessionId(getUniqueTimestamp()));
                              } else {
                                dispatch(resetEnquiryInfo());
                                e.stopPropagation(); dispatch(getUniqueEnquirySessionId(getUniqueTimestamp()));
                                sessionStorage.setItem('Order-log-UnReadCount', "")
                                sessionStorage.setItem('Order-log-ReadCount', "")
                                sessionStorage.setItem('reset-the-orderlog-count', "true");
                                sessionStorage.setItem('subEnquiryId', "");
                                sessionStorage.setItem("enquiry-current-status", "");
                                sessionStorage.setItem('isCopyandCreate', "");
                                sessionStorage.setItem("partsData", "")
                                history.push('/createenquiry');
                              }
                            }}>
                              <AddCircleIcon fontSize="medium" />
                            </IconButton>
                          </ListItemIcon>
                        }
                      </ListItem>}
                  </>
                );
              })}
              {!isAdmin && (
                <div className="font-12 mt-2">
                  {markupPercentage && parseFloat(markupPercentage) > 0 && displayPrice && displayPrice.length > 0 && displayPrice[0].value === false && percentageForSellingPrice && (
                    <Typography component="div" className="ml-5 mb-4">
                      <Grid component="label" container alignItems="center" spacing={1}>
                        {menuItems && menuItems[0] && menuItems[0]['name'] !== "Special Price Request" && <Grid item>
                          <AntSwitch
                            checked={isSellingPriceData}
                            onChange={(e) => {
                              dispatch(isSellingPrice(e.target.checked));
                            }}
                          />
                        </Grid>}
                      </Grid>
                    </Typography>
                  )}
                </div>
              )}
              <div className="font-12 mt-2 copyright">
                {profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.customerInfo && profileDetails.data.data.customerInfo ?
                  <Button
                    disabled={profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.basicInfo.userType === 'guestAdmin' || blockIntent === true ? true : false}
                    onClick={(e: any) => {
                      if (profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.basicInfo.userType !== 'guestAdmin') {
                        setOpenCloseSupportDialog(true);
                      }
                    }}
                    style={{ cursor: 'pointer' }} className="text-muted mr-2">Support</Button> :
                  <Button
                    disabled={blockIntent === true ? true : false}
                    onClick={(e: any) => {
                      e.preventDefault();
                    }}
                    className="text-muted mr-2">Support</Button>}
                |  <Button
                  disabled={blockIntent === true ? true : false}
                  onClick={() => handleOpenCloseTAndC(true)} style={{ cursor: 'pointer' }}
                  className="text-muted ml-2">T &amp; C
                </Button>
                <div className="text-muted mt-1">© 2021 Al-Futtaim. All rights reserved</div>
                {customerTierType === "Workshop" && (
                  <div>
                    <img className="ml-n2 mt-2" alt="credit card icon" width="260px"
                      src={creditcardicon} />
                  </div>
                )}
              </div>
            </div>
            <TermsAndConditionsDialog
              open={openTAndCDialog}
              handleopenClose={() => {
                handleOpenCloseTAndC(false)
              }}
            />
            <DocumentExpiryStatus
              open={showDeactivateDialog}
              handleopenClose={(data: any) => {
                setshowDeactivateDialog(data ? true : false)
              }} />
            <SupportDialog
              openCloseSupportDialog={openCloseSupportDialog}
              setOpenCloseSupportDialog={setOpenCloseSupportDialog}
            ></SupportDialog>
          </Drawer>
          {
            !leftMenuVisibility && (
              <>
                <div className="px-2 mt-3 align-items-center text-center">
                  <IconButton
                    onClick={() => {
                      dispatch(openSideMenu(true));
                    }}
                  >
                    <MenuIcon />
                  </IconButton>
                </div>
                ` <div className="pt-3 pb-4 text-center">
                  <img src={smallLogoicon} alt="Logo" className="h-28" />
                </div>
                <div className="media bg-primary py-3 px-1">
                  <img className="rounded-circle hw-45 ml-2" src={(userType === userTypes.kam || userType === userTypes.fse) ? tempKamLogo : dealerLogo ? dealerLogo : NoImage} alt="Logo" />
                </div>
                <div className="w-100 p-2 left-nav">
                  {!isAdmin ? menuItems.map((item: any, index: any) => {
                    return (
                      <>
                        {item.route === 'achievements' ? (
                          <>
                            {showAchievements && (
                              <Tooltip title={item.name} placement="right">
                                <ListItem
                                  disabled={blockIntent && item.route !== 'orgsetting' && item.name !== 'Logout' ? true : false}
                                  onClick={async () => {
                                    if (item.name.toLowerCase() === "logout") {
                                      handleopenClose(true);
                                    } else {
                                      if (item.route) {
                                        history.push(item.route);
                                        if (item.route.includes('/oescatalogue')) {
                                          dispatch(openSideMenu(false));
                                        } else { dispatch(openSideMenu(true)); }
                                      }
                                    }
                                    setCurrentUrl(window.location.href);
                                  }}
                                  button
                                  key={item.name}
                                  className={currentUrl && currentUrl.includes(item.route) ? "active-menu mb-2" : "mb-2"}
                                >
                                  <ListItemIcon>{item.icon}</ListItemIcon>
                                </ListItem>
                              </Tooltip>
                            )}
                          </>
                        ) : (
                          <Tooltip title={item.name} placement="right">
                            <ListItem
                              disabled={blockIntent && item.route !== 'orgsetting' && item.name !== 'Logout' ? true : false}
                              onClick={async () => {
                                if (item.name.toLowerCase() === "logout") {
                                  handleopenClose(true);
                                } else {
                                  if (item.route) {
                                    history.push(item.route);
                                    if (item.route.includes('/oescatalogue')) {
                                      dispatch(openSideMenu(false));
                                    } else { dispatch(openSideMenu(true)); }
                                  }
                                }
                                setCurrentUrl(window.location.href);
                              }}
                              button
                              key={item.name}
                              className={currentUrl && currentUrl.includes(item.route) ? "active-menu mb-2" : "mb-2"}
                            >
                              <ListItemIcon>{item.icon}</ListItemIcon>
                            </ListItem>
                          </Tooltip>
                        )}
                      </>
                    );
                  }) : adminMenuItems.map((item: any, index: any) => {
                    return (
                      <Tooltip title={item.name} placement="right">
                        <ListItem
                          disabled={blockIntent && item.route !== 'orgsetting' && item.name !== 'Logout' ? true : false}
                          onClick={async () => {
                            if (item.name.toLowerCase() === "logout") {
                              handleopenClose(true);
                            } else {
                              if (item.route) {
                                history.push(item.route);
                              }
                            }
                            setCurrentUrl(window.location.href);
                            dispatch(setTabValue(0));
                          }}
                          button
                          key={item.name}
                          className={currentUrl && currentUrl.includes(item.route) ? "active-menu mb-2" : "mb-2"}
                        >
                          <ListItemIcon>{currentUrl && currentUrl.includes(item.route) ? item.selectedIcon ? item.selectedIcon : item.icon : item.icon}</ListItemIcon>
                          {item.route === 'dealers' &&
                            <ListItemIcon hidden>
                              <IconButton className="p-0 m-1 toast-header" edge="end" aria-label="add">
                                <AddCircleIcon fontSize="medium" />
                              </IconButton>
                            </ListItemIcon>
                          }
                        </ListItem>
                      </Tooltip>
                    )
                  })}
                </div >
              </>
            )
          }
        </div >
      }
      {openChooseCustomerDialog && <ChooseCustomerDialog openChooseCustomerDialog={openChooseCustomerDialog} openCloseChooseCustomer={openCloseChooseCustomer} isOrderEnquiryCreated={isOrderEnquiryCreated} />}
      {/* {iwsFseOpenChooseCustomerDialog && <IWSFSEChooseCustomerDialog iwsFseOpenChooseCustomerDialog={iwsFseOpenChooseCustomerDialog} openCloseChooseCustomerIWSFSEDialog={openCloseChooseCustomerIWSFSEDialog} />} */}
      <LogoutDialog
        open={openLogoutDialog}
        text="Are you sure to logout?"
        handleopenClose={() => {
          handleopenClose(false)
        }} />
    </Fragment >
  );
};

export default LeftMenu;