/* eslint-disable */
import React, { Fragment, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import ApproveDialog from './ApproveDialog';
import RejectDialog from './RejectDialog';
import DeactivateDialog from './DeactivateDailog';
import { onBoardingService } from '../../services/onBoarding.service';
import { onboardingService } from '../../services/onboard.service';
import RequestCorrectionDialog from './RequestCorrectionDialog';

export default function ActionButtons(props: any) {
    const [showApproveDialog, setshowApproveDialog] = React.useState<boolean>(false);
    const [showRejectDialog, setshowRejectDialog] = React.useState<boolean>(false);
    const [showDeactivateDialog, setshowDeactivateDialog] = React.useState<boolean>(false);
    const [showRequestCorrectionDialog, setshowRequestCorrectionDialog] = React.useState<boolean>(false);
    const [customerTypes, setcustomerTypes] = React.useState<any>([]);
    const [openSnackbar, setOpenSnackbar] = React.useState(false);
    const [status, setdealerStatus] = React.useState<string>("");
    const { saveCallback, showDeactivate, dealerStatus, deactiveShow, selectedDealerInfo, dealerDetails,
        addressList } = props;
    const leftMenuVisibility: any = useSelector(
        (state: any) => state.global && state.global.sideMenuOpened
    );
    const [isHidden, setIsHidden] = React.useState(false);
    const [isHiddenDocumentsTab, seIIsHiddenDocumentsTab] = React.useState(false);
    const [isHiddenShipToPartyTab, setIsHiddenShipToPartyTab] = React.useState(false);
    const [isHiddenKamReview, setIsHiddenKamReview] = React.useState(false);
    const [isHiddenAccess, setIsHiddenAccess] = React.useState(false);
    const userCurrentStatus = sessionStorage.getItem('userCurrentStatus');
    const [currentStatus, setCurrentStatus] = React.useState<any>("");
    const isStatusPlusComing = sessionStorage.getItem('isStatusPlusComing');

    useEffect(() => {
        // loadCustomerTypes();
        if (userCurrentStatus === "Document expiry issue") {
            setCurrentStatus("Document expiry issue");
        } else {
            setCurrentStatus(dealerStatus);
        }
    }, [dealerStatus])

    useEffect(() => {
        if (userCurrentStatus === "Document expiry issue") {
            setdealerStatus("greenActionButton")
        } else if (dealerStatus == "Deactive") {
            setdealerStatus("greenActionButton")
        }
        else if (dealerStatus == "Active" && isStatusPlusComing === "true") {
            setdealerStatus("greenActionButton")
        } else if(dealerStatus == "Active" && isStatusPlusComing === "false"){
            setdealerStatus("redActionButton")
        }
    }, [props])

    // const loadCustomerTypes = async () => {
    //     const data = await onboardingService.getCustomerTypes();
    //     if (data && data.data && data.data[0])
    //         setcustomerTypes(data.data[0].customerType)
    // }

    const handleopenCloseSnackbar = (openRequestSnackbar?: any) => {
        setOpenSnackbar(openRequestSnackbar ? true : false);
    };

    return (
        <Fragment>
            <div className={leftMenuVisibility ? "row card card-body p-2 fixed-bottom ml-280" : "row card card-body p-2 fixed-bottom ml-70"}>
                <div className="col-12 d-flex mt-2 p-0">
                    <div className="col-9">
                        <div className="">
                            {
                                deactiveShow && dealerStatus ? <Button variant="contained"
                                    onClick={() => {
                                        if (deactiveShow) {
                                            setshowDeactivateDialog(true);
                                            setOpenSnackbar(false)
                                        }
                                    }}
                                    className={`${status} border-left rounded-pill ml-4 mb-2`} >{dealerStatus == "Active" && isStatusPlusComing === "true" ? 'Activate Customer' : currentStatus === "Document expiry issue" ? 'Activate Customer' : dealerStatus == "Deactive" ? 'Activate Customer' : 'Deactivate Customer'}</Button> : ""
                            }
                            {dealerStatus === "Deactive" && "Activate Customer" || currentStatus === "Document expiry issue" ?
                                <div className="row px-2">
                                    <span className="row text-info px-5">Deactivation Reason :

                                   {currentStatus === "Document expiry issue" ?
                                    <div className="row px-3 text-info-deactivationReason">Document expiry issue</div> :
                                        <div className="row px-3 text-info-deactivationReason">
                                            {/* {selectedDealerInfo?.deactivationReason || dealerDetails?.data?.deactivationReason
                                                || dealerDetails?.data?.deactivationReason ||
                                                 dealerDetails?.data?.data?.deactivationReason ?

                                                selectedDealerInfo?.deactivationReason || dealerDetails?.data?.deactivationReason
                                                || dealerDetails?.data?.deactivationReason || dealerDetails?.data?.data?.deactivationReason
                                                : "-"} */}

                                            {selectedDealerInfo && selectedDealerInfo.deactivationReason && selectedDealerInfo.deactivationReason.slice(0, 100)
                                                || dealerDetails && dealerDetails.data && dealerDetails.data.deactivationReason && dealerDetails.data.deactivationReason.slice(0, 100)
                                                || dealerDetails && dealerDetails.data && dealerDetails.data.deactivationReason && dealerDetails.data.deactivationReason.slice(0, 100)
                                                || dealerDetails && dealerDetails.data && dealerDetails.data.data && dealerDetails.data.data.deactivationReason && dealerDetails.data.data.deactivationReason.slice(0, 100)
                                            }
                                            {isHidden === false && selectedDealerInfo?.deactivationReason
                                                || isHiddenDocumentsTab === false && dealerDetails?.data?.deactivationReason
                                                || isHiddenAccess === false && dealerDetails?.data?.data?.deactivationReason
                                                ?
                                                selectedDealerInfo && selectedDealerInfo.deactivationReason && selectedDealerInfo.deactivationReason.length > 100
                                                &&
                                                <button
                                                    className='text-info mb-2'
                                                    style={{ border: "none" }}
                                                    onClick={() => {
                                                        setIsHidden(true);
                                                    }}
                                                >..read more</button>
                                                || dealerDetails && dealerDetails.data && dealerDetails.data.deactivationReason && dealerDetails.data.deactivationReason.length > 100
                                                &&
                                                <button
                                                    className='text-info mb-2'
                                                    style={{ border: "none" }}
                                                    onClick={() => {
                                                        seIIsHiddenDocumentsTab(true)
                                                    }}
                                                >..read more</button>
                                                || dealerDetails && dealerDetails.data && dealerDetails.data.data && dealerDetails.data.data.deactivationReason && dealerDetails.data.data.deactivationReason.length > 100
                                                &&
                                                <button
                                                    className='text-info mb-2'
                                                    style={{ border: "none" }}
                                                    onClick={() => {
                                                        setIsHiddenAccess(true);
                                                    }}
                                                >..read more</button> : ""}
                                            {isHidden && selectedDealerInfo?.deactivationReason
                                                || isHiddenDocumentsTab && dealerDetails?.data?.deactivationReason
                                                || isHiddenShipToPartyTab && dealerDetails?.data?.deactivationReason
                                                || isHiddenAccess && dealerDetails?.data?.data?.deactivationReason
                                                ?
                                                selectedDealerInfo && selectedDealerInfo.deactivationReason && selectedDealerInfo.deactivationReason.slice(100)
                                                || dealerDetails && dealerDetails.data && dealerDetails.data.deactivationReason && dealerDetails.data.deactivationReason.slice(100)
                                                || dealerDetails && dealerDetails.data && dealerDetails.data.deactivationReason && dealerDetails.data.deactivationReason.slice(100)
                                                || dealerDetails && dealerDetails.data && dealerDetails.data.data && dealerDetails.data.data.deactivationReason.slice(100)
                                                : ""}
                                        </div>}
                                    </span>
                                </div>
                                : ""}
                        </div>
                    </div>
                    <div className="col-3 onboard-footer">
                        <div className="">
                            <Button variant="contained"
                                disabled={dealerStatus && dealerStatus === "Deactive" ? true : false}
                                onClick={() => { if (saveCallback) saveCallback() }}
                                color="primary" className={`blueActionButton border-left rounded-pill ml-4 mb-2`} >{props.showSave ? 'Save' : 'Save & Next'}</Button>
                        </div>
                    </div>
                </div>
            </div>
            <ApproveDialog
                customerTypes={customerTypes}
                open={showApproveDialog}
                openRequestSnackbar={openSnackbar}
                handleopenCloseSnackbar={handleopenCloseSnackbar}
                handleopenClose={(data: any) => {
                    setshowApproveDialog(data ? true : false)
                }}
            />
            <DeactivateDialog
                open={showDeactivateDialog}
                status={currentStatus}
                openRequestSnackbar={openSnackbar}
                handleopenCloseSnackbar={handleopenCloseSnackbar}
                handleopenClose={(data: any) => {
                    setshowDeactivateDialog(data ? true : false)
                }}
            />
            <RejectDialog
                open={showRejectDialog}
                openRequestSnackbar={openSnackbar}
                handleopenCloseSnackbar={handleopenCloseSnackbar}
                handleopenClose={(data: any) => {
                    setshowRejectDialog(data ? true : false)
                }}
            />
            <RequestCorrectionDialog
                open={showRequestCorrectionDialog}
                openRequestSnackbar={openSnackbar}
                handleopenCloseSnackbar={handleopenCloseSnackbar}
                handleopenClose={(data: any, open: any) => {
                    setshowRequestCorrectionDialog(data ? true : false)
                }}
            />
        </Fragment>
    );
}
