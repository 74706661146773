/* eslint-disable */
import React, { Fragment, Dispatch } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import WarningIcon from "@material-ui/icons/Warning";
import { Button, Dialog } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { get } from 'lodash';
import Snackbar from '../../common/components/CreatedEnquirySnackbar';
import {
    getUniqueEnquirySessionId,
    resetEnquiryInfo,
} from "../../store/actions/createUpdateEnquiry.actions";
import { setOrderEnquiryDetails } from "../../store/actions/orderEnquiry.action";
import { getUniqueTimestamp } from "../../common/components/util";
import { getCustomerDetailsByCustomerID } from '../../store/actions/dealer.action';

const useStyles = makeStyles(() => ({
    root: {
        width: '100%',
    },
    title: {
        flex: '1 1 100%',
    },
    closeButton: {
        backgroundColor: '#EAEAEA',
        width: 120,
        fontWeight: 800,
        textTransform: 'capitalize',
        fontFamily: 'Nunito',
        fontSize: '0.688rem',
        color: '#666666',
        borderRadius: '2.5rem',
        height: 35,
        textAlign: 'center',
        marginRight: 24
    },
    icon: {
        float: "left",
        margin: "0 20px 20px 0",
        height: "100%",
        fill: "white",
    },
    cancel: {
        fontSize: '0.813rem',
        fontFamily: 'Nunito',
        fontWeight: 700,
        color: '#919191',
        textTransform: 'capitalize'
    },
    correctionText: {
        color: '#133F8A',
        fontSize: '0.875rem',
        fontFamily: 'Nunito',
        fontWeight: 700,
        textTransform: 'inherit'
    },
    textFontColor: {
        color: 'red',
        fontSize: '0.875rem',
        fontFamily: 'Nunito',
        fontWeight: 900,
        textTransform: 'inherit'
    }
}));

export default function ExpiredDialog(props: any) {
    const dispatch: Dispatch<any> = useDispatch();
    const classes = useStyles();
    const [loading, setLoading] = React.useState<boolean>(false);
    const history = useHistory()
    const { selectedNewDealer }: any = useSelector((state: any) => state.dealer);
    const b2bUserId = get(selectedNewDealer, "createdByB2bUserId", "");
    const [isValid, setIsValid] = React.useState<boolean>(true);
    const [successMessage, setSuccessMessage] = React.useState<any>(null);
    const { openRequestSnackbar, handleopenCloseSnackbar, products, enquiryData, resetData, checkSessionStorage, setCopyCreateEnquiry } = props;
    const [openErrorSnackbar, setOpenErrorSnackbar] = React.useState(false);
    const { enquiryList, enquiryStatus } = useSelector((state: any) => state.orderEnquiry);
    const [userMasterId, setUserMasterId] = React.useState<any>();

    return (
        <Fragment>
            {/* {loading && <FullscreenLoader />} */}
            <Dialog
                open={props.open ? true : false}
                onClose={() => { props.handleopenClose(false); props.setSuccessMessage(''); props.handleopenCloseSnackbar(false); }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
            >
                <Snackbar
                    open={props.openRequestSnackbar ? true : false}
                    type={props.openErrorSnackbar ? 'error' : ''}
                    handleopenClose={() => { }} message={props.successMessage}
                />
                <DialogContent>
                    <div className={classes.root}>
                        <div className="col-12 mt-1 px-0 pr-0">
                            <Typography className={classes.title} variant="h4" id="tableTitle" component="div">
                                <h4 className="shift-to-party-page-title">
                                    <IconButton aria-label="close" className="float-right pr-5 mt-2" onClick={() => { props.handleopenClose(false); }}>
                                        <CloseIcon />
                                    </IconButton>
                                </h4>
                            </Typography>
                        </div>
                        <DialogContent>
                            <div className={classes.icon}>
                                <WarningIcon className="delete-warning" fontSize="small" />
                            </div>
                            <div>
                                <DialogContentText id="alert-dialog-description">
                                    <div className="">
                                        <h6 className="mt-2 mb-0 dealer-expired-heading">Re-Order Enquiry ? </h6>
                                    </div>
                                </DialogContentText>
                            </div>
                        </DialogContent>
                    </div>
                    <div className="col-md-12 mt-1 mb-2 px-5">
                        <div className="d-flex align-items-center">
                            <div className="dealer-expired-subText">
                                Are you sure you want to copy & create New Enquiry using this Enquiry ?
                            </div>
                        </div>
                    </div>
                </DialogContent>
                <div className="col-12 mt-2 mb-2">
                    <div className="d-flex align-items-center">
                        <div className="row-space-start ml-auto">
                            <DialogActions className="mt-3 mb-1 justify-content-end">
                                <Button variant="contained"
                                    color="secondary" className="check-availability-button-access-tab"
                                    onClick={async () => {
                                        setCopyCreateEnquiry(true);
                                        let partsData: any = [];
                                        setLoading(true);
                                        if (sessionStorage.getItem('userType') === 'FSE') {
                                            await dispatch(getCustomerDetailsByCustomerID(enquiryData && enquiryData.length > 0 && enquiryData[0].customerId));
                                        }
                                        if (enquiryData && enquiryData.length > 0) {
                                            let uniqueSequences = new Set(); // Create a Set to store unique sequences
                                            enquiryData.forEach((data: any) => {
                                                if (data.products && data.products.length > 0) {
                                                    data.products.forEach((details: any) => {
                                                        partsData.push({
                                                            productId: details.productId,
                                                            quantity: details.quantity
                                                        });
                                                    });
                                                }
                                                if (data.removedProducts && data.removedProducts.length > 0) {
                                                    data.removedProducts.forEach((details: any) => {
                                                        // Check if the sequence is already present in the Set
                                                        if (!uniqueSequences.has(details.seq)) {
                                                            partsData.push({
                                                                productId: details.productId,
                                                                quantity: details.quantity
                                                            });
                                                            uniqueSequences.add(details.seq); // Add the sequence to the Set
                                                        }
                                                    });
                                                }
                                            });
                                        }
                                        // enquiryData && enquiryData.length > 0 && enquiryData.map((data: any) => {
                                        //     data && data.products.map((details: any) => {
                                        //         partsData.push({
                                        //             productId: details.productId,
                                        //             quantity: details.quantity
                                        //         })
                                        //     })
                                        //     data && data.removedProducts && data.removedProducts.length > 0 && data && data.removedProducts.map((details: any) => {
                                        //         partsData.push({
                                        //             productId: details.productId,
                                        //             quantity: details.quantity
                                        //         })
                                        //     })
                                        // })
                                        resetData();
                                        dispatch(resetEnquiryInfo());
                                        setLoading(false);
                                        props.setViewigFromOrderHistory(false);
                                        props.handleopenClose(false);
                                        sessionStorage.setItem('partsData', JSON.stringify([...partsData]));
                                        dispatch(getUniqueEnquirySessionId(getUniqueTimestamp()));
                                        dispatch(setOrderEnquiryDetails('create'));
                                        sessionStorage.setItem('Order-log-UnReadCount', "")
                                        sessionStorage.setItem('Order-log-ReadCount', "")
                                        sessionStorage.setItem('reset-the-orderlog-count', "true");
                                        sessionStorage.setItem('subEnquiryId', "");
                                        sessionStorage.setItem('isCopyandCreate', "true");
                                        history.push('/createenquiry');
                                        checkSessionStorage(true);
                                    }}
                                >Yes
                                </Button>
                                <Button variant="contained" className={classes.closeButton}
                                    onClick={() => { props.handleopenClose(false); }}
                                >Close
                                </Button>
                            </DialogActions>
                        </div>
                    </div>
                </div>
            </Dialog>
        </Fragment>
    );
}
