import { API_ENDPOINTS, DEALER, ONBOARD, ORDER_ENQUIRY } from "../constants/apiConfig";
import { makeRequest } from "../middleware/axiosMiddleware"

export const dealerService = {
    addDealer,
    addDealerUser,
    getAgreementDocs,
    sendCustomerAgreementMail,
    sendCategoryCertificateMail,
    getAllDealers,
    getAllFSEDealers,
    getAllDealersRequests,
    getDealerDetails,
    checkUserIdAvailability,
    updateDealerData,
    updateDealerUser,
    getAllUsers,
    verifySAPCustomerId,
    addUserParameter,
    editUserParameter,
    updateDealerInProgressUser,
    getAccessParam,
    getKamAccessParam,
    addAccessParam,
    updateAccessParam,
    getUserParameter,
    uploadDocument,
    deleteDocument,
    getUploadedDocuments,
    sendEmailTemplate,
    getUserDetailsInfo,
    getKAMUserList,
    getFSEKAMUserList,
    getKAMUserListForKAM,
    getFSESalesRegions,
    resendInvitationLink,
    getAllSapCustomerId,
    getAllLostSaleReason,
    getAllBrand,
    getAllCRStatus,
    getLostSaleReportData,
    getDealerCreditRequest,
    saveAdditionalCreditRequest,
    deleteBankStatementOfCreditRequest,
    generateB2bUserId,
    sendInvitationLink,
    confirmDataFromDealer,
    updateSellingPrice,
    getViewTarget,
    getCustomerFilterStatus,
    getShipToParty,
    postSetDefaultAddress,
    getFSECustomerTypes,
    getRefreshDataFromSAP,
    getCurrency,
    getShipToPartyDropDownValues,
    getDeliveryBlockReason,
    getRefreshDataFromSAPCustomerDetails,
    getCustomerDetailsByCustomerID,
    getFSECustomerList,
    getFSEEnquiryStatus,
    getInactiveStatusDetails
};

async function addDealer(data: any) {
    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + DEALER.ADD_DEALER;
    const options = {
        // cors_url: ADMIN_TOOL_ENDPOINTS.CORS_URL,
        path: apiPath,
        method: "POST",
        bodyObj: data,
        customHeaders: {
            // "X-Subscription-Key": CPM_MASS_SERVICE.AUTH_KEY
        },
        formdata: true
    };
    return await makeRequest(options)
}

async function addUserParameter(data: any) {
    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + DEALER.ADD_PARAMETER;
    const options = {
        // cors_url: ADMIN_TOOL_ENDPOINTS.CORS_URL,
        path: apiPath,
        method: "POST",
        bodyObj: data,
        customHeaders: {
            // "X-Subscription-Key": CPM_MASS_SERVICE.AUTH_KEY
        },
        formdata: true
    };
    return await makeRequest(options)
}

async function getUserParameter(sapCustomerId: any, b2bUserId: any) {
    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + DEALER.GET_USER_PARAMETER + `?sapCustomerId=${sapCustomerId}&b2bUserId=${b2bUserId}`;
    const options = {
        path: apiPath,
        method: "GET",
        formdata: true
    };
    return await makeRequest(options)
}

async function resendInvitationLink(b2bUserId: any) {
    const data = { "b2bUserId": b2bUserId }
    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + DEALER.RESENDINVITATION;
    const options = {
        path: apiPath,
        method: "POST",
        bodyObj: data,
        customHeaders: {},
        formdata: true
    };
    return await makeRequest(options)
}

async function getUserDetailsInfo(b2bUserId: any) {
    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + DEALER.ADD_DEALER_USER + `/${b2bUserId}`;
    const options = {
        path: apiPath,
        method: "GET",
        formdata: true
    };
    return await makeRequest(options)
}

async function getCurrency() {
    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + DEALER.ADD_DEALER_USER;
    const options = {
        path: apiPath,
        method: "GET",
        formdata: true
    };
    return await makeRequest(options)
}

async function getRefreshDataFromSAP(refreshAddress?: any, id?: any, shipToPartyNumber?: any, webApplicationLoginId?: any, userName?: any, addressId?: any) {
    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + `/formField/masterData?dataType=${refreshAddress}&id=${id}&shipToPartyNumber=${shipToPartyNumber}&actionTakenBy=${webApplicationLoginId}&actionTakenByName=${userName.replace(/\s+/g, '')}&addressId=${addressId}`
    const options = {
        path: apiPath,
        method: "GET",
        formdata: true
    };
    return await makeRequest(options)
}

async function getRefreshDataFromSAPCustomerDetails(refreshCustomerDetails?: any, id?: any, webApplicationLoginId?: any, userName?: any) {
    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + `/formField/masterData?dataType=${refreshCustomerDetails}&id=${id}&actionTakenBy=${webApplicationLoginId}&actionTakenByName=${userName.replace(/\s+/g, '')}`
    const options = {
        path: apiPath,
        method: "GET",
        formdata: true
    };
    return await makeRequest(options)
}

async function addDealerUser(data: any) {
    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + DEALER.ADD_DEALER_USER;
    const options = {
        // cors_url: ADMIN_TOOL_ENDPOINTS.CORS_URL,
        path: apiPath,
        method: "POST",
        bodyObj: data,
        customHeaders: {

            // "X-Subscription-Key": CPM_MASS_SERVICE.AUTH_KEY
        },
        formdata: true
    };
    return await makeRequest(options)
}

async function getAgreementDocs(type: any, sapId?: any) {
    let apiPath = API_ENDPOINTS.DEALER_ENDPOINT + `/listAllCustomers?type=${type}`
    if (sapId && sapId.trim() !== '') {
        apiPath = `${apiPath}&sapCustomerId=${sapId.trim()}`;
    }
    const options = {
        path: apiPath,
        method: "GET",
        formdata: true
    };
    return await makeRequest(options)
}

async function sendCustomerAgreementMail(data: any) {
    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + '/customer/sendCustomerAgreement';
    const options = {
        // cors_url: ADMIN_TOOL_ENDPOINTS.CORS_URL,
        path: apiPath,
        method: "POST",
        bodyObj: data,
        customHeaders: {

            // "X-Subscription-Key": CPM_MASS_SERVICE.AUTH_KEY
        },
        formdata: true
    };
    return await makeRequest(options)
}

async function sendCategoryCertificateMail(data: any) {
    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + '/customer/sendCategoryCertificateEmail'
    const options = {
        // cors_url: ADMIN_TOOL_ENDPOINTS.CORS_URL,
        path: apiPath,
        method: "POST",
        bodyObj: data,
        customHeaders: {

            // "X-Subscription-Key": CPM_MASS_SERVICE.AUTH_KEY
        },
        formdata: true
    };
    return await makeRequest(options)
}

async function getAllDealers(filterBy?: string, filterBySalesRegion?: string, customerTier?: any, searchBy?: string, page?: number) {
    let userType = sessionStorage.getItem('userType');
    let webApplicationLoginId = sessionStorage.getItem('webApplicationLoginId');
    let apiPath = API_ENDPOINTS.DEALER_ENDPOINT + DEALER.GET_ALL_DEALERS + `?&customerTierType=${customerTier}&userType=${userType?.toLowerCase()}`;

    if (sessionStorage.getItem('userType') === "kam" && sessionStorage.getItem('userType') !== "FSE") {
        apiPath = API_ENDPOINTS.DEALER_ENDPOINT + DEALER.GET_ALL_DEALERS + `?&customerTierType=${customerTier}&userType=${userType?.toLowerCase()}&loginId=${webApplicationLoginId}`;
    } else {
        apiPath = API_ENDPOINTS.DEALER_ENDPOINT + DEALER.GET_ALL_DEALERS + `?&customerTierType=${customerTier}&userType=${userType?.toLowerCase()}`;
    }

    if (filterBySalesRegion && filterBySalesRegion.trim() !== '') {
        apiPath = `${apiPath}&filterBySalesRegion=${filterBySalesRegion.trim()}`;
    }

    if (userType && userType?.toLowerCase() === 'fse') {
        apiPath = `${apiPath}&filterBy=${webApplicationLoginId?.trim()}`
    } else {
        if (filterBy && filterBy.trim() !== "" && filterBy.trim() !== "0") {
            apiPath = `${apiPath}&filterBy=${filterBy.trim()}`
        }
    }

    if (searchBy && searchBy.trim() !== "") {
        apiPath = `${apiPath}&searchBy=${searchBy.trim()}`;
    }

    const options = {
        path: apiPath,
        method: "GET",
        bodyObj: {},
        customHeaders: {
            "Paging-Info": `start-index=${page ? page * 10 : 0}|no-of-records=10`
        },
    };
    return await makeRequest(options)
}

async function getAllFSEDealers(filterBy?: string, filterBySalesRegion?: string, customerTier?: any, searchBy?: string, page?: number,) {
    let userType = sessionStorage.getItem('userType');
    let webApplicationLoginId = sessionStorage.getItem('webApplicationLoginId');

    // 'customerTierType=All&filterBy=all&userType=fse&loginId=082427&filterBySalesRegion=&searchBy='
    let apiPath = API_ENDPOINTS.DEALER_ENDPOINT + DEALER.GET_ALL_DEALERS + `?&customerTierType=${customerTier}&loginId=${webApplicationLoginId?.trim()}&userType=${userType?.toLowerCase()}`;

    if (filterBy && filterBy.trim() !== "" && filterBy.trim() !== "0") {
        apiPath = `${apiPath}&filterBy=${filterBy.trim()}`
    }

    if (filterBy && filterBy.trim() !== "" && filterBy.trim() === "0") {
        apiPath = `${apiPath}&filterBy=all`
    }

    if (filterBySalesRegion && filterBySalesRegion.trim() !== '') {
        apiPath = `${apiPath}&filterBySalesRegion=${filterBySalesRegion.trim()}`;
    }

    if (searchBy && searchBy.trim() !== "") {
        apiPath = `${apiPath}&searchBy=${searchBy.trim()}`;
    }

    const options = {
        path: apiPath,
        method: "GET",
        bodyObj: {},
        customHeaders: {
            "Paging-Info": `start-index=${page ? page * 10 : 0}|no-of-records=10`
        },
    };
    return await makeRequest(options)
}

// async function getAllDealers(filterBy?: string, customerTier?:any,searchBy?: string, page?: number) {
//     let apiPath = API_ENDPOINTS.DEALER_ENDPOINT + DEALER.GET_ALL_DEALERS;
//     if (filterBy && filterBy.trim() !== "" && filterBy.trim()!=="0" && searchBy && searchBy.trim() !== "")
//      apiPath = `${apiPath}?&filterBy=${filterBy.trim()}&searchBy=${searchBy.trim()}&customerTierType=${customerTier}`;
//     else if (filterBy && filterBy.trim() !== "0")
//      apiPath = `${apiPath}?&filterBy=${filterBy.trim()}&customerTierType=${customerTier}`;
//     else if (filterBy && filterBy.trim()==="0"&&searchBy&&searchBy==="")    
//      apiPath = `${apiPath}?&filterBy=${""}&customerTierType=${customerTier}`;
//     else if (filterBy && filterBy.trim()==="0"&&searchBy&&searchBy!=="")    
//      apiPath = `${apiPath}?&filterBy=${""}&searchBy=${searchBy.trim()}&customerTierType=${customerTier}`;
//     else if (filterBy&&filterBy.trim()==="0")
//     apiPath = `${apiPath}?&customerTierType=${customerTier}`;
//     else if (searchBy && searchBy.trim() !== "")
//     apiPath = `${apiPath}?&searchBy=${searchBy.trim()}&customerTierType=${customerTier}`;

//     const options = {
//         path: apiPath,
//         method: "GET",
//         bodyObj: {},
//         customHeaders: {
//             "Paging-Info": `start-index=${page ? page * 10 : 0}|no-of-records=10`
//         },
//     };
//     return await makeRequest(options)
// }


async function getAllDealersRequests(loginId?: string, customerTier?: string, searchBy?: string, filterValue?: any, filterByStatus?: string, page?: number) {
    let userType: any = sessionStorage.getItem('userType');
    let apiPath = API_ENDPOINTS.DEALER_ENDPOINT + DEALER.GET_ALL_DEALERS_REQUESTS;
    apiPath = `${apiPath}?&loginId=${loginId}&searchBy=${searchBy}&filterBy=${filterValue}&customerTierType=${customerTier}&filterByStatus=${filterByStatus}&loginUserType=${userType.toLowerCase()}`;
    const options = {
        path: apiPath,
        method: "GET",
        bodyObj: {},
        customHeaders: {
            "Paging-Info": `start-index=${page ? page * 10 : 0}|no-of-records=10`
        },
    };
    return await makeRequest(options)
}

async function getAllUsers(id?: any, page?: any, searchBy?: any, isModifyParameter?: any) {
    let path: any;

    if (isModifyParameter === true || isModifyParameter === false) {
        path = API_ENDPOINTS.DEALER_ENDPOINT + DEALER.GET_ALL_USERS + `/${id}?isAddressesRequired=${isModifyParameter}`;
    } else {
        path = API_ENDPOINTS.DEALER_ENDPOINT + DEALER.GET_ALL_USERS + `/${id}`;
    }

    if (searchBy && searchBy.trim() !== "") {
        if (isModifyParameter === true || isModifyParameter === false) {
            path = `${path}&searchBy=${searchBy.trim()}`;
        } else {
            path = `${path}?searchBy=${searchBy.trim()}`;
        }
    }

    const options = {
        path,
        method: "GET",
        bodyObj: {},
        customHeaders: {
            "Paging-Info": `start-index=${page ? (parseInt(page) * 10) : 0}|no-of-records=10`
        },
    };
    return await makeRequest(options)
}

async function getDealerDetails(customerId: string, type: any, webApplicationLoginId?: any) {
    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + DEALER.GET_DEALER_DETAILS;
    const options = {
        path: `${apiPath}/${customerId}?loginId=${webApplicationLoginId ? webApplicationLoginId : sessionStorage.getItem('webApplicationLoginId')}&details=${type}`,
        method: "GET",
        bodyObj: {},
        customHeaders: {},
    };
    return await makeRequest(options)
}

async function getCustomerFilterStatus() {
    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + '/formField/newCustomerStatus';
    const options = {
        path: apiPath,
        method: "GET",
        bodyObj: {},
        customHeaders: {},
    };
    return await makeRequest(options)
}

async function getFSECustomerTypes() {
    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + `/formField/customerAccess?id=${sessionStorage.getItem('webApplicationLoginId')}&role=fse`;
    const options = {
        path: apiPath,
        method: "GET",
        bodyObj: {},
        customHeaders: {},
    };
    return await makeRequest(options)
}

async function getCustomerDetailsByCustomerID(sapCustomerId: any) {
    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + `/formField/masterData?dataType=customerDetailsAsPerTheCustomerType&loginId=${sessionStorage.getItem('webApplicationLoginId')}&sapCustomerId=${sapCustomerId}`;
    const options = {
        path: apiPath,
        method: "GET",
        bodyObj: {},
        customHeaders: {},
    };
    return await makeRequest(options)
}

async function getFSECustomerList() {
    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + `/formField/masterData?dataType=customerListOnFsePortalForEnquiry&loginId=${sessionStorage.getItem('webApplicationLoginId')}`;
    const options = {
        path: apiPath,
        method: "GET",
        bodyObj: {},
        customHeaders: {},
    };
    return await makeRequest(options)
}

async function getFSEEnquiryStatus() {
    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + `/formField/enquiryStatus`;
    const options = {
        path: apiPath,
        method: "GET",
        bodyObj: {},
        customHeaders: {},
    };
    return await makeRequest(options)
}

async function getInactiveStatusDetails(sapCustomerId: any) {
    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + `/displayStatusesAndReasons?sapCustomerId=${sapCustomerId}`;
    const options = {
        path: apiPath,
        method: "GET",
        bodyObj: {},
        customHeaders: {},
    };
    return await makeRequest(options)
}

async function checkUserIdAvailability(data: any) {
    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + DEALER.CHECK_USERID_AVAILABILITY;
    const options = {
        // cors_url: ADMIN_TOOL_ENDPOINTS.CORS_URL,
        path: apiPath,
        method: "POST",
        bodyObj: data,
        customHeaders: {
            // "X-Subscription-Key": CPM_MASS_SERVICE.AUTH_KEY
        },
        formdata: true
    };
    return await makeRequest(options)
}

async function updateDealerData(customerId: string, data: any) {
    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + DEALER.UPDATE_DEALER + "/" + customerId;
    const options = {
        // cors_url: ADMIN_TOOL_ENDPOINTS.CORS_URL,
        path: apiPath,
        method: "Put",
        bodyObj: data,
        customHeaders: {
            // "X-Subscription-Key": CPM_MASS_SERVICE.AUTH_KEY
        },
        formdata: true
    };
    return await makeRequest(options)
}

async function updateDealerUser(data: any) {
    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + DEALER.UPDATE_DEALER_USER;
    const options = {
        // cors_url: ADMIN_TOOL_ENDPOINTS.CORS_URL,
        path: apiPath,
        method: "Put",
        bodyObj: data,
        customHeaders: {
            // "X-Subscription-Key": CPM_MASS_SERVICE.AUTH_KEY
        },
        formdata: true
    };
    return await makeRequest(options)
}

async function updateDealerInProgressUser(data: any) {
    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + DEALER.UPDATE_DEALER_SUB_USER;
    const options = {
        // cors_url: ADMIN_TOOL_ENDPOINTS.CORS_URL,
        path: apiPath,
        method: "Put",
        bodyObj: data,
        customHeaders: {
            // "X-Subscription-Key": CPM_MASS_SERVICE.AUTH_KEY
        },
        formdata: true
    };
    return await makeRequest(options)
}

async function verifySAPCustomerId(sapCustomerId: string) {
    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + DEALER.VERIFY_SAPCUSTOMERID + "/" + sapCustomerId;
    const options = {
        // cors_url: ADMIN_TOOL_ENDPOINTS.CORS_URL,
        path: apiPath,
        method: "GET",
        customHeaders: {
            "Lob-Id": "AUTO",
            "Channel-Id": "B2B",
            "Accept-Language": "EN",
            "Content-Type": "application/json"
        },
        formdata: true
    };
    return await makeRequest(options)
}

async function editUserParameter(data: any) {
    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + DEALER.GET_USER_PARAMETER
    const options = {
        // cors_url: ADMIN_TOOL_ENDPOINTS.CORS_URL,
        path: apiPath,
        method: "PUT",
        bodyObj: data,
        customHeaders: {
            // "X-Subscription-Key": CPM_MASS_SERVICE.AUTH_KEY
        },
        formdata: true
    };
    return await makeRequest(options)
}

async function addAccessParam(data: any) {
    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + DEALER.ADD_ACCESS_PARAM;
    const options = {
        // cors_url: ADMIN_TOOL_ENDPOINTS.CORS_URL,
        path: apiPath,
        method: "POST",
        bodyObj: data,
        customHeaders: {
            // "X-Subscription-Key": CPM_MASS_SERVICE.AUTH_KEY
        },
        formdata: true
    };
    return await makeRequest(options)
}

async function updateAccessParam(data: any) {
    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + DEALER.UPDATE_ACCESS_PARAM;
    const options = {
        // cors_url: ADMIN_TOOL_ENDPOINTS.CORS_URL,
        path: apiPath,
        method: "PUT",
        bodyObj: data,
        customHeaders: {
            // "X-Subscription-Key": CPM_MASS_SERVICE.AUTH_KEY
        },
        formdata: true
    };
    return await makeRequest(options)
}

async function getAccessParam(id: any, adminId: any, action: any) {
    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + DEALER.GET_ACCESS_PARAM;
    const options = {
        path: `${apiPath}/${id}/adminB2bUserId/${adminId}?action=${action}`,
        method: "GET",
        bodyObj: {},
        customHeaders: {
        },
    };
    return await makeRequest(options)
}

async function getKamAccessParam(id: any, sapId: any) {
    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + DEALER.GET_ACCESS_PARAM;
    const options = {
        path: `${apiPath}/${id}/sapCustomerId/${sapId}`,
        method: "GET",
        bodyObj: {},
        customHeaders: {
        },
    };
    return await makeRequest(options)
}

async function uploadDocument(data: any, customHeaders: any) {
    const path = API_ENDPOINTS.CMS_ENDPOINT + DEALER.DOCUMENTS;

    const options = {
        path,
        method: "POST",
        bodyObj: data,
        customHeaders,
        formdata: true
    };

    return await makeRequest(options)
}

async function deleteDocument(id: any, sapCustomerId: any, document: any) {
    const data: any = { id, sapCustomerId }
    document ? data.document = 'true' : data.document = 'false';
    const path = API_ENDPOINTS.CMS_ENDPOINT + DEALER.DOCUMENTS + '/delete';

    const options = {
        path,
        method: "POST",
        bodyObj: data,
        customHeaders: {},
        formdata: true
    };

    return await makeRequest(options)
}

async function getUploadedDocuments(customHeaders: any, sapCustomerId: any) {
    const path = API_ENDPOINTS.CMS_ENDPOINT + DEALER.DOCUMENTS + `/${sapCustomerId}`;

    const options = {
        path,
        method: "GET",
        customHeaders,
    };
    return await makeRequest(options)
}

async function sendEmailTemplate(data: any) {
    const apiPath = API_ENDPOINTS.EMAIL_ENDPOINT
    const options = {
        //cors_url: API_ENDPOINTS.CORS_URL,
        path: apiPath,
        method: "POST",
        bodyObj: data,
        customHeaders: {
            'Lob-Id': 'auto',
            'Channel-Id': 'web',
            'Org-Id': '2001',
            'Paging-Info': 'start-index=0',
            'Accept-Language': 'EN',
            'Content-Type': 'application/json'
        },
        formdata: true
    };
    return await makeRequest(options)
}

async function getKAMUserList(customHeaders: any) {
    const path = API_ENDPOINTS.KAMUSERS;

    const options = {
        path,
        method: "GET",
        customHeaders,
    };
    return await makeRequest(options)
}

async function getFSEKAMUserList(customHeaders: any) {

    const path = API_ENDPOINTS.DEALER_ENDPOINT + DEALER.FORMFIELD + `/masterData?dataType=kamListForFse&webApplicationLoginId=${sessionStorage.getItem('webApplicationLoginId')}`;

    const options = {
        path,
        method: "GET",
        customHeaders,
    };
    return await makeRequest(options)
}

async function getKAMUserListForKAM(customHeaders: any) {

    const path = API_ENDPOINTS.DEALER_ENDPOINT + DEALER.FORMFIELD + `/masterData?dataType=kamListForKam&webApplicationLoginId=${sessionStorage.getItem('webApplicationLoginId')}`;

    const options = {
        path,
        method: "GET",
        customHeaders,
    };
    return await makeRequest(options)
}



async function getFSESalesRegions(customHeaders: any) {
    const path = API_ENDPOINTS.DEALER_ENDPOINT + DEALER.FORMFIELD + `/masterData?dataType=salesRegionList&webApplicationLoginId=${sessionStorage.getItem('webApplicationLoginId')}`;

    const options = {
        path,
        method: "GET",
        customHeaders,
    };
    return await makeRequest(options)
}

async function getShipToPartyDropDownValues(onboardingCustomerType: any) {

    const path = `${API_ENDPOINTS.DEALER_ENDPOINT}${DEALER.FORMFIELD}${onboardingCustomerType === 'Workshop' ? '/masterData?dataType=shipToPartyDropDown&detailsRequired=deliveryPriority,shippingConditions,maxPartialDeliveries,deliveringPlant' : '/masterData?dataType=shipToPartyDropDown&detailsRequired=deliveryPriority,shippingConditions,maxPartialDeliveries'}`;

    const options = {
        path,
        method: "GET",
    };
    return await makeRequest(options)
}

async function getDeliveryBlockReason(brand: any = false) {

    const path = `${API_ENDPOINTS.DEALER_ENDPOINT}${DEALER.FORMFIELD}${brand ? `/masterData?dataType=enquiryGridMasterData&loginId=${sessionStorage.getItem('webApplicationLoginId')}&detailsRequired=deliveryBlockReason,salesOffice` : `/masterData?dataType=enquiryGridMasterData&loginId=${sessionStorage.getItem('webApplicationLoginId')}&detailsRequired=deliveryBlockReason`}`

    const options = {
        path,
        method: "GET",
    };
    return await makeRequest(options)
}


async function getAllSapCustomerId() {
    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + DEALER.FORMFIELD + `/sapCustomerIds?webApplicationLoginId=${sessionStorage.getItem('webApplicationLoginId')}`;
    // const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + DEALER.FORMFIELD + `/sapCustomerIds`;
    const options = {
        path: apiPath,
        method: "GET",
        formdata: true
    };
    return await makeRequest(options)
}

async function getAllBrand(tierType?: any) {
    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + DEALER.FORMFIELD + `/brands?tierType=${tierType}`;
    const options = {
        path: apiPath,
        method: "GET",
        formdata: true
    };
    return await makeRequest(options)
}

async function getAllCRStatus(type: any) {
    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + DEALER.FORMFIELD + `/creditRequestStatus?source=${type}`;
    const options = {
        path: apiPath,
        method: "GET",
        formdata: true
    };
    return await makeRequest(options)
}

async function getAllLostSaleReason() {
    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + DEALER.FORMFIELD + '/lossSaleReason';
    const options = {
        path: apiPath,
        method: "GET",
        formdata: true
    };
    return await makeRequest(options)
}

async function getLostSaleReportData(data: any) {
    const options = {
        path: API_ENDPOINTS.ENQUIRY + ORDER_ENQUIRY.API_PATH + '/report',
        method: "POST",
        bodyObj: data,
        customHeaders: {},
        formdata: true
    };
    return await makeRequest(options)
}

async function getDealerCreditRequest(loginId: any, sapCustomerId: any, type: any) {
    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + `/dealer/creditLimit/${type}/?sapCustomerId=${sapCustomerId}&loginId=${loginId}`;
    const options = {
        path: apiPath,
        method: "GET",
        // bodyObj: data,
        customHeaders: {},
        formdata: true
    };
    return await makeRequest(options)
}

async function saveAdditionalCreditRequest(sapCustomerId: any, data: any) {
    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + `/dealer/${sapCustomerId}/creditRequest`;
    const options = {
        path: apiPath,
        method: "POST",
        bodyObj: data,
        customHeaders: {},
        formdata: true
    };
    return await makeRequest(options)
}

async function postSetDefaultAddress(data: any) {
    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + `/address/setDefault`;
    const options = {
        path: apiPath,
        method: "POST",
        bodyObj: data,
        customHeaders: {},
        formdata: true
    };
    return await makeRequest(options)
}

async function deleteBankStatementOfCreditRequest(documentId: any, requestId: any, data: any) {
    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + `/dealer/creditRequest/document/delete?docId=${documentId}&requestId=${requestId}`;
    const options = {
        path: apiPath,
        method: "POST",
        bodyObj: data,
        customHeaders: {},
        formdata: true
    };
    return await makeRequest(options)
}

async function generateB2bUserId(data: any) {
    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + ONBOARD.API_PATH + '/getB2bUserId';
    const options = {
        path: apiPath,
        method: "POST",
        bodyObj: data,
        customHeaders: {},
        formdata: true
    };
    return await makeRequest(options)
}
async function sendInvitationLink() {
    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + DEALER.FORMFIELD + '';
    const options = {
        path: apiPath,
        method: "GET",
        formdata: true
    };
    return await makeRequest(options)
}
async function confirmDataFromDealer(data: any) {
    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + ONBOARD.API_PATH + '/confirmRequest';
    const options = {
        path: apiPath,
        method: "POST",
        bodyObj: data,
        formdata: true
    };
    return await makeRequest(options)
}

async function updateSellingPrice(data: any, sapId: any) {
    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + `/customer/${sapId}/percentageForSellingPrice`;
    const options = {
        // cors_url: ADMIN_TOOL_ENDPOINTS.CORS_URL,
        path: apiPath,
        method: "PUT",
        bodyObj: data,
        customHeaders: {
            // "X-Subscription-Key": CPM_MASS_SERVICE.AUTH_KEY
        },
        formdata: true
    };
    return await makeRequest(options)
}


async function getViewTarget(sap: any, year: any) {
    const apipath = API_ENDPOINTS.DEALER_ENDPOINT + `/targets/customer/${sap}/getTarget?year=${year}`
    const options = {
        path: apipath,
        method: "GET",
        bodyObj: {},
        customHeaders: {},
        formdata: true
    }
    return await makeRequest(options)
}


async function getShipToParty(sapID: any, b2bId: any) {
    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + `/address/ShipToPartyNumber/${sapID}?b2bUserId=${b2bId}&calledBy=${"kam"}`;
    const options = {
        path: apiPath,
        bodyObj: {},
        method: "GET",
        customHeaders: {},
        formdata: true
    };
    return await makeRequest(options)
}