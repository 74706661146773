/* eslint-disable */
import React, { Fragment } from 'react';
import { Divider, MenuItem, TextField } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useState, Dispatch, useEffect, } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { editSuperDealer } from '../../store/actions/superAdmin.actions';
import ReturnRequestSnackbar from '../../common/components/CreatedEnquirySnackbar';
import { Prompt } from "react-router-dom"
import FullscreenLoader from '../../common/components/FullscreenLoader';
import ActionButtonsSuperAdmin from './ActionButtonsSuperAdmin';
import AssignmentReturnedIcon from '@material-ui/icons/AssignmentReturned';
import { downloadCategoryCertificate } from '../../store/actions/onboard.actions';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import DeleteDialog from '../../common/components/deleteDialog';
import { saveKamReviewDetails, getKamDetails, getDealerDetails, deleteDocument, } from '../../store/actions/editDealer.actions';
import { deleteKamReviewsDocument, resetValues, updateDocument } from '../../store/actions/editDealer.actions';
import { IconButton } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import Snackbar from './../../common/components/Snackbar';
import CachedIcon from '@material-ui/icons/Cached';
import { createTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import { getRefreshButton } from "../../store/actions/superAdmin.actions";
import Tooltip from '@material-ui/core/Tooltip';


const theme = createTheme({
  palette: {
    primary: {
      main: '#1A9FE0'
    },
    secondary: {
      main: '#FF0000',
    }
  },
});

const useStyles = makeStyles((theme) => ({
  actionButton: {
    width: 35,
    fontWeight: 600,
    textTransform: 'capitalize',
    fontFamily: 'Nunito',
    fontSize: '0.288rem',
    color: '#666666',
    borderRadius: '1rem',
    height: 22,
  },
  actionButtonFont: {
    fontWeight: 700,
    textTransform: 'capitalize',
    fontFamily: 'Nunito',
    fontSize: '0.889rem',
    color: '#666666',
  },
  collapseBg: {
    background: 'rgba(66, 94, 108, 0.07)'
  },
  subTableHead: {
    color: '#444444',
    fontWeight: 800,
    width: 140,
    fontSize: '0.75rem',
    fontFamily: 'Nunito',
    padding: 10,
  },
  subTableHeadSmall: {
    color: '#444444',
    fontWeight: 800,
    width: 40,
    fontSize: '0.75rem',
    fontFamily: 'Nunito',
    padding: 10,
  },
  closeButton: {
    backgroundColor: '#EAEAEA',
    width: 120,
    marginTop: 10,
    fontWeight: 800,
    textTransform: 'capitalize',
    fontFamily: 'Nunito',
    fontSize: '0.688rem',
    color: '#666666',
    borderRadius: '2.5rem',
    height: 35,
    textAlign: 'center',
    marginRight: 24
  },
  subTableBodyContent: {
    color: '#666666',
    fontWeight: 600,
    fontSize: '0.75rem',
    fontFamily: 'Nunito',
    padding: 10,
    width: 140,
  },
  subTableBodyContentSmall: {
    color: '#666666',
    fontWeight: 600,
    fontSize: '0.75rem',
    fontFamily: 'Nunito',
    padding: 10,
    width: 40,
  },
}))

const EditDealer = (props: any) => {

  const [excel, setExcel] = useState<boolean>()
  const [customerTier, setCustomerTier] = useState<string>("");
  const [customerTierKey, setCustomerTierKey] = useState<string>('');
  const [paymentMode, setPaymentMode] = useState<any>([]);

  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)

  const [openErrorSnackbar, setOpenErrorSnackbar] = useState<boolean>(false)
  const [successMessage, setSuccessMessage] = React.useState<any>(null);
  const [dataUpdated, setDataUpdated] = useState<boolean>(false);
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [currentIndex, setCurrentIndex] = React.useState<any>();

  const dispatch: Dispatch<any> = useDispatch();
  const history = useHistory()

  const { paymentModeList }: any = useSelector((state: any) => state.onboard);
  const { getCustomerDetails, tierList }: any = useSelector((state: any) => state.superAdmin);
  const { selectedNewDealer }: any = useSelector((state: any) => state.dealer);
  let b2bUserId: any = getCustomerDetails && getCustomerDetails.data && getCustomerDetails.data.data && getCustomerDetails.data.data && getCustomerDetails.data.data.b2bUserId;
  let sapCustomerId: any = getCustomerDetails && getCustomerDetails.data && getCustomerDetails.data.data && getCustomerDetails.data.data.sapCustomerId;
  const webApplicationLoginId = sessionStorage.getItem('webApplicationLoginId');
  const [errorMessage, showErrorMessage] = React.useState<any>(null);
  const [customerAgreement, setCustomerAgreement] = React.useState<any>([]);
  const [categoryCertificate, setCategoryCertificate] = React.useState<any>([]);
  const [annexureToAgreement, setAnnexureToAgreement] = React.useState<any>([]);
  const { categoryCertificateResponse }: any = useSelector((state: any) => state.onboard);
  const [deleteDocumentRemovalType, setDeleteDocumentRemovalType] = React.useState<any>("");
  const { dealerDetails }: any = useSelector((state: any) => state.dealer);
  const customerType = sessionStorage.getItem('customerType');
  const { getRefreshButtonSAPResponse }: any = useSelector((state: any) => state.superAdmin);
  let customerTypeAPIResponse: any = getCustomerDetails && getCustomerDetails.data && getCustomerDetails.data.data && getCustomerDetails.data.data.customerTierInfo && getCustomerDetails.data.data.customerTierInfo.customerType;
  let pricingTierAPIResponse: any = getCustomerDetails && getCustomerDetails.data && getCustomerDetails.data.data && getCustomerDetails.data.data.customerTierInfo && getCustomerDetails.data.data.customerTierInfo.pricingTier;
  const [pricingTier, setPricingTier] = useState<any>("");
  //let customerAccountTier: any = getCustomerDetails && getCustomerDetails.data && getCustomerDetails.data.data && getCustomerDetails.data.data.customerTierInfo && getCustomerDetails.data.data.customerTierInfo.customerAccountTier;

  const classes = useStyles();

  const handleopenCloseSnackbar = (open?: any) => {
    setOpenSnackbar(open ? true : false);
  };

  const handleopenCloseErrorSnackbar = (open?: any) => {
    setOpenErrorSnackbar(open ? true : false);
  };

  const handleopenClose = (open?: any) => {
    setOpenDeleteDialog(open ? true : false);
  };

  useEffect(() => {
    if ((getRefreshButtonSAPResponse && getRefreshButtonSAPResponse.data === null) && (getRefreshButtonSAPResponse && getRefreshButtonSAPResponse.data && getRefreshButtonSAPResponse && getRefreshButtonSAPResponse.data && getRefreshButtonSAPResponse.data.status === 400)) {
    } else if ((getRefreshButtonSAPResponse && getRefreshButtonSAPResponse.data !== null) && (getRefreshButtonSAPResponse && getRefreshButtonSAPResponse.data && getRefreshButtonSAPResponse && getRefreshButtonSAPResponse.data && getRefreshButtonSAPResponse.data.status !== 400)) {
      setPricingTier(getRefreshButtonSAPResponse && getRefreshButtonSAPResponse.data && getRefreshButtonSAPResponse.data.data && getRefreshButtonSAPResponse.data.data.tierType);
      setCustomerTier(getRefreshButtonSAPResponse && getRefreshButtonSAPResponse.data && getRefreshButtonSAPResponse.data.data && getRefreshButtonSAPResponse.data.data.tierType);
    }
  }, [getRefreshButtonSAPResponse]);

  
  useEffect(() => {
    // setCustomerTier('');
    const data = getCustomerDetails && getCustomerDetails.data && getCustomerDetails.data.data;
    const paymentModes = getCustomerDetails && getCustomerDetails.data && getCustomerDetails.data.data && getCustomerDetails.data.data.paymentMode || []
    setPaymentMode(paymentModes);

    if (getCustomerDetails && getCustomerDetails.data && getCustomerDetails.data.data && getCustomerDetails.data.data.customerAgreement && getCustomerDetails && getCustomerDetails.data && getCustomerDetails.data.data && getCustomerDetails.data.data.customerAgreement.length) {
      let customerAgreement = getCustomerDetails && getCustomerDetails.data && getCustomerDetails.data.data && getCustomerDetails.data.data.customerAgreement.filter((data: any) => data.documentId != null && data.documentId)
      setCustomerAgreement(customerAgreement);
    }
    if (getCustomerDetails && getCustomerDetails.data && getCustomerDetails.data.data && getCustomerDetails.data.data.annexureToAgreement && getCustomerDetails && getCustomerDetails.data && getCustomerDetails.data.data && getCustomerDetails.data.data.annexureToAgreement.length) {
      let annexureToAgreement = getCustomerDetails && getCustomerDetails.data && getCustomerDetails.data.data && getCustomerDetails.data.data.annexureToAgreement.filter((data: any) => data.documentId != null && data.documentId)
      setAnnexureToAgreement(annexureToAgreement);
    }

    setPricingTier(pricingTierAPIResponse);
    if (data && data.customerTierInfo && data.customerTierInfo) {
      setCustomerTier(data.customerTierInfo.customerAccountTier);
    } else {
      if (selectedNewDealer && selectedNewDealer.customerTierType === 'Workshop') {
        if (tierList && tierList.data && tierList.data.length > 0) {
          let data = tierList.data.filter((item: any) => item.key === '6');
          if (data && data.length > 0) {
            setCustomerTier(data[0].value)
            setCustomerTierKey(data[0].key);
          }
        }
      }
    }
    setExcel(data?.manageExportsToExcel);
  }, [getCustomerDetails, selectedNewDealer, tierList]);

  const theme = createMuiTheme({
    palette: {
      secondary: {
        main: '#1A9FE0'
      },
    }
  });

  function deleteDocumentDialog(type: any) {
    setOpenDeleteDialog(true);
    setSuccessMessage(null);
    setDeleteDocumentRemovalType(type);
  }

  const onClickBrowseFileasync = async (type: any, item: any) => {
    setLoading(true);
    setSuccessMessage(null);
    setOpenSnackbar(false)
    setOpenErrorSnackbar(false)

    const name = item && item.name ? item.name : '',
      doctExtension = (name && name.substring(name.lastIndexOf('.') + 1, name.length)) || (name),
      doctExtensions = ['jpg', 'jpeg', 'png', 'pdf', 'doc'],
      doctExtensionsforHO = ['jpg', 'png'],
      canUploadFile =(type === 'annexureToAgreement' || type === 'customerAgreement') && type !== 'pictureOfHo' ? doctExtensions.indexOf(doctExtension.toLowerCase()) !== -1 : doctExtensionsforHO.indexOf(doctExtension.toLowerCase()) !== -1;
    if (!canUploadFile) {
      setSuccessMessage('Invalid File type format');
      setOpenSnackbar(true)
      setOpenErrorSnackbar(true)
      setLoading(false);
      return;
    }
    if ((type !== 'annexureToAgreement' && type === 'customerAgreement' && type !== 'pictureOfHo')) {
      if ((item && item.size < 5000000 && customerAgreement.length < 10)) {
        let keyAttribute = `fieldname=${type}&docExtension=${doctExtension}`;

        const customHeaders = {
          "Accept": "application/json",
          "Lob-Id": "AUTO",
          "Channel-Id": "B2B",
          "Org-Id": "",
          "Accept-Language": "EN",
        };

        let docType = '';
        if (type === 'customerAgreement') { docType = 'customerAgreement' }

        const formData: any = new FormData();
        formData.append("entityName", "CUSTOMER");
        formData.append("keyAttribute", keyAttribute);
        formData.append("fileUpload", item);
        formData.append("document", 'true');
        formData.append("Attributes", `key=Brand&value=${item}`);
        formData.append("sapCustomerId", sapCustomerId);
        formData.append("docType", docType);
        formData.append("label", type);
        formData.append("b2bUserId", b2bUserId ? b2bUserId : '');
        formData.append("uploadedByRole", 'kam');
        formData.append("uploadedBywebApplicationLoginId", '');
        formData.append("selectedFileName", item.name);
        let documentRes: any = await dispatch(updateDocument(formData, customHeaders));
        if (documentRes && documentRes.message && documentRes.message.status === 'S') {
          setSuccessMessage('Document Uploaded Successfully');
          setOpenSnackbar(true)
          setOpenErrorSnackbar(false)
          setLoading(false);

          const { label, id } = documentRes.data;

          if (label === "customerAgreement") {
            let po = [...customerAgreement];
            po.push({ documentId: documentRes.data });
            setCustomerAgreement(po);
          }
        } else {
          setSuccessMessage('Document upload failed');
          setOpenSnackbar(true)
          setOpenErrorSnackbar(true)
          setLoading(false);
        }
      } else if (customerAgreement.length >= 10 && type !== 'annexureToAgreement' && type === 'customerAgreement' && type !== 'pictureOfHo' && item && item.size < 5000000) {
        setSuccessMessage('Should not exceed 10 files.');
        setOpenSnackbar(true)
        setOpenErrorSnackbar(true)
        setLoading(false);
      } else if (type !== 'annexureToAgreement' && type === 'customerAgreement' && type !== 'pictureOfHo' && item && item.size > 5000000) {
        setSuccessMessage('Please upload less than 5MB file.');
        setOpenSnackbar(true)
        setOpenErrorSnackbar(true)
        setLoading(false);
      }
    } else if ((type === 'annexureToAgreement' && type !== 'customerAgreement' && type !== 'pictureOfHo')) {
      if ((item && item.size < 5000000 && annexureToAgreement.length < 10)) {
        let keyAttribute = `fieldname=${type}&docExtension=${doctExtension}`;

        const customHeaders = {
          "Accept": "application/json",
          "Lob-Id": "AUTO",
          "Channel-Id": "B2B",
          "Org-Id": "",
          "Accept-Language": "EN",
        };

        let docType = '';
        if (type === 'annexureToAgreement') { docType = 'annexureToAgreement' }

        const formData: any = new FormData();
        formData.append("entityName", "CUSTOMER");
        formData.append("keyAttribute", keyAttribute);
        formData.append("fileUpload", item);
        formData.append("document", 'true');
        formData.append("Attributes", `key=Brand&value=${item}`);
        formData.append("sapCustomerId", sapCustomerId);
        formData.append("docType", docType);
        formData.append("label", type);
        formData.append("b2bUserId", b2bUserId ? b2bUserId : '');
        formData.append("uploadedByRole", 'kam');
        formData.append("uploadedBywebApplicationLoginId", '');
        formData.append("selectedFileName", item.name);
        let documentRes: any = await dispatch(updateDocument(formData, customHeaders));
        if (documentRes && documentRes.message && documentRes.message.status === 'S') {
          setSuccessMessage('Document Uploaded Successfully');
          setOpenSnackbar(true)
          setOpenErrorSnackbar(false)
          setLoading(false);
          const { label, id } = documentRes.data;

          if (label === "annexureToAgreement") {
            let po = [...annexureToAgreement];
            po.push({ documentId: documentRes.data });
            setAnnexureToAgreement(po);
          }
        } else {
          setSuccessMessage('Document upload failed');
          setOpenSnackbar(true)
          setOpenErrorSnackbar(true)
          setLoading(false);
        }
      } else if (annexureToAgreement.length >= 10 && type === 'annexureToAgreement' && type !== 'customerAgreement' && type !== 'pictureOfHo' && item && item.size < 5000000) {
        setSuccessMessage('Should not exceed 10 files.');
        setOpenSnackbar(true)
        setOpenErrorSnackbar(true)
        setLoading(false);
      } else if (type === 'annexureToAgreement' && type !== 'customerAgreement' && type !== 'pictureOfHo' && item && item.size > 5000000) {
        setSuccessMessage('Please upload less than 5MB file.');
        setOpenSnackbar(true)
        setOpenErrorSnackbar(true)
        setLoading(false);
      }
    }
    setLoading(false);
  }

  const removeDocument = async (type: any) => {
    // dispatch(resetValues());
    let customerAgreementDoc = [...customerAgreement]
    let annexureToAgreementDoc = [...annexureToAgreement]

    const obj = {
      "type": type
    }

    if (type === "customerAgreement") {
      const documentId = customerAgreementDoc[currentIndex].documentId && customerAgreementDoc[currentIndex].documentId.id;
      let response: any = await dispatch(deleteDocument(sapCustomerId, documentId, obj));
      if (response && response.message && response.message.status === 'S') {
        customerAgreementDoc[currentIndex].documentId = null;
        customerAgreementDoc.splice(currentIndex, 1);
        setCustomerAgreement(customerAgreementDoc);
        setSuccessMessage(response.message.message);
        setOpenSnackbar(true)
        setOpenErrorSnackbar(false)
        setOpenDeleteDialog(false);
        setLoading(false);
      } else {
        setSuccessMessage(response.error);
        setOpenSnackbar(true)
        setOpenErrorSnackbar(true)
        setLoading(false);
      }
    } else if (type === "annexureToAgreement") {
      const documentId = annexureToAgreementDoc[currentIndex].documentId && annexureToAgreementDoc[currentIndex].documentId.id;
      let response: any = await dispatch(deleteDocument(sapCustomerId, documentId, obj));
      if (response && response.message && response.message.status === 'S') {
        annexureToAgreementDoc[currentIndex].documentId = null;
        annexureToAgreementDoc.splice(currentIndex, 1);
        setAnnexureToAgreement(annexureToAgreementDoc);
        setSuccessMessage(response.message.message);
        setOpenSnackbar(true)
        setOpenErrorSnackbar(false)
        setOpenDeleteDialog(false);
        setLoading(false);
      } else {
        setSuccessMessage(response.error);
        setOpenSnackbar(true)
        setOpenErrorSnackbar(true)
        setLoading(false);
      }
    }
  }

  const onChangeTier = (e: any) => {
    setCustomerTier(e.target.value);
    if (tierList && tierList.data && tierList.data.length > 0) {
      let data = tierList.data.filter((item: any) => item.value === e.target.value);
      if (data && data.length > 0) {
        setCustomerTierKey(data[0].key);
      }
    }
  }

  const handleExcel = (e: any) => {
    setDataUpdated(true)
    setExcel(e.target.value === "true" ? true : false)
  }

  const onChangePaymentMode = (checked: any, code: any) => {
    setDataUpdated(true);
    let payment = [...paymentMode]
    if (checked) {
      payment.push(code);
      setPaymentMode(payment);
    }
    else {
      payment = payment.filter(e => e !== code);
      setPaymentMode(payment);
    }
  }

  const onClickRefresh = async () => {
    setLoading(true);
    const data: any = await dispatch(getRefreshButton(sapCustomerId, customerTypeAPIResponse ? customerTypeAPIResponse : customerType));

    if (data && data.message && data.message.status === "S") {
      setOpenErrorSnackbar(false)
      setOpenSnackbar(true)
      setSuccessMessage(data && data.message && data.message.message);
      setLoading(false);
    } else {
      setSuccessMessage(data && data.error);
      setOpenSnackbar(true)
      setOpenErrorSnackbar(true)
      setLoading(false);
      setCustomerTier('');
    }
  }


  const handleSubmit = async (e: any) => {
    setLoading(true);
    const id = getCustomerDetails && getCustomerDetails.data && getCustomerDetails.data.data && getCustomerDetails.data.data.sapCustomerId

    let selectedCustomerAgreement: any = [];
    if (customerAgreement && customerAgreement.length > 0) {
      customerAgreement.map((po: any) => {
        if (po.documentId && po.documentId.id) {
          selectedCustomerAgreement.push({ documentId: po.documentId.id })
        }
      })
    }

    let selectedAnnexureToAgreement: any = [];
    if (annexureToAgreement && annexureToAgreement.length > 0) {
      annexureToAgreement.map((po: any) => {
        if (po.documentId && po.documentId.id) {
          selectedAnnexureToAgreement.push({ documentId: po.documentId.id })
        }
      })
    }

    const data = {
      "paymentMode": paymentMode,
      "b2bUserId": getCustomerDetails && getCustomerDetails.data && getCustomerDetails.data.data && getCustomerDetails.data.data && getCustomerDetails.data.data.b2bUserId,
      "manageExportsToExcel": excel,
      "customerTier": customerTierKey,
      "pricingTier": pricingTier,
      "customerAccountTier": customerTier,
      "customerAgreement": selectedCustomerAgreement,
      "annexureToAgreement": selectedAnnexureToAgreement,
    }
    const update: any = await dispatch(editSuperDealer(data, id))
    if (update && update.message && update.message.status === "S") {
      setLoading(false);
      setDataUpdated(false);
      setOpenSnackbar(true)
      setSuccessMessage(update && update.message && update.message.message);
      setOpenErrorSnackbar(false)
    } else {
      if (update && update.error && Array.isArray(update.error)) {
        setSuccessMessage(update.error[0].message);
      } else if (update && update.error && typeof update.error === 'string') {
        setSuccessMessage(update.error);
      } else if (update && update.error && typeof update.error === 'object' && update.error.message) {
        setSuccessMessage(update.error.message);
      }
      setOpenSnackbar(true)
      setLoading(false);
      setOpenErrorSnackbar(true)
    }
  }

  const downloadCertificate = async () => {
    setLoading(true);
    let payload = {
      "customerName": getCustomerDetails && getCustomerDetails.data && getCustomerDetails.data.data && getCustomerDetails.data.data && getCustomerDetails.data.data.companyName ? getCustomerDetails.data.data.companyName : "",
      "currentYear": new Date().getUTCFullYear()
    }
    let responseData: any = await dispatch(downloadCategoryCertificate(payload))

    if (responseData && responseData.status === 400) {

    } else {
      let ele: any = JSON.parse(responseData && responseData.data)
      // const tempDiv = document.createElement('div');

      // tempDiv.innerHTML = ele;
      // document.body.appendChild(tempDiv);
      // const canvas = await html2canvas(tempDiv, { useCORS: true });
      // const imgData = canvas.toDataURL('image/png');
      // const pdf = new jsPDF('p', 'mm', 'a4');
      // pdf.addImage(imgData, 'PNG', 0, 0, 210, (canvas.height * 210) / canvas.width);
      // pdf.save('Certificate.pdf');
      // document.body.removeChild(tempDiv);

      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = ele;
      tempDiv.style.position = 'absolute';
      tempDiv.style.top = '-9999px';
      document.body.appendChild(tempDiv);
      html2canvas(tempDiv, { useCORS: true, scale: 2 }).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF();
        // const imgWidth = 210;
        // let imgHeight = (canvas.height * imgWidth) / canvas.width;
        // pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
        pdf.addImage(imgData, 'PNG', 0, 0,210,290)
        pdf.save('Certificate.pdf');
        document.body.removeChild(tempDiv);
        setLoading(false);
      });
    }
  }

  return (
    <>
      {loading && <FullscreenLoader />}
      {(getCustomerDetails && getCustomerDetails.loading) || (paymentModeList && paymentModeList.loading) && <FullscreenLoader />}
      <div className="col-lg-12 col-md-12 col-sm-12 pb-5">
        <div className="d-flex flex-row">
          <h5 className="page-sub-title text-bold font-20 edit-font fontweight-superadmin-header mb-2">Edit Customer Details  </h5>
          <div className="font-12 mt-3 pl-2 edit-font fontweight-superadmin">({getCustomerDetails && getCustomerDetails.data && getCustomerDetails.data.data && getCustomerDetails.data.data && getCustomerDetails.data.data.companyName})</div>
        </div>
        <div className="pb-2">
          <Button
            className="float-right  col-2 col-sm-2 col-md-2 col-lg-1 font-size-5 pl-0 addOrderEnqButton mb-4"
            size="small"
            variant="contained"
            color="primary"
            onClick={() => {
              history.push("/superadmindealers")
            }}
          >Back
          </Button>
        </div>
        <div className="cardCommon mt-5">
          <form>
            <div className=" font-13 pt-1 mb-3 pl-4 p-3">
              <div className="col-2 p-0 edit-font fontweight-superadmin mt-2">Payment Mode</div>
              <div className="flex row-space-start ml-auto mt-4">
                <div>
                  <div className="mb-2">
                  </div>
                  {paymentModeList && paymentModeList.data && paymentModeList.data.length > 0
                    && paymentModeList.data[0] && paymentModeList.data[0].paymentMode &&
                    paymentModeList.data[0].paymentMode.length > 0 &&
                    paymentModeList.data[0].paymentMode?.map((item: any, i: number) => (
                      (<>
                        <ThemeProvider theme={theme} key={i}>
                          <Checkbox className="checkbox" checked={paymentMode.includes(item.code)} disabled={item.code === "BANK" || item.code === 'OFFLINE'} name="bank" onChange={(e) => { onChangePaymentMode(e.target.checked, item.code); }} />
                        </ThemeProvider>
                        <span className="mr-3 edit-font">{item.label}</span>
                      </>
                      )
                    ))}
                </div>
              </div>
            </div>
            <div className="col-12 mb-3 edit-font fontweight-subheader px-4">Currently "Bank Transfer" payment mode is unavailable. </div>
            <Divider className="" />
            <div className="font-13 pt-1 pb-1 p-3 pl-4 mt-2 mb-3">
              <div className="col-lg-2 col-12 p-0 edit-font fontweight-superadmin mt-2">Manage Exports to excel</div>
              <div className="flex row-space-start mt-4">
                <div className='d-flex flex-row'>
                  <input type="radio" className='mt-1' name="excel" value="true" checked={excel == true} onChange={handleExcel} style={{ "height": "1.3em", "width": "1.3em" }} />
                  <label className='mb-0 mt-0 pt-0 ml-1 edit-font pt-1 pl-1' style={{ fontSize: "0.8215rem" }}>Yes</label><br></br>
                </div>
                <div className="ml-5 ">
                  <div className='d-flex flex-row pl-5 ml-1'>
                    <input type="radio" className='mt-1' name="excel" value="false" checked={excel == false} onChange={handleExcel} style={{ "height": "1.3em", "width": "1.3em" }} />
                    <span className='mb-0 mt-0 pt-0 ml-1 edit-font pt-1 pl-1' style={{ fontSize: "0.8215rem" }}>No</span>
                  </div>
                </div>
              </div>
            </div>
            <Divider className="" />
            {selectedNewDealer && selectedNewDealer.customerTierType === 'Dealer' ?
              <div className="font-13 row pt-1 pb-1 p-3 pl-4 mb-2">
                <div className="flex row-space-start mt-4 col-4">
                  <TextField id="pricingTier"
                    disabled
                    value={pricingTier}
                    // onChange={(e: any) => onChangePricingTier(e)}
                    size="small" className="col-sm-12 my-2 commonRoundedInputs"
                    label="Pricing Tier" variant="outlined" margin="dense">
                  </TextField>
                </div>
                {/* <div className="col-2 p-0 edit-font fontweight-superadmin mt-2">Customer Tier</div> */}
                <div className="flex row-space-start mt-4 col-4">
                  <TextField select id="customerTier"
                    // disabled={selectedNewDealer && selectedNewDealer.customerTierType === 'Workshop'}
                    value={customerTier}
                    onChange={(e: any) => onChangeTier(e)}
                    size="small" className="col-sm-12 my-2 commonRoundedInputs"
                    label="Select Customer Tier" variant="outlined" margin="dense" >
                    {tierList && tierList.data && tierList.data.length > 0 &&
                      tierList.data.map((item: any) => (
                        <MenuItem key={item.id} value={item.value}>{item.value}</MenuItem>))}
                  </TextField>
                  {/* <div className="flex row-space-start mt-4 col-4">
                  <Button
                    className="mt-5 font-size-5 pl-0 addOrderEnqButton mb-4"
                    size="small"
                    variant="contained"
                    color="primary"
                    type="submit"
                  >Update
                  </Button>
                </div> */}
                  {/* <div className='d-flex flex-row mb-2'>
                  <input type="radio" name="customer" className='mt-1' value="Workshop" checked={customer == "Workshop"} onChange={handleCustomer} style={{ "height": "1.3em", "width": "1.3em" }} />
                  <span className='mb-0 mt-0 pt-0 ml-1  edit-font pt-1 pl-1' style={{ fontSize: "0.8215rem" }}>Workshop</span><br></br>
                </div>q adx
                <div className="ml-5 mb-2">
                  <div className='d-flex flex-row'>
                    <input type="radio" name="customer" value="Dealer" checked={customer == "Dealer"} onChange={handleCustomer} className='mt-1' style={{ "height": "1.3em", "width": "1.3em" }} />
                    <label className='mb-0 mt-0 pt-0 ml-1  edit-font pt-1 pl-1' style={{ fontSize: "0.8215rem" }}>Dealer</label>
                  </div>
                </div> */}
                </div>
                <div className="flex row-space-start col-4">
                  <Tooltip title="Refresh to fetch updated Tier from SAP" placement="top">
                    <Button variant="contained" className={`mt-4 ${classes.closeButton}`}
                      // disabled={customerTypeAPIResponse === "" && customerTier === ""}
                      onClick={() => {
                        onClickRefresh();
                      }}
                    ><CachedIcon fontSize="inherit" className={classes.actionButton} /><p className={`mr-2 ${classes.actionButtonFont}`}>Refresh</p>
                    </Button>
                  </Tooltip>
                </div>
              </div>
              : <div className="font-13 row pt-1 pb-1 p-3 pl-4 mb-2">
                {/* <div className="p-0 edit-font fontweight-superadmin mt-2">Customer Tier</div> */}
                <div className="flex row-space-start mt-4 col-4">
                  <TextField select id="customerTier"
                    // disabled={selectedNewDealer && selectedNewDealer.customerTierType === 'Workshop'}
                    value={customerTier}
                    onChange={(e: any) => onChangeTier(e)}
                    size="small" className="col-sm-12 my-2 commonRoundedInputs"
                    label="Select Customer Tier" variant="outlined" margin="dense" >
                    {tierList && tierList.data && tierList.data.length > 0 &&
                      tierList.data.map((item: any) => (
                        <MenuItem key={item.id} value={item.value}>{item.value}</MenuItem>))}
                  </TextField>
                </div>
              </div>}
            {customerType === "Int. Business" ? "" :
              <div className='card col-12 border-right-0 border border-left-0'>
                <h6 className="page-sub-title pt-3 pb-2 px-3">Customer Agreement Docs<span style={{ color: 'grey' }}></span></h6>
              </div>
            }
            {customerType === "Int. Business" ? "" :
              <>
                <div className="col-12 m-0 p-0 border-top border-bottom">
                  <div className="row px-3 mb-2">
                    <div className="col-md-3 col-lg-3 pr-2 pt-3 mb-4 px-4 pl-0 ">
                      <h6 className="document-content pt-3 pb-2">Category Certificate <span style={{ color: 'grey' }}></span></h6>
                      <small className="dealer-table-content">(Silver/Gold/Platinum/Elite)</small>
                    </div>
                    <div className={"col-md-4 col-lg-4 col-sm-4 mb-2 pt-2 pl-0 mt-2"}>
                      <div className="file-name-kam mt-2 px-4">
                        <span className="cursor-pointer"
                          onClick={() => {
                            downloadCertificate();
                          }}
                        >{"Certificate.Pdf"}</span>
                        <IconButton className="p-1" aria-label="delete"
                          onClick={(e: any) => { downloadCertificate(); }}>
                          <AssignmentReturnedIcon className="file-close-color" fontSize="small" />
                        </IconButton>
                      </div>
                    </div>
                    <div className="col-md-4 col-lg-4 col-sm-4 mb-2 pl-0 mt-4">
                    </div>
                  </div>
                </div>
                <div className="col-12 m-0 p-0 border-top border-bottom">
                  <div className="row px-3 mb-2">
                    <div className="col-md-3 col-lg-3 pr-2 pt-3 mb-4 px-4 pl-0 ">
                      <h6 className="document-content pt-3 pb-2">Customer Agreement <span style={{ color: 'grey' }}></span></h6>
                      <small className="dealer-table-content">('jpg', 'jpeg', 'png', 'pdf', 'doc' and maximum 10 files upto 5 MB can be uploaded)</small>
                    </div>
                    <div className="col-md-2 col-lg-2 pt-2 mb-2 pl-0 mt-4 px-4">
                      <Button variant="contained"
                        component="label" color="secondary"
                        className="rounded-button-dealer pl-4 pr-4 mt-0">
                        Browse File
                        <input type="file" hidden multiple
                          onChange={(e) =>
                            (e.target && e.target.files) ?
                              onClickBrowseFileasync("customerAgreement", e.target.files[0]) : ""
                          }
                        />
                      </Button>
                    </div>
                    <div className="col-md-7 col-lg-7 mb-2 pl-0 mt-3">
                      {customerAgreement && customerAgreement.length > 0 && customerAgreement.map((file: any, index: any) => (
                        <div className="file-name-kam mt-2">
                          <span className="cursor-pointer"
                            onClick={() => {
                              window.open(file.documentId.url)
                            }}
                          >{file.documentId && file.documentId.name ? file.documentId.name : file.documentId && file.documentId.selectedFileName}</span>
                          {file.documentId && file.documentId.selectedFileName ? (
                            <IconButton className="p-1" aria-label="delete"
                              onClick={(e: any) => { deleteDocumentDialog("customerAgreement"); setCurrentIndex(index) }}>
                              <CancelIcon className="file-close-color" fontSize="small" />
                            </IconButton>
                          ) : ''}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                {/* <div className='col-md-6 col-lg-6 col-sm-6 row border-left'>
                    <div className="col-md-4 col-lg-4 col-sm-4 pt-3 pr-2 mb-4 px-4 pl-0 ">
                      <h6 className="document-content pt-3 pb-2">Category Certificate <span style={{ color: 'grey' }}></span></h6>
                      {isEditDealer && (
                        <small className="dealer-table-content">(Silver/Gold/Platinum/Elite)</small>
                      )}
                    </div>
                    <div className="col-md-4 col-lg-4 col-sm-4 mb-2 pl-0 mt-4">
                    </div>
                    <div className="col-md-4 col-lg-4 col-sm-4 mb-2 pt-2 pl-0 mt-2">
                      <div className="file-name-kam mt-2">
                        <span className="cursor-pointer"
                          onClick={() => {
                          }}
                        >{"Certificate.Pdf"}</span>
                        <IconButton className="p-1" aria-label="delete"
                          onClick={(e: any) => { downloadCertificate(); }}>
                          <AssignmentReturnedIcon className="file-close-color" fontSize="small" />
                        </IconButton>
                      </div>
                    </div>
                  </div> */}

                <div className="col-12 m-0 p-0 border-top">
                  <div className="row px-3 mb-2">
                    <div className="col-md-3 col-lg-3 pr-2 pt-3 mb-4 px-4 pl-0 ">
                      <h6 className="document-content pt-3 pb-2">Annexure To Agreement <span style={{ color: 'grey' }}></span></h6>
                      <small className="dealer-table-content">('jpg', 'jpeg', 'png', 'pdf', 'doc' and maximum 10 files upto 5 MB can be uploaded)</small>
                    </div>
                    <div className="col-md-2 col-lg-2 pt-2 mb-2 pl-0 mt-4 px-4">
                      <Button variant="contained"
                        component="label" color="secondary"
                        className="rounded-button-dealer pl-4 pr-4 mt-0">
                        Browse File
                        <input type="file" hidden multiple
                          onChange={(e) =>
                            (e.target && e.target.files) ?
                              onClickBrowseFileasync("annexureToAgreement", e.target.files[0]) : ""
                          }
                        />
                      </Button>
                    </div>
                    <div className="col-md-7 col-lg-7 mb-2 pl-0 mt-3">
                      {annexureToAgreement && annexureToAgreement.length > 0 && annexureToAgreement.map((file: any, index: any) => (
                        <div className="file-name-kam">
                          <span className="cursor-pointer"
                            onClick={() => {
                              window.open(file.documentId.url)
                            }}
                          >{file.documentId && file.documentId.name ? file.documentId.name : file.documentId && file.documentId.selectedFileName}</span>
                          {file.documentId && file.documentId.selectedFileName ? (
                            <IconButton className="sp-1" aria-label="delete"
                              onClick={(e: any) => { deleteDocumentDialog("annexureToAgreement"); setCurrentIndex(index) }}>
                              <CancelIcon className="file-close-color" fontSize="small" />
                            </IconButton>
                          ) : ''}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </>
            }
          </form>
        </div>
      </div>
      <DeleteDialog
        open={openDeleteDialog}
        text="Are you sure you want to delete File ?"
        handleopenClose={(data: any) => {
          if (data === 'delete') removeDocument(deleteDocumentRemovalType);
          if (!data) handleopenClose(false);
        }} />
      <ReturnRequestSnackbar
        open={openSnackbar}
        type={openErrorSnackbar ? 'error' : ''}
        handleopenClose={() => {
          handleopenCloseSnackbar(false)
        }}
        message={successMessage} />
      <ActionButtonsSuperAdmin
        openRequestSnackbar={true}
        deactiveShow={true}
        handleSubmit={handleSubmit}
      />
      <Prompt when={dataUpdated} message={"Do you wish to proceed without saving the data?"}></Prompt>
    </>
  )
}

export default EditDealer

