/* eslint-disable */
import React, { Fragment, Dispatch, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { getAllDealers, getAllUsers, getDealerDetails, getInactiveStatusDetails, getDealerCreditRequest, getkamUserList, isEdit, selectedNewDealer, getAgreementDocs } from "../../store/actions/dealer.action";
import "../Dealers/Dealers.css";
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import SearchIcon from '@material-ui/icons/Search';
import Menu from '@material-ui/core/Menu';
import EditIcon from '@material-ui/icons/Edit';
import GroupIcon from '@material-ui/icons/Group';
import Snackbar from './../../common/components/Snackbar';
import AddDealerDialog from "../Dealers/AddDealerDialog";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import DealerDetailsDialog from "../Dealers/DealerDetailsDialog";
import TablePagination from '@material-ui/core/TablePagination';
import FullscreenLoader from "../../common/components/FullscreenLoader";
import defaultLogo from '../../assets/default-user-logo.png';
import { getNewBrand } from "../../common/components/util";
import { filter, get } from "lodash";
import { userTypes } from "../../constants/userTypes";
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import Dialog from '@material-ui/core/Dialog';
import RetrieveBrandsDialog from "../Dealers/RetrieveBrandsDialog";
import { AddNewDealer } from "../Dealers/newAddDealerDialog";
import "./SuperDealer.css";
import { getPaymentMode } from '../../store/actions/onboard.actions';
import { getCustomerDealerDetails, getTierList } from "../../store/actions/superAdmin.actions";
import { getDealerListSetTargetData } from "../../store/actions/superAdmin.actions";
import { makeStyles } from '@material-ui/core/styles';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { EmojiEventsOutlined } from "@material-ui/icons";
import { getPurChaseHistoryByYear } from "../../store/actions/achievement.actions";
import { getNewlyAddedBrand } from "../../common/components/util";
import AgreementDialog from "./AgreementDialog";
import PriorityHighIcon from "@material-ui/icons/PriorityHigh";
// import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import HelpIcon from "@material-ui/icons/Help";
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import ErrorIcon from '@material-ui/icons/Error';
import { isEmpty, isString } from 'lodash';
import moment from "moment-timezone";

const theme = createTheme({
  palette: {
    primary: {
      main: '#1A9FE0'
    },
    secondary: {
      main: '#FF0000',
    }
  },
});

function a11yProps(index: any) {
  return {
    id: `wrapped-tab-${index}`,
    'aria-controls': `wrapped-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  actionButton: {
    color: '#1A9FE0',
  },
  collapseBg: {
    background: 'rgba(66, 94, 108, 0.07)'
  },
  subTableHead: {
    color: '#444444',
    fontWeight: 800,
    width: 140,
    fontSize: '0.75rem',
    fontFamily: 'Nunito',
    padding: 10,
  },
  subTableHeadSmall: {
    color: '#444444',
    fontWeight: 800,
    width: 40,
    fontSize: '0.75rem',
    fontFamily: 'Nunito',
    padding: 10,
  },
  subTableBodyContent: {
    color: '#666666',
    fontWeight: 600,
    fontSize: '0.75rem',
    fontFamily: 'Nunito',
    padding: 10,
    width: 140,
  },
  subTableBodyContentSmall: {
    color: '#666666',
    fontWeight: 600,
    fontSize: '0.75rem',
    fontFamily: 'Nunito',
    padding: 10,
    width: 40,
  },
}))



const SuperDealer: React.FC = () => {
  const [dealerDetailTab, setDealerDetailTab] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const dispatch: Dispatch<any> = useDispatch();
  const { allDealers, kamUserList, dealerRequests, getInactiveStatusaApiResponse }: any = useSelector((state: any) => state.dealer);
  const [openDealerDetailDialog, setOpenDealerDetailDialog] = React.useState(false);
  const [openAgreementDialog, setOpenAgreementDialog] = React.useState(false);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [openAddDealer, setOpenAddDealer] = React.useState(false);
  const [openNewAddDealerPopup, setOpenNewAddDealerPopup] = React.useState(false);
  const [isDialogForEdit, setIsDialogForEdit] = React.useState(false);
  const [kamUserId, setKamUserId] = React.useState<any>('0');
  const [searchValue, setSearchValue] = React.useState<string>("");
  const [filterValue, setFilterValue] = React.useState("");
  const [filterByCustomer, setFilterByCustomer] = React.useState("All")
  const [selectedStatusFilterByKAM, setSelectedStatusFilterByKAM] = React.useState<any>([
    {
      name: "All",
      code: "all"
    },
    {
      name: "Assigned to me",
      code: "assignedToMe"
    },
    {
      name: "Added by me",
      code: "addedByMe"
    },
  ]);
  const [selectedTab, setselectedTab] = React.useState(0);
  const [item, setItem] = React.useState<any>()
  const history = useHistory();
  const { profileDetails }: any = useSelector((state: any) => state.profileDetails);
  const webApplicationLoginId = sessionStorage.getItem('webApplicationLoginId') || '';
  const userType = get(profileDetails, 'data.data.basicInfo.userType', '');
  const [open, setOpen] = React.useState(false);
  const [openCustomerDialog, setOpenCustomerDialog] = React.useState(false);
  const [showOnboard, showOnboarding] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open1, setOpen1] = React.useState(false)
  const [customerTier, setCustomerTier] = React.useState<string>("")
  const [openInfo, setOpenInfo] = React.useState(-1);
  const [loading, setLoading] = React.useState<any>(false);
  const isStatusPlusComing = sessionStorage.getItem('isStatusPlusComing');

  const classes = useStyles();
  const id = open ? "simple-popover" : undefined

  const verifySuccess = () => {
    showOnboarding(true);
  }
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  useEffect(() => {
    dispatch(getAllDealers(kamUserId, '', filterByCustomer));
    dispatch(getkamUserList());
  }, []);

  const handleopenCloseSnackbar = (open?: any) => {
    setOpenSnackbar(open ? true : false);
  };

  const handleopenCloseAdddealer = (open?: any) => {
    setOpenAddDealer(open ? true : false);
  };

  const handleClose1 = () => {
    setOpen1(false);
    setAnchorEl(null);
  };

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const filterDealersByKAM = (e: any) => {
    setPage(0);
    setOpenInfo(-1);
    if (e.target.value === "0") {
      setKamUserId(e.target.value);
      if (searchValue.trim() !== "")
        dispatch(getAllDealers(e.target.value.trim(), "", filterByCustomer, searchValue.trim(), 0));
      else
        dispatch(getAllDealers(e.target.value.trim(), "", filterByCustomer));
    }
    else {
      setKamUserId(e.target.value);
      let searchFilter = e.target.value ? e.target.value : "";
      if (e.target.value && e.target.value.trim() !== "") {
        if (searchValue.trim() === "")
          dispatch(getAllDealers(searchFilter.trim(), "", filterByCustomer, "", 0));
        else
          dispatch(getAllDealers(searchFilter.trim(), "", filterByCustomer, searchValue.trim(), 0));
      }
    }
  }

  const handleFilterByCustomer = (e: any) => {
    setPage(0);
    setOpenInfo(-1);
    if (kamUserId === "0") {
      setFilterByCustomer(e.target.value);
      if (searchValue.trim() !== "")
        dispatch(getAllDealers(kamUserId, "", e.target.value.trim(), searchValue.trim(), 0));
      else
        dispatch(getAllDealers(kamUserId, "", e.target.value.trim()));
    }
    else {
      setFilterByCustomer(e.target.value);
      let searchFilter = e.target.value ? e.target.value : "";
      if (e.target.value && e.target.value.trim() !== "") {
        if (searchValue.trim() === "") {
          dispatch(getAllDealers(kamUserId, "", searchFilter.trim(), "", 0));
        } else {
          if (kamUserId.trim() !== "" && kamUserId.trim() !== "0") {
            dispatch(getAllDealers(kamUserId, "", searchFilter.trim(), searchValue.trim(), 0));
          } else
            dispatch(getAllDealers("", "", searchFilter.trim(), searchValue.trim(), 0));
        }
      }
    }
  }

  const searchDealerList = (e: any, isSearch: any) => {
    if (searchValue.trim() === "") {
      if (kamUserId.trim() !== "" && kamUserId.trim() !== "0")
        dispatch(getAllDealers(kamUserId.trim(), "", filterByCustomer, "", isSearch ? 0 : page));
      else
        dispatch(getAllDealers("", "", filterByCustomer));
    } else {
      if (kamUserId.trim() !== "" && kamUserId.trim() !== "0")
        dispatch(getAllDealers(kamUserId, "", filterByCustomer, searchValue.trim(), isSearch ? 0 : page));
      else
        dispatch(getAllDealers("", "", filterByCustomer, searchValue.trim(), isSearch ? 0 : page));
    }
  }

  const handleChangePage = (newPage: number) => {
    setOpenInfo(-1);
    if (kamUserId.trim() !== "0") {
      if (searchValue.trim() === "" && filterValue.trim() === "") {
        dispatch(getAllDealers(kamUserId, "", filterByCustomer, "", newPage));
      }
      else if (searchValue.trim() === "" && filterValue.trim() !== "") {
        dispatch(getAllDealers(kamUserId, "", filterByCustomer, "", newPage));
      }
      else {
        dispatch(getAllDealers(kamUserId, "", filterByCustomer, searchValue.trim(), newPage));
      }
    } else if (kamUserId.trim() === "0") {
      dispatch(getAllDealers('', "", filterByCustomer, searchValue.trim(), newPage));
    }
    setPage(newPage);
  };

  return (
    <Fragment>
      {loading && (<FullscreenLoader />)}
      {allDealers && allDealers.loading && <FullscreenLoader />}
      {dealerRequests && dealerRequests.loading && <FullscreenLoader />}
      <div className="d-flex align-items-center">
        <h2 className="page-title">
          Customers</h2>
      </div>
      <Grid container className="">
        <Snackbar
          open={openSnackbar}
          handleopenClose={() => {
            handleopenCloseSnackbar(false)
          }} text="Dealer added successfully!" />
        <AddDealerDialog
          open={openAddDealer}
          isEdit={isDialogForEdit}
          handleopenClose={(data: any) => {
            if (data) {
              handleopenCloseSnackbar(true)
            }
            handleopenCloseAdddealer(false)
          }}
        />
        {openDealerDetailDialog &&
          <DealerDetailsDialog selectedTabValue={dealerDetailTab} open={openDealerDetailDialog} onClose={(value: boolean) => setOpenDealerDetailDialog(value)} />
        }

        {openAgreementDialog &&
          <AgreementDialog selectedTabValue={dealerDetailTab} open={openAgreementDialog} onClose={(value: boolean) => setOpenAgreementDialog(value)} />
        }

        <Grid item xs={12} sm={4}>
          <div className="mt-3">List of all Customer</div>
        </Grid>
        <Grid item xs={12} sm={8}>
          <div className="float-right">
            <Button
              variant="contained"
              color="secondary"
              className="rounded-button-dealer pt-2 pb-2 mr-2"
              onClick={async () => {
                dispatch(getAgreementDocs('Dealer'));
                setOpenAgreementDialog(true);
              }}
            >
              Publish Agreement Docs
            </Button>
            <TextField
              id="kamList"
              select
              label="Filter By CustomerType"
              variant="outlined"
              className="rounded-input-dealer mw-200p mr-2 mb-2"
              size="small"
              onChange={(e: any) => { handleFilterByCustomer(e) }}
              value={filterByCustomer}
            >
              <MenuItem value={"All"}>
                All
              </MenuItem>
              <MenuItem value={"Dealer"}>
                Dealer
              </MenuItem>\ <MenuItem value={"Workshop"}>
                Workshop
              </MenuItem>\ <MenuItem value={"Government"}>
                Government
              </MenuItem>\ <MenuItem value={"Fleet"}>
                Fleet
              </MenuItem>\
              <MenuItem value={"Int. Business"}>
                Int. Business
              </MenuItem>
            </TextField>
            <TextField
              id="kamList"
              select
              label="Filter By KAM"
              variant="outlined"
              className="rounded-input-dealer mw-200p mr-2 mb-2"
              size="small"
              onChange={(e: any) => { setPage(0); setOpenInfo(-1); filterDealersByKAM(e); setFilterValue('') }}
              value={kamUserId}
            >
              {kamUserList && kamUserList.data && kamUserList.data.data && kamUserList.data.data.length > 0 && kamUserList.data.data.map((item: any, index: any) => (
                <MenuItem key={index} value={item.webApplicationLoginId}>
                  {item.displayName}
                </MenuItem>
              ))}
              <MenuItem value={"0"}>
                Show All
              </MenuItem>
            </TextField>
            <FormControl variant="outlined" className="">
              <InputLabel htmlFor="dealer-search" className="pl-2" margin="dense">Search</InputLabel>
              <OutlinedInput
                id="dealer-search"
                value={searchValue}
                onKeyPress={(ev) => {
                  if (ev.key === 'Enter') {
                    searchDealerList(searchValue, true);
                    setPage(0);
                    setOpenInfo(-1);
                  }
                }}
                onChange={(e: any) => {
                  setSearchValue(e.target.value);
                  setOpenInfo(-1);
                  if (e.target.value.trim() === "") {
                    if (selectedTab == 0) {
                      if (kamUserId.trim() !== "" && kamUserId.trim() !== "0")
                        dispatch(getAllDealers(kamUserId, "", filterByCustomer));
                      else
                        dispatch(getAllDealers("", "", filterByCustomer));
                    }
                  }
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="Search"
                      edge="end"
                      onClick={(e: any) => {
                        searchDealerList(e, true);
                        setPage(0);
                        setOpenInfo(-1);
                      }}
                    >
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                }
                labelWidth={50} margin="dense" className="rounded-input-dealer mw-200p mr-2 mb-2" />
            </FormControl>
          </div>
        </Grid>
        {<Grid item xs={12} className="mt-2 cardCommon">
          <TableContainer component={Paper} className="height-500 pb-5 mb-5">
            <Table aria-label="dealer table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <div className="dealer-table-title">Customer</div>
                    <div className="dealer-table-title-desc">Logo, Name &amp; Trade License No</div>
                  </TableCell>
                  <TableCell>
                    <div className="dealer-table-title">Address</div>
                    <div className="dealer-table-title-desc">Region &amp; Address</div>
                  </TableCell>
                  <TableCell>
                    <div className="dealer-table-title">Contact</div>
                    <div className="dealer-table-title-desc">Telephone &amp; Email</div>
                  </TableCell>
                  <TableCell>
                    <div className="dealer-table-title">Assigned</div>
                    <div className="dealer-table-title-desc">KAM &amp; FSE</div>
                  </TableCell>
                  <TableCell>
                    <div className="dealer-table-title mb-3">Brands</div>
                  </TableCell>
                  <TableCell>
                    <div className="dealer-table-title mb-3">CustomerType</div>
                  </TableCell>
                  <TableCell>
                    <div className="dealer-table-title mb-3">SapCustomerId</div>
                  </TableCell>
                  <TableCell><div className="dealer-table-title mb-3">Status</div></TableCell>
                  <TableCell ><div className="dealer-table-title mb-3">Action</div></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {allDealers && allDealers.data && allDealers.data.data && allDealers.data.data.data && allDealers.data.data.data.customerList && allDealers.data.data.data.customerList.length > 0 &&
                  allDealers.data.data.data.customerList.map((item: any, index: any) =>
                    <React.Fragment>
                      <TableRow key={item.sapCustomerId}>
                        <TableCell component="th" scope="row" className="mw-200p">
                          <Grid container>
                            <div className="d-flex">
                              {/* <Grid item xs={3}> */}
                              <div style={{ display: "inline" }}>
                                <img
                                  src={item.logo ? item.logo : defaultLogo}
                                  alt="Logo Client"
                                  className="dealer-grid-logo"
                                /></div>

                              <div className="ml-2 py-1 pr-1" style={{ display: "inline" }}>
                                <div className="dealer-table-content dealer-company-name cursor-pointer"
                                  onClick={async () => {
                                    sessionStorage.setItem('userCurrentStatus', item.status);
                                    dispatch(selectedNewDealer(item));
                                    dispatch(isEdit(false));
                                    history.push('/vieweditdealerdetails');
                                  }}>{item.companyTitle} {item.companyName}</div>
                                <div className="dealer-table-subcontent">Trade License No. {item.tradeLicenseNo}</div>
                              </div>
                            </div>
                          </Grid>
                        </TableCell>
                        <TableCell className="mw-150p">
                          <div className="dealer-table-content">{item.addresses ? item.addresses : "-"}</div>
                        </TableCell>
                        <TableCell className="mw-150p">
                          <div className="dealer-table-content">{item.contactDetails.telephoneNumber ? item.contactDetails.telephoneNumber : "-"}</div>
                          <div className="dealer-table-subcontent">{item.contactDetails.email ? item.contactDetails.email : "-"}</div>
                        </TableCell>
                        <TableCell className="mw-150p">
                          <div className="dealer-table-content">{item.assignKam ? item.assignKam : "-"}</div>
                          <div className="dealer-table-subcontent">{item.assignFse}{item.assignFse ? " (FSE)" : '-'}</div>
                        </TableCell>
                        <TableCell className="mw-150p">
                          <div className="dealer-table-subcontent">
                            {item.customerTierType === 'Workshop' ? (<>
                              {item.selectedBrand &&
                                item.customerTierType === "Workshop" ? item.selectedBrand.map((value: any) => getNewBrand(value && value.make)).join(", ") : item.organizationIds.map((value: any) => value.isActive === false ? '' : getNewlyAddedBrand(value && value.make)).join(", ")
                              }
                            </>) : (<>
                              {item.organizationIds &&
                                item.customerTierType === "Workshop" ? item.organizationIds.map((value: any) => value.isActive === false ? '' : getNewBrand(value && value.make)).join(", ") : item.organizationIds.map((value: any) => value.isActive === false ? '' : getNewlyAddedBrand(value && value.make)).join(", ")
                              }
                            </>)}
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className="dealer-table-content">{item.customerTierType ? item.customerTierType : "-"}</div>
                        </TableCell>
                        <TableCell>
                          <div className="dealer-table-content">{item.sapCustomerId ? item.sapCustomerId : "-"}</div>
                        </TableCell>
                        {/* <TableCell className="mw-150p">
                        <div className={item.status && item.status === "Active" ? "dealer-table-content status-color" : "dealer-table-content text-red"}>{item.status ? item.status === "Document expiry issue" ? "Document Expiry Issue" : item.status === "Deactive" ? "Inactive" : item.status : "-"}</div>
                        <div className="dealer-table-subcontent dealer-table-content text-red">{item.status !== "Deactive" && item.docExpiryAutoDeactivateUntil && item.docExpiryAutoDeactivate ? "Till :" : ""}{" "}{item.status !== "Deactive" && item.docExpiryAutoDeactivateUntil && item.docExpiryAutoDeactivate ? item.docExpiryAutoDeactivateUntil : ""}</div>
                      </TableCell> */}
                        {item.status && item.status !== "Active" && item && item.statusPlus && item.statusPlus.length > 0 ?
                          <TableCell className="mw-150p">
                            <div style={{ display: "flex" }} className={!isEmpty(item && item.statusPlus && item.statusPlus) && item.status && item.status === "Active" ? "dealer-table-content text-red" : item.status && item.status === "Active" ? "dealer-table-content status-color" : "dealer-table-content text-red"}>{!isEmpty(item && item.statusPlus && item.statusPlus) && item.status && item.status === "Active" ? "Inactive" : item.status ? item.status === "Document expiry issue" ? "Document Expiry Issue" : item.status === "Deactive" ? "Inactive" : item.status : "-"}
                              <ErrorIcon className="cursor-pointer bottomFieldNameTable ml-2 mb-2 mt-0 float-right" fontSize="small"
                                onClick={async () => {
                                  setLoading(true);
                                  await dispatch(getInactiveStatusDetails(item.sapCustomerId));
                                  setOpenInfo(open === index + 1 ? -1 : index + 1)
                                  setLoading(false);
                                }} /></div>
                            <div className="dealer-table-subcontent dealer-table-content text-red">{!isEmpty(item && item.statusPlus && item.statusPlus) ? `(${item && item.statusPlus && item.statusPlus.toString().split(",").join(', ')})` : ""}</div>
                            <div className="dealer-table-subcontent dealer-table-content text-red">{item.status !== "Deactive" && item.docExpiryAutoDeactivateUntil && item.docExpiryAutoDeactivate ? "Till :" : ""}{" "}{item.status !== "Deactive" && item.docExpiryAutoDeactivateUntil && item.docExpiryAutoDeactivate ? item.docExpiryAutoDeactivateUntil : ""}</div>
                          </TableCell>
                          :
                          item.status && item.status === "Active" && item && item.statusPlus && item.statusPlus.length > 0 ?
                            <TableCell className="mw-150p">
                              <div style={{ display: "flex" }} className={!isEmpty(item && item.statusPlus && item.statusPlus) && item.status && item.status === "Active" ? "dealer-table-content text-red" : item.status && item.status === "Active" ? "dealer-table-content status-color" : "dealer-table-content text-red"}>{!isEmpty(item && item.statusPlus && item.statusPlus) && item.status && item.status === "Active" ? "Inactive" : item.status ? item.status === "Document expiry issue" ? "Document Expiry Issue" : item.status === "Deactive" ? "Inactive" : item.status : "-"}
                                <ErrorIcon className="cursor-pointer bottomFieldNameTable ml-2 mb-2 mt-0 float-right" fontSize="small"
                                  onClick={async () => {
                                    setLoading(true);
                                    await dispatch(getInactiveStatusDetails(item.sapCustomerId));
                                    setOpenInfo(open === index + 1 ? -1 : index + 1)
                                    setLoading(false);
                                  }} /></div>
                              <div className="dealer-table-subcontent dealer-table-content text-red">{!isEmpty(item && item.statusPlus && item.statusPlus) ? `(${item && item.statusPlus && item.statusPlus.toString().split(",").join(', ')})` : ""}</div>
                              <div className="dealer-table-subcontent dealer-table-content text-red">{item.status !== "Deactive" && item.docExpiryAutoDeactivateUntil && item.docExpiryAutoDeactivate ? "Till :" : ""}{" "}{item.status !== "Deactive" && item.docExpiryAutoDeactivateUntil && item.docExpiryAutoDeactivate ? item.docExpiryAutoDeactivateUntil : ""}</div>
                            </TableCell>
                            :
                            <TableCell className="mw-150p">
                              <div className={!isEmpty(item && item.statusPlus && item.statusPlus) && item.status && item.status === "Active" ? "dealer-table-content text-red" : item.status && item.status === "Active" ? "dealer-table-content status-color" : "dealer-table-content text-red"}>{!isEmpty(item && item.statusPlus && item.statusPlus) && item.status && item.status === "Active" ? "Inactive" : item.status ? item.status === "Document expiry issue" ? "Document Expiry Issue" : item.status === "Deactive" ? "Inactive" : item.status : "-"}</div>
                              {/* {item && item.statusPlus && item.statusPlus.map((ele: any) => {
                              return <>
                                <div className="dealer-table-subcontent dealer-table-content text-red">({ele ? ele : ""})</div>
                              </>
                            })} */}
                              <div className="dealer-table-subcontent dealer-table-content text-red">{!isEmpty(item && item.statusPlus && item.statusPlus) ? `(${item && item.statusPlus && item.statusPlus.toString().split(",").join(', ')})` : ""}</div>
                              {isEmpty(item && item.statusPlus && item.statusPlus) &&
                                <div className="dealer-table-subcontent dealer-table-content text-red">{item.status !== "Deactive" && item.docExpiryAutoDeactivateUntil && item.docExpiryAutoDeactivate ? "Till :" : ""}{" "}{item.status !== "Deactive" && item.docExpiryAutoDeactivateUntil && item.docExpiryAutoDeactivate ? item.docExpiryAutoDeactivateUntil : ""}</div>
                              }
                            </TableCell>}
                        <TableCell>
                          <div style={{ background: "white" }}>
                            {<IconButton aria-describedby={id} onClick={(event: any) => {
                              sessionStorage.setItem('customerType', item.customerTierType ? item.customerTierType : "");
                              handleClick(event); setItem(item); setCustomerTier(item.customerTierType && item.customerTierType)
                            }} className={classes.actionButton} size="small">
                              <div className="px-2 pt-1 pb-1 boxshadow-icon"> <MoreVertIcon fontSize="inherit" className={classes.actionButton} /> </div>
                            </IconButton>}
                          </div>
                        </TableCell>
                      </TableRow>
                      {!isEmpty(getInactiveStatusaApiResponse) ?
                        <TableRow className={classes.collapseBg}>
                          <TableCell className="p-0 w-100" colSpan={12}>
                            <Collapse in={openInfo > 0 && openInfo === index + 1 ? true : false}>
                              <Box>
                                <Table aria-label="table">
                                  <TableHead>
                                    <TableRow>
                                    <TableCell className={`${classes.subTableHead} px-0 ml-0`}>
                                        <span className="mx-2 ml-3"></span>
                                        Date </TableCell>
                                      <TableCell className={classes.subTableHead}>Time</TableCell>
                                      <TableCell className={classes.subTableHead}>Inactive Reason</TableCell>
                                      <TableCell className={classes.subTableHead}></TableCell>
                                      <TableCell className={classes.subTableHead}>Status</TableCell>
                                      <TableCell className={classes.subTableHead}></TableCell>
                                    </TableRow>
                                  </TableHead>
                                  {!isEmpty(getInactiveStatusaApiResponse && getInactiveStatusaApiResponse.data) && getInactiveStatusaApiResponse && getInactiveStatusaApiResponse.data.map((subEnquiry: any) => {
                                    return <>
                                      <TableRow>
                                        {/* <TableCell className={`${classes.subTableBodyContent} px-4`}>{moment(subEnquiry && subEnquiry.date).tz('Asia/Dubai').format('YYYY-MM-DD')}</TableCell> */}
                                        <TableCell className={`${classes.subTableBodyContent} px-4`}>{subEnquiry && subEnquiry.date}</TableCell>
                                        <TableCell className={classes.subTableBodyContent}>
                                          {moment.utc(subEnquiry && subEnquiry.time, 'HH:mm:ss').tz('Asia/Dubai').format('HH:mm:ss')}
                                        </TableCell>
                                        <TableCell className={classes.subTableBodyContent}>{subEnquiry && subEnquiry.reason}</TableCell>
                                        <TableCell className={classes.subTableBodyContent}>{ }</TableCell>
                                        <TableCell className={classes.subTableBodyContent}>{subEnquiry && subEnquiry.status}</TableCell>
                                        <TableCell className={classes.subTableBodyContent}>{ }</TableCell>
                                      </TableRow>
                                    </>
                                  })}
                                </Table>
                              </Box>
                            </Collapse>
                          </TableCell >
                        </TableRow>
                        :
                        <>
                          {isEmpty(getInactiveStatusaApiResponse) &&
                            <TableRow className={classes.collapseBg}>
                              <TableCell className="p-0 w-100" colSpan={12}>
                                <Collapse in={openInfo > 0 && openInfo === index + 1 ? true : false}>
                                  <Box>
                                    <Table aria-label="table">
                                      <TableHead>
                                        <TableRow>
                                          <TableCell className={classes.subTableHead}>
                                            <div className="justify-content-center mx-auto text-center d-flex">No Record Found.</div>
                                          </TableCell>
                                        </TableRow>
                                      </TableHead>
                                    </Table>
                                  </Box>
                                </Collapse>
                              </TableCell>
                            </TableRow>
                          }</>
                      }
                    </React.Fragment>
                  )}
                <Menu
                  id={id}
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose1}
                  anchorReference="anchorEl"
                  anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                  transformOrigin={{ vertical: "top", horizontal: "right" }}
                  className="ml-3 mt-5"
                >
                  <MenuItem className="  ">   {userType !== userTypes.fse &&
                    <Button className="text-info btn-link mr-4" startIcon={<EditIcon />}
                      onClick={() => {
                        sessionStorage.setItem('userCurrentStatus', item.status);
                        dispatch(selectedNewDealer(item));
                        dispatch(getPaymentMode());
                        dispatch(getTierList());
                        dispatch(getCustomerDealerDetails(item.sapCustomerId))
                        history.push('/editsuperdealer');
                        sessionStorage.setItem('isStatusPlusComing', !isEmpty(item && item.statusPlus && item.statusPlus) ? 'true' : 'false');
                      }}>Edit</Button>
                  }</MenuItem>
                  {/* <Button className="text-info btn-link mr-3" startIcon={<FileCopyIcon />}
                            onClick={() => {
                              setDealerDetailTab(1);
                              dispatch(getDealerDetails(item.sapCustomerId, ''));
                              setOpenDealerDetailDialog(true);
                            }}>Docs</Button> */}
                  <MenuItem>   <Button className="text-info btn-link mr-3"
                    startIcon={<GroupIcon />}
                    onClick={() => {
                      history.push('/dealerusers'); dispatch(getAllUsers(item.sapCustomerId));
                      dispatch(getDealerDetails(item.sapCustomerId, 'dealerDetails'))
                    }}>Users</Button></MenuItem>
                  {customerTier === "Dealer" && <>
                    <MenuItem > <Button className="text-info btn-link mr-3"
                      startIcon={<AttachMoneyIcon />}
                      onClick={() => {
                        dispatch(getDealerCreditRequest(webApplicationLoginId, item.sapCustomerId, 'kam'));
                        history.push('/viewcreditkam');
                      }}><span className="ml-n2">ViewCredit</span></Button></MenuItem>
                  </>}
                  {customerTier === "Dealer" &&
                    <MenuItem >  <Button className="text-info btn-link mr-3"
                      startIcon={<img src="./settargetinfo.svg" />}
                      onClick={() => {
                        history.push('/dealerstarget');
                        dispatch(selectedNewDealer(item));
                        dispatch(getDealerListSetTargetData(item.sapCustomerId, new Date().getFullYear(), webApplicationLoginId));
                      }}><span>SetTarget</span></Button>
                    </MenuItem>}
                  {customerTier === "Dealer" && <>  <MenuItem className="mt-0"> <Button className="text-info btn-link mr-3"
                    startIcon={<EmojiEventsOutlined fontSize="inherit" />}
                    onClick={() => {
                      history.push("/achievementssuperadmin")
                      // dispatch(getPurChaseHistoryByYearKam("",webApplicationLoginId,item.sapCustomerId,"2022")) 
                      dispatch(selectedNewDealer(item));
                    }}><span>Achievements</span></Button>
                  </MenuItem>
                  </>}
                </Menu>
              </TableBody>
            </Table>
            {allDealers && allDealers.data && allDealers.data.data && allDealers.data.data.data && allDealers.data.data.data.customerList && allDealers.data.data.data.customerList.length === 0 && (
              <div className="my-5 mx-auto text-center">
                No users found.
              </div>
            )}
            {<div className="col-12 d-flex justify-content-end">
              <TablePagination
                rowsPerPageOptions={[10]}
                component="div"
                count={allDealers && allDealers.data && allDealers.data.data && allDealers.data.data.data && allDealers.data.data.data.totaCount ? allDealers.data.data.data.totaCount : 0}
                rowsPerPage={10}
                page={page}
                onPageChange={(e: any, newPage: number) => { handleChangePage(newPage) }}
              />
            </div>}
          </TableContainer>
          {
            openNewAddDealerPopup &&
            <AddNewDealer
              setOpenCustomerDialog={setOpenCustomerDialog}
              openCustomerDialog={openCustomerDialog}
              verifySuccess={verifySuccess} aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              fullWidth={true}
              maxWidth="md" />
          }
        </Grid>}
      </Grid>

      {/* <AddNewDealer
        setOpenCustomerDialog={setOpenCustomerDialog}
        openCustomerDialog={openCustomerDialog}
        verifySuccess={verifySuccess} aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth="md" /> */}

      <Dialog className="pb-0"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth="md">
        <RetrieveBrandsDialog setOpenDialog={handleClose} />
      </Dialog>
    </Fragment >
  );
};

export default SuperDealer;
