export const DASHBOARD_REDIRECT_EXCLUSION = [
    '/specialofferdetail',
    "/quotation",
    '/announcements',
    '/adminspecialoffers',
    '/onboarding',
    '/creditteam',
    '/createcreditrequest',
    '/shiptoparty',
    "/achivements",
    "/specialpricerequest",
    "/myschedule",
    "/quotation"
]
